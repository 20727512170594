<div class="container">
    <div class="row">
        <div *ngIf="isLoggedIn" class="col-sm-12 col-md-8 col-lg-6 col-xl-6 mx-auto mt-3 text-center">
            <mat-card style="min-width: 50%;">
                <mat-card-content class="text-center">
                    <h2>Welcome</h2>
                </mat-card-content>
                <mat-card-content>
                    <p>You are already logged in.</p>
                    <a routerLink="/" mat-raised-button>Go to Home</a>
                    <br>
                    <br>
                    <a routerLink="/dashboard" mat-raised-button>Go to your Dashboard</a>
                    <br>
                </mat-card-content>
            </mat-card>
            <br>
            <app-explore-more></app-explore-more>
        </div>
        <div *ngIf="!isLoggedIn" class="col-sm-12 col-md-8 col-lg-6 col-xl-6 mx-auto mt-3">
            <mat-card style="min-width: 50%;">
                <div class="login-google my-4 text-center">
                    <button mat-raised-button color="primary" (click)="loginWithGoogle()">Login with Google</button>
                    <br>
                    or 
                    <hr>
                </div>
            
                <mat-tab-group>
                    <mat-tab label="Login">
                        <mat-card-header>
                            <mat-card-title>Login</mat-card-title>
                            <mat-card-subtitle>
                                Your journey to value investing starts here
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <form class="">
                                <mat-form-field class="">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput [formControl]="loginEmailFormControl" placeholder="Ex. pat@example.com">
                                    <mat-error *ngIf="loginEmailFormControl.hasError('email') && !loginEmailFormControl.hasError('required')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="loginEmailFormControl.hasError('required')">
                                        Email is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <br>
                                <mat-form-field class="">
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput [formControl]="loginPasswordFormControl">
                                    <mat-error *ngIf="loginPasswordFormControl.hasError('required')">
                                        Password is <strong>required</strong> with 6 characters minimum
                                    </mat-error>
                                </mat-form-field>
                                <br>
                                <button mat-raised-button (click)="loginClicked()">Login</button>
                            </form>
                        </mat-card-content>
                    </mat-tab>
                    <mat-tab label="Register">
                        <mat-card-header>
                            <mat-card-title>Register</mat-card-title>
                            <mat-card-subtitle>
                                Your journey to value investing starts here
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <form class="">
                                <mat-form-field class="">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput [formControl]="registerEmailFormControl" placeholder="Ex. pat@example.com">
                                    <mat-error *ngIf="registerEmailFormControl.hasError('email') && !registerEmailFormControl.hasError('required')">
                                        Please enter a valid email address
                                    </mat-error>
                                    <mat-error *ngIf="registerEmailFormControl.hasError('required')">
                                        Email is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <br>
                                <mat-form-field class="">
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput [formControl]="registerPasswordFormControl">
                                    <mat-error *ngIf="registerPasswordFormControl.hasError('required')">
                                        Password is <strong>required</strong> with 6 characters minimum
                                    </mat-error>
                                </mat-form-field>
                                <br>
                                <button mat-raised-button (click)="registerClicked()">Register</button>
                            </form>
                        </mat-card-content>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
            
            <div class="mt-5 nt-subtitle mb-2">Why register?</div>
            <div>
                <ul style="list-style-type: none; padding-left: 20px;">
                    <li class="nt-content my-1" *ngFor="let feature of this.productDescription[0].featureList">
                        ✅ {{feature}}
                    </li>
                    <li class="nt-content my-1">
                        And much more...
                    </li>
                </ul>
                <div class="text-center">
                    <app-subscription-button color="accent">
                        See all advantages
                    </app-subscription-button>
                </div>
            </div>
        </div>
    </div>
</div>

