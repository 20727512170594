import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-progress-bar',
  template: `
    <div class="progress-bar">
      <div [style.width.%]="percentage" [style.backgroundColor]="color"></div>
    </div>
  `,
  styles: [`
    .progress-bar {
      width: 100%;
      height: 20px;
      background-color: #eee;
      border-radius: 10px;
      overflow: hidden;
    }

    .progress-bar div {
      height: 100%;
    }

    .progress-text {
      text-align: center;
      font-size: 18px;
      margin-top: 10px;
    }
  `]
})
export class SimpleProgressBarComponent {
  @Input() percentage: number = 0;
  @Input() color: string = '#4CAF50'; // Default color is green
}
