import { Component } from '@angular/core';
import { FormControl, NgForm, Validators, FormGroupDirective } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/core/login.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login-window',
  templateUrl: './login-window.component.html',
  styleUrls: ['./login-window.component.scss'],
})
export class LoginWindowComponent {
  public loginEmailFormControl = new FormControl('', [Validators.required, Validators.email]);
  public loginPasswordFormControl = new FormControl('', [Validators.minLength(6)])
  public registerEmailFormControl = new FormControl('', [Validators.required, Validators.email]);
  public registerPasswordFormControl = new FormControl('', [Validators.minLength(6)])
  public firebaseUser:firebase.default.User|null = null

  constructor(
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LoginWindowComponent>){}
  
    loginClicked(): void {
    this.loginService.authState$.subscribe(user => {
      this.firebaseUser = user
      this.dialogRef.close()
    })
    if(this.loginEmailFormControl.valid && this.loginPasswordFormControl.valid && 
      this.loginEmailFormControl.value && this.loginPasswordFormControl.value){
      this.loginService.loginWithEmailPassword(
        this.loginEmailFormControl.value, this.loginPasswordFormControl.value)
    } else {
      this.snackBar.open('Please fill correctly the email and password fields', 'ok')
    }
  }

  registerClicked(): void {
    if(this.registerEmailFormControl.valid && this.registerPasswordFormControl.valid && 
      this.registerEmailFormControl.value && this.registerPasswordFormControl.value){
      this.loginService.registerUserEmailPassword(
        this.registerEmailFormControl.value, this.registerPasswordFormControl.value)
    } else {
      this.snackBar.open('Please fill correctly the email and password fields', 'ok')
    }
  }

  loginWithGoogle(): void {
    this.loginService.loginWithGoogle()
    this.closeWindow()
  }

  closeWindow(): void {
    this.dialogRef.close();
  }

}
