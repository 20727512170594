
import { inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from './login.service';


export const authGuard: CanActivateFn = () => {
    const loginService = inject(LoginService);
    const router = inject(Router);

    return loginService.isLoggedIn.pipe(
        take(1),
        map(isLoggedIn => {
            if (!isLoggedIn) {
                router.navigate(['/login']);
                return false;
            }
            return true;
        })
    )
}