import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Article, ArticlesService } from '../../core/articles.service';
import { convert_int_to_ext_symbol } from 'src/app/core/helpers';
import { findCompaniesInText } from '../main-news/main-news.component';
import externalData from '../../../assets/company_name_data.json';
const companies: Array<{symbol: string, company_name: string}> = externalData


@Component({
  selector: 'app-articles-component',
  templateUrl: './articles-component.component.html',
  styleUrls: ['./articles-component.component.scss']
})
export class ArticlesComponentComponent implements OnInit {
  isLoading: boolean = true
  convert_int_to_ext_symbol = convert_int_to_ext_symbol
  private _searchQuery: string = "";
  @Input() set searchQuery(value: string) {
      this._searchQuery = value;
      this.initNewsList();
  }
  get searchQuery(): string {
    return this._searchQuery;
  }
  @Input() disable_auto_fetch: boolean = false
  @Input() full_page: boolean = true
  
  articles: Article[] = [];
  isFetching = false;

  @HostListener('window:scroll') onScroll(e: Event): void {
    this.detectEndOfPage();
  }

  constructor(private articlesService: ArticlesService) { }

  ngOnInit(): void {
    this.initNewsList();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  initNewsList(): void {
    this.isLoading = true
    this.articles = [];
    console.log("SearchQuery: ", this.searchQuery);
    if(this.searchQuery && this.searchQuery.length > 0){
      this.articlesService.getSearchArticlesFrom(this.searchQuery, 0).subscribe(articles => {
        articles.forEach(article => {
          article.companies = findCompaniesInText(article, companies)
        })
        this.articles = articles;
        this.isLoading = false
      });
    } else {
      this.articlesService.getArticlesFrom(0).subscribe(articles => {
        articles.forEach(article => {
          article.companies = findCompaniesInText(article, companies)
        })
        this.articles = articles;
        this.isLoading = false
      });
    }
  }

  fetchNewsArticles(): void {
    console.log("Need to fetch new articles on ",this.searchQuery);
    if(!this.isFetching){
      this.isFetching = true;

      if(this.searchQuery){
        this.articlesService.getSearchArticlesFrom(this.searchQuery, this.articles.length).subscribe(articles => {
          articles.forEach(article => {
            article.companies = findCompaniesInText(article, companies)
          })
          this.articles.push.apply(this.articles, articles);
          this.isFetching = false;
        });
      } else {
        this.articlesService.getArticlesFrom(this.articles.length).subscribe(articles => {
          articles.forEach(article => {
            article.companies = findCompaniesInText(article, companies)
          })
          this.articles.push.apply(this.articles, articles);
          this.isFetching = false;
        });
      }
    } else {
      //console.log("Already fetching articles");
    }
  }

  detectEndOfPage(): void {
    if(!this.disable_auto_fetch){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.fetchNewsArticles();
      }
    }
  }


}
