import { Component, inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/core/login.service';
import { UserService } from 'src/app/core/user.service';

export interface PriceTargetDialogData {
  symbol: string,
  lastStockPrice: number,
}

@Component({
  selector: 'app-price-target-modal',
  templateUrl: './price-target-modal.component.html',
  styleUrls: ['./price-target-modal.component.scss']
})
export class PriceTargetModalComponent {
  // _symbol: string|undefined
  // @Input() set symbol(value: string|undefined) {
  //   this._symbol = value;
  //   if(this.isLoggedIn){
  //     this.getPriceTarget();
  //   }
  // }

  readonly dialogRef = inject(MatDialogRef<PriceTargetModalComponent>);
  readonly data = inject<PriceTargetDialogData>(MAT_DIALOG_DATA);

  currentPriceTarget: number|undefined;
  newPriceTarget: number|undefined;
  isLoggedIn: boolean = false;
  constructor(private loginService: LoginService, private userService: UserService) { }

  ngOnInit(): void {
    this.isLoggedIn = this.loginService.isLoggedIn.value;
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
        this.getPriceTarget()
      }
    });
    if(this.data.lastStockPrice !== undefined) {
      this.newPriceTarget = Math.round(this.data.lastStockPrice*100)/100;
    }
  }

  async getPriceTarget() {
    if(this.data.symbol === undefined) return;

    const idToken = await this.loginService.getIdToken();
    this.userService.getPriceTargets(idToken).subscribe(priceTargets => {
      const priceTarget = priceTargets.filter(priceTarget => priceTarget.symbol === this.data.symbol);
      if(priceTarget.length > 0) {
        if(priceTarget.length > 1) console.error('Multiple price targets found for symbol:', this.data.symbol);
        this.currentPriceTarget = priceTarget[0].price_target;
      }
    })
  }

  async confirmPriceTarget() {
    const idToken = await this.loginService.getIdToken();
    if(this.newPriceTarget !== undefined && !isNaN(this.newPriceTarget) && this.data.symbol !== undefined) {
      this.userService.setPriceTarget(idToken, this.data.symbol, this.newPriceTarget)
        .subscribe(() => {
          console.log('New price target set successfully.');
          this.currentPriceTarget = this.newPriceTarget;
          this.newPriceTarget = undefined;
        });
    } else {
      console.error('Invalid price target value.');
    }
  }

  removePriceTarget() {
    this.newPriceTarget = 0;
    this.confirmPriceTarget();
  }
}
