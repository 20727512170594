import { Component, Input } from '@angular/core';
import { PillarData } from 'src/app/core/pillars.service';
import { getRiskyPillarsSorted, getTextRiskAssessmentForPillar } from './pillars_textual_risk';
@Component({
  selector: 'app-pillars-textual-risk',
  templateUrl: './pillars-textual-risk.component.html',
  styleUrls: ['./pillars-textual-risk.component.scss']
})
export class PillarsTextualRiskComponent {
  riskList: Array<string|null> = []
  @Input() pillars: Array<PillarData> = []
  @Input() use_strong?: boolean = true;
  @Input() limit?: number = 0;
  @Input() short?: boolean = false;

  initialNumberOfRisks: number = 0

  constructor(){}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.computeRisk()
  }

  computeRisk(): void {
    let riskyPillars = getRiskyPillarsSorted(this.pillars)
    this.initialNumberOfRisks = riskyPillars.length
    if(this.limit){
      riskyPillars = riskyPillars.slice(0, this.limit)
    }
    this.riskList = riskyPillars.map(x => getTextRiskAssessmentForPillar(x, this.use_strong, this.short))
  }
}
