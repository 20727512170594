import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AdName } from '../../components/advertise/advertise.component';
import {ChangeDetectionStrategy,} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { FlatTreeControl } from '@angular/cdk/tree';


// interface DocLink {
//   name: string;
//   children?: DocLink[];
// }

// interface DocLinkNode {
//   expandable: boolean;
//   name: string;
//   level: number;
// }

// const TREE_DATA: DocLink[] = [
//   {
//     name: 'Fruit',
//     children: [{name: 'Apple'}, {name: 'Banana'}, {name: 'Fruit loops'}],
//   },
//   {
//     name: 'Vegetables',
//     children: [
//       {
//         name: 'Green',
//         children: [{name: 'Broccoli'}, {name: 'Brussels sprouts'}],
//       },
//       {
//         name: 'Orange',
//         children: [{name: 'Pumpkins'}, {name: 'Carrots'}],
//       },
//     ],
//   },
// ];

@Component({
  selector: 'app-learn-page',
  templateUrl: './learn-page.component.html',
  styleUrls: ['./learn-page.component.scss'],
  // standalone: true,
  // imports: [MatTreeModule, MatButtonModule, MatIconModule],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnPageComponent {
  isSmallScreen: boolean = false;
  sidebarMode: MatDrawerMode = 'side'
  sidebarOpened: boolean = true
  AdName = AdName

  constructor(
    private breakpointObserver: BreakpointObserver,
    private title: Title,
    private el: ElementRef,
    private route: ActivatedRoute,
    private router: Router) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
          if(this.isSmallScreen){
            this.sidebarMode = 'over'
            this.sidebarOpened = false
          } else {
            this.sidebarMode = 'side'
            this.sidebarOpened = true
          }
    });
    this.title.setTitle('NeoTradr - Learn')
    // this.dataSource.data = TREE_DATA;
  }

  ngOnInit(): void {
    // const docLinks = this.buildDocLinksTree();
    // console.log(docLinks);
    // this.dataSource.data = docLinks;
  }

  // buildDocLinksTree(): DocLink[] {
  //   const sections = document.querySelectorAll('section');
  //   const docLinks: DocLink[] = [];

  //   sections.forEach(section => {
  //     const subtitles = section.querySelectorAll('.nt-subtitle, .nt-subsubtitle');
  //     if (subtitles.length > 0) {
  //       const sectionId = section.id;
  //       const sectionDocLink: DocLink = { name: sectionId, children: [] };

  //       subtitles.forEach(subtitle => {
  //         const subtitleText = subtitle.textContent?.trim() || '';
  //         sectionDocLink.children?.push({ name: subtitleText });
  //       });

  //       docLinks.push(sectionDocLink);
  //     }
  //   });

  //   return docLinks;
  // }

  ngAfterViewInit() {
    this.route.fragment.subscribe(fragment => {
      if(fragment){
        this.scrollToSection(fragment)
      }
    })
  }

  scrollToSection(name: string) {
    this.router.navigate(this.route.snapshot.url, {fragment: name})
    const element = this.el.nativeElement.querySelector('#'+name);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  // private _transformer = (node: DocLink, level: number) => {
  //   return {
  //     expandable: !!node.children && node.children.length > 0,
  //     name: node.name,
  //     level: level,
  //   };
  // };

  // treeControl = new FlatTreeControl<DocLinkNode>(
  //   node => node.level,
  //   node => node.expandable,
  // );

  // treeFlattener = new MatTreeFlattener(
  //   this._transformer,
  //   node => node.level,
  //   node => node.expandable,
  //   node => node.children,
  // );

  // dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  // hasChild = (_: number, node: DocLinkNode) => node.expandable;
}
