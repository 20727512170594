<mat-card>
    <mat-card-header>
        <mat-card-title class="nt-subtitle">
            Price Target
        </mat-card-title>
        <mat-card-subtitle>
            Set a price target for the stock. You will be notified when the price decrease to your price target.
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div class="nt-subsubtitle">Current stock price for {{this.data.symbol}}</div>
        <div>
            <h3>{{this.data.lastStockPrice | currency}}</h3>
        </div>
    </mat-card-content>
    <hr>
    <mat-card-content>
        <div class="nt-subsubtitle">Set price target for {{this.data.symbol}}</div>
        <div class="price-target-container">
            <div>Set Price Target for {{this.data.symbol}}:</div>
            <div>
                <mat-form-field appearance="outline">
                    <input type="number" matInput id="newPriceTarget" [(ngModel)]="newPriceTarget" />
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button  color="primary" (click)="confirmPriceTarget()">Confirm</button>
            </div>
        </div>
        <div *ngIf="this.newPriceTarget && this.data.lastStockPrice" class="change">
            <div *ngIf="this.newPriceTarget < this.data.lastStockPrice">
                That's {{this.data.lastStockPrice - this.newPriceTarget | number}} below the current stock price.
                ({{((this.data.lastStockPrice - this.newPriceTarget) / this.data.lastStockPrice * 100) | number}}%)
            </div>
            <div *ngIf="this.newPriceTarget > this.data.lastStockPrice">
                That's {{this.newPriceTarget - this.data.lastStockPrice | number}} above the current stock price.
                ({{((this.newPriceTarget - this.data.lastStockPrice) / this.data.lastStockPrice * 100) | number}}%)
            </div>
        </div>
    </mat-card-content>
    <hr>
    <mat-card-content>
        <div class="nt-subsubtitle">Saved price target</div>
        <div>
            <div *ngIf="currentPriceTarget">
                Current price target for <b>{{this.data.symbol}}</b>: {{ currentPriceTarget | currency }}
                <button style="position: relative; top:4px" mat-icon-button color="warn" (click)="removePriceTarget()">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div *ngIf="!currentPriceTarget">
                No price target set for <b>{{this.data.symbol}}</b>
            </div>
            <p *ngIf="!this.isLoggedIn" class="not-logged-in">Please log in to set a new price target.</p>
        </div>
    </mat-card-content>

</mat-card>
