import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { getLocalStorageItem, setLocalStorageItem } from '../core/local-storage-manager';
import { SymbolData } from '../components/symbol-list/symbol-list.component';
import { Router } from '@angular/router';
import { PillarData } from '../core/pillars.service';
import { NavigationService } from '../navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-discover-page',
  templateUrl: './discover-page.component.html',
  styleUrls: ['./discover-page.component.scss']
})
export class DiscoverPageComponent implements OnInit {
  isSmallScreen: boolean = false
  ntVersion: string = environment.ntVersion
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private navigation: NavigationService) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => {
          this.isSmallScreen = result.matches;
        });
  }

  ngOnInit(): void {
    
  }

  setSymbolToHistory(symbol: string, score: number, pillars_data: Array<PillarData>): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    savedHistory[symbol] = {
      symbol: symbol,
      timestamp: new Date(),
      score: score,
      pillars_data: pillars_data
    }
    setLocalStorageItem('symbolhistory', savedHistory, 60*60*24*365*15)
  }

  back(): void {
    this.navigation.back()
  }

}
