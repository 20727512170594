
<div class="container">
    <section class="my-5">
        <div class="row">
            <div class="col-12 text-center">
                <h2>Contact us</h2>
                <p>If you have any questions or feedback, please feel free to contact us:</p>
                <p>Email: <a href="mailto:contact@neotradr.com">contact&#64;neotradr.com</a></p>
            </div>
        </div>
    </section>
</div>
<div style="padding-top: 100px;">
    <app-footer></app-footer>
</div>
