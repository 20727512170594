import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { convert_int_to_ext_symbol } from 'src/app/core/helpers';
import { LoginService } from 'src/app/core/login.service';
import { UserService } from 'src/app/core/user.service';
import { PriceTargetDialogData, PriceTargetModalComponent } from '../price-target/price-target-modal/price-target-modal.component';

@Component({
  selector: 'app-price-target-list',
  templateUrl: './price-target-list.component.html',
  styleUrls: ['./price-target-list.component.scss']
})
export class PriceTargetListComponent {
  isLoggedIn: boolean = false;
  priceTargets: Array<{price_target: number, symbol: string, timestamp: Date}> = [];
  dataSource: Array<{price_target: number, symbol: string, timestamp: Date}> = [];
  displayedColumns = ['symbol', 'price_target', 'timestamp', 'actions']

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private dialog: Dialog) { }

  ngOnInit(): void {
    this.isLoggedIn = this.loginService.isLoggedIn.value;
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
        this.getPriceTarget()
      }
    });
  }

  async getPriceTarget() {
    const idToken = await this.loginService.getIdToken();
    this.userService.getPriceTargets(idToken).subscribe(priceTargets => {
      this.priceTargets = priceTargets;
      this.dataSource = priceTargets;
      console.log('Price targets:', priceTargets);
    })
  }

  editPriceTarget(elementData: {price_target: number, symbol: string, timestamp: Date}) {
    console.log('Edit price target:', elementData);
  }

  async deletePriceTarget(elementData: {price_target: number, symbol: string, timestamp: Date}) {
    console.log('Delete price target:', elementData);
    const idToken = await this.loginService.getIdToken();
    this.userService.setPriceTarget(idToken, elementData.symbol, 0).subscribe(() => {
      this.getPriceTarget();
    })
  }

  goToCompanyPage(symbol: string): void {
    this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
  }

  goToComparison(symbol: string): void {
    this.router.navigate(['/compare'],{queryParams: {company1: symbol}})
  }

  goToMomentumInvesting(symbol: string): void {
    this.router.navigate(['/momentum', symbol])
  }


  // openPriceTargetModalWindow(symbol: string) {
  //   const data: PriceTargetDialogData = {
  //     symbol: symbol,
  //     lastStockPrice: this.getLatestStockPric(symbol),
  //   }
  //   let dialogRef = this.dialog.open(PriceTargetModalComponent, {
  //     // width: '250px',
  //     data: data,
  //   });
}
