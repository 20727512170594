<app-counter-banner></app-counter-banner>
<section class="header-sections">
    <div class="container p-5">
        <div class="row py-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <app-search-bar [disableAction]="true" (clicked)="loadFirstSymbol($event)" [symbol]="companySymbol[0]"></app-search-bar>
            </div>
    
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                <app-search-bar [disableAction]="true" (clicked)="loadSecondSymbol($event)" [symbol]="companySymbol[1]"></app-search-bar>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngFor="let index of [0, 1]">
                <div *ngIf="this.companySymbol[index]">
                    <div [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" style="width: 48px; height: 48px;">
                        <button [matTooltip]="userIsLogged() ? 'Add to favorite' : 'You need to be logged in'" mat-icon-button (click)="swapFavorite(index)" [disabled]="!userIsLogged()">
                            <mat-icon *ngIf="isFavorite(index)" class="favorite-icon">star</mat-icon>
                            <mat-icon *ngIf="!isFavorite(index)">star_border</mat-icon>
                        </button>
                    </div>
                    <div>
                        <div class="mt-2">
                            <div class="nt-primary" style="font-family: Inter; font-size: 44px; font-weight: 800; line-height: 1.2;">
                                {{this.companySymbol[index]}}
                            </div>
                        </div>
                        <div class="mt-2">
                            <div class="mb-0" style="font-family: Inter; font-size: 18px; color: rgb(107, 114, 128); line-height: 1.4;">
                                {{this.companyName[index]}} 
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                    <div class="justify-content-center text-center mt-4">
                        <span class="mx-auto highlight-title">Global VIP Score</span>
                        <div class="row">
                            <div class="col-10">
                                <div *ngIf="this.vipScore[index]" [style]="
                                    'background-color:'+getColorFromScore(this.vipScore[index]!)
                                    + '; border-radius: 30px; padding: 10px;'">
                                        <b>{{this.vipScore[index] | number:'1.0-0'}}</b>/100
                                </div>
                            </div>
                            <div class="col-2">
                                <button mat-icon-button (click)="scrollToSection('valuation-section')">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                            </div>
                        </div>
                        <span *ngIf="!this.vipScore">-</span>
                    </div>
                </div>
                <div *ngIf="!this.companySymbol[index]">
                    <div class="justify-content-center text-center mt-4">
                        <div style="background-color: rgb(93, 93, 93); border-radius: 30px; padding: 20px;">
                            <span class="mx-auto highlight-title" style="color: white;">
                                Analyze a company first to see it here
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="mt-3" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
    <div class="container">

        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <mat-card class="my-2" id="valuation-section">
                    <mat-card-header>
                        <mat-card-subtitle>NeoTradr Value Investing Analysis</mat-card-subtitle>
                        <mat-card-title>Value Investing Analysis</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div style="min-width: 300px;" class="mx-auto">
                            <app-detailed-radar-view 
                            [data]="this.categoriesDetailed[0]"
                            [title]="this.companySymbol[0]"
                            [data2]="this.categoriesDetailed[1]"
                            [title2]="this.companySymbol[1]"
                            ></app-detailed-radar-view>
                        </div>
                        <div>
                            <mat-accordion displayMode="flat">
                                <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                                    <mat-expansion-panel-header>
                                        Legend
                                    </mat-expansion-panel-header>
                                    Company is analysed on five main perspective.
                                    <ul>
                                        <li>
                                            <b>Valuation</b> assesses the company's worth compared to its current market price. A higher valuation indicates favorable value for investors relative to the current stock price.
                                        </li>
                                        <li>
                                            <b>Financial Health</b> gauges the overall financial well-being of the company, focusing on aspects like stability, solvency, and long-term viability. A higher score reflects robust financial health.
                                        </li>
                                        <li>
                                            <b>Liquidity and Debt</b> analyzes the company's debt levels and available cash. A higher rating suggests effective debt management and reduced financial risk.
                                        </li>
                                        <li>
                                            <b>Profitability and Growth</b> examines the company's ability to generate profits and sustain growth. A higher score signifies strong performance, translating into favorable returns for shareholders.
                                        </li>
                                        <li>
                                            <b>Return on Investment</b> evaluates the return generated for shareholders. A higher ROI indicates a more lucrative investment, showcasing the company's efficiency in delivering returns to its investors.
                                        </li>
                                    </ul>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        
        <hr>
        <span class="highlight-header nt-primary">Highlights</span>
        <button style="position: relative; top: 4px;" mat-icon-button (click)="this.shortHightlights = !this.shortHightlights">
            <mat-icon *ngIf="!this.shortHightlights">unfold_less</mat-icon>
            <mat-icon *ngIf="this.shortHightlights">unfold_more</mat-icon>
        </button>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-1">
                <mat-card class="highlight-card my-1">
                    <mat-card-header class="mb-1">
                        <span matTooltip="Pillars representing internal strenghs of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                            Strengths
                        </span>
                    </mat-card-header>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-card-content *ngIf="this.pillarsData[0]">
                                <app-pillars-textual-strength [pillars]="this.pillarsData[0]" [(short)]="this.shortHightlights"></app-pillars-textual-strength>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[0]">
                                -
                            </mat-card-content>
                        </div>
                        <div class="col-md-6">
                            <mat-card-content *ngIf="this.pillarsData[1]">
                                <app-pillars-textual-strength [pillars]="this.pillarsData[1]" [(short)]="this.shortHightlights"></app-pillars-textual-strength>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[1]">
                                -
                            </mat-card-content>
                        </div>
                    </div>
                
                    <mat-card-header class="mb-1">
                        <span matTooltip="Pillars representing internal weaknesses of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                            Weaknesses
                        </span>
                    </mat-card-header>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-card-content *ngIf="this.pillarsData[0]">
                                <app-pillars-textual-weakness [pillars]="this.pillarsData[0]" [(short)]="this.shortHightlights"></app-pillars-textual-weakness>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[0]">
                                -
                            </mat-card-content>
                        </div>
                        <div class="col-md-6">
                            <mat-card-content *ngIf="this.pillarsData[1]">
                                <app-pillars-textual-weakness [pillars]="this.pillarsData[1]" [(short)]="this.shortHightlights"></app-pillars-textual-weakness>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[1]">
                                -
                            </mat-card-content>
                        </div>
                    </div>

                    <mat-card-header class="mb-1">
                        <span matTooltip="Pillars representing external opportunities of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                            Opportunities
                        </span>
                    </mat-card-header>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-card-content style="text-align: center; position: relative;" *ngIf="this.pillarsData[0]">
                                <!-- <div *ngIf="!this.isLoggedIn" [class.div_blur]="!this.isLoggedIn"></div>
                                <div *ngIf="!this.isLoggedIn" [class.div_blur_text]="!this.isLoggedIn">
                                    <span matTooltip="Login to access this feature">Login</span>
                                </div> -->
                                <app-pillars-textual-opportunity [pillars]="this.pillarsData[0]" [(short)]="this.shortHightlights"></app-pillars-textual-opportunity>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[0]">
                                -
                            </mat-card-content>
                        </div>
                        <div class="col-md-6">
                            <mat-card-content style="text-align: center; position: relative;" *ngIf="this.pillarsData[1]">
                                <!-- <div *ngIf="!this.isLoggedIn" [class.div_blur]="!this.isLoggedIn"></div>
                                <div *ngIf="!this.isLoggedIn" [class.div_blur_text]="!this.isLoggedIn">
                                    <span matTooltip="Login to access this feature">Login</span>
                                </div> -->
                                <app-pillars-textual-opportunity [pillars]="this.pillarsData[1]" [(short)]="this.shortHightlights"></app-pillars-textual-opportunity>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[1]">
                                -
                            </mat-card-content>
                        </div>
                    </div>
                
                    <mat-card-header class="mb-1">
                        <span matTooltip="Pillars representing external threats of the company" (click)="this.shortHightlights = !this.shortHightlights" class="mx-auto highlight-title">
                            Threats
                        </span>
                    </mat-card-header>
                    <div class="row">
                        <div class="col-md-6">
                            <mat-card-content style="text-align: center; position: relative;" *ngIf="this.pillarsData[0]">
                                <!-- <div *ngIf="!this.isLoggedIn" [class.div_blur]="!this.isLoggedIn"></div>
                                <div *ngIf="!this.isLoggedIn" [class.div_blur_text]="!this.isLoggedIn">
                                    <span matTooltip="Login to access this feature">Login</span>
                                </div> -->
                                <app-pillars-textual-threat [pillars]="this.pillarsData[0]" [(short)]="this.shortHightlights"></app-pillars-textual-threat>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[0]">
                                -
                            </mat-card-content>
                        </div>
                        <div class="col-md-6">
                            <mat-card-content style="text-align: center; position: relative;" *ngIf="this.pillarsData[1]">
                                <!-- <div *ngIf="!this.isLoggedIn" [class.div_blur]="!this.isLoggedIn"></div>
                                <div *ngIf="!this.isLoggedIn" [class.div_blur_text]="!this.isLoggedIn">
                                    <span matTooltip="Login to access this feature">Login</span>
                                </div> -->
                                <app-pillars-textual-threat [pillars]="this.pillarsData[1]" [(short)]="this.shortHightlights"></app-pillars-textual-threat>
                            </mat-card-content>
                            <mat-card-content *ngIf="!this.pillarsData[1]">
                                -
                            </mat-card-content>
                        </div>
                    </div>
                </mat-card>
            </div> 
        </div>

        <mat-card-content *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-content>
        <hr>

        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let index  of [0, 1]">
                <mat-card class="my-2" *ngIf="this.companyName[index]">
                    <mat-card-header>
                        <mat-card-subtitle>Company profile</mat-card-subtitle>
                        <mat-card-title>{{this.companyName[index]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="px-0">
                        <mat-accordion displayMode="flat">
                            <mat-expansion-panel [expanded]="false" class="mat-elevation-z0">
                                <mat-expansion-panel-header>
                                    Company description
                                </mat-expansion-panel-header>
                                {{this.companyDescription[index]}}
                            </mat-expansion-panel>
                        </mat-accordion>

                        <mat-list>
                            <mat-list-item>
                                Sector: {{this.companySector[index]}}
                            </mat-list-item>
                            <mat-list-item>
                                Industry: {{this.companyIndustry[index]}}
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <hr>
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let index  of [0, 1]">
                <mat-card class="mt-3" *ngIf="this.companyName[index]">
                    <mat-card-header>
                        <mat-card-subtitle>{{this.companyName[index]}} financial metrics</mat-card-subtitle>
                        <mat-card-title>Metrics</mat-card-title>
                    </mat-card-header>
                    <mat-card-content >
                        <mat-list>
                            <mat-list-item *ngFor="let metric of this.metrics[index]">
                                <span *ngIf="metric">{{metric.name}}:</span>
                                <span *ngIf="metric"> {{metric.value}}</span>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <hr>

        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <mat-card class="my-2" id="price-section" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
                    <mat-card-header>
                        <mat-card-subtitle>Price</mat-card-subtitle>
                        <mat-card-title>Stock price for {{this.companySymbol[0]}} and {{this.companySymbol[1]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-timeplot [chart_title]="['Price '+companySymbol[0], 'Price '+companySymbol[1]]" [time_values]="priceData[0].labels" [values]="[priceData[0].values, priceData[1].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let index  of [0, 1]">
                <div *ngIf="this.companySymbol[index]">
                    <app-technical-pillars-list id="technical-section" [stockPriceData]="this.priceData[index]"></app-technical-pillars-list>

                    <mat-card class="my-2" id="risk-section">
                        <mat-card-header>
                            <mat-card-subtitle>Explanation of current risks analyzed on {{this.companySymbol[index]}}</mat-card-subtitle>
                            <mat-card-title>Risks for {{this.companySymbol[index]}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="mx-auto">
                                <app-pillars-textual-risk [pillars]="pillarsData[index]!"></app-pillars-textual-risk>
                            </div>
                        </mat-card-content>
                        <mat-card-content *ngIf="isLoading">
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let index  of [0, 1]">
                <mat-card class="my-2" id="pillars-section" *ngIf="this.companySymbol[index]">
                    <app-premium-feature-icon class="top-right-corner"></app-premium-feature-icon> 
                    <mat-card-header>
                        <mat-card-subtitle>Value Investing Pillars analysis with detailed results</mat-card-subtitle>
                        <mat-card-title>Pillars details for {{this.companySymbol[index]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="px-0" style="position: relative;">
                        <!-- <div *ngIf="!userHasPremium()" [class.div_blur]="!userHasPremium()"></div>
                        <div *ngIf="!userHasPremium()" [class.div_blur]="!userHasPremium()">
                            Join Premium to access this feature
                            <app-go-premium-button color="accent">Join Premium</app-go-premium-button>
                        </div>
                        <app-pillars [pillars]="pillarsData[index]!" [class.blur]="!this.isLoggedIn"></app-pillars> -->
                        <app-pillars [pillars]="pillarsData[index]!" [class.blur]="!userHasPremium()"></app-pillars>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>

                <mat-card class="my-2" id="revenue-section" *ngIf="this.companySymbol[index]">
                    <mat-card-header>
                        <mat-card-subtitle>Revenue, Net Income and Free Cashflow data</mat-card-subtitle>
                        <mat-card-title>Revenue, Net Income and FCF for {{this.companySymbol[index]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-timeplot [chart_title]="['Revenue ($)', 'Net Income', 'Free CashFlow']" [time_values]="revenueData[index].labels" [values]="[revenueData[index].values, netIncomeData[index].values, freeCashflow[index].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        
        <hr>

        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="my-2" id="share-outstanding-section" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
                    <mat-card-header>
                        <mat-card-subtitle>Number of shares</mat-card-subtitle>
                        <mat-card-title>Share outstanding for {{this.companySymbol[0]}} and {{this.companySymbol[1]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <!-- [chart_title]="['Price '+companySymbol[0], 'Price '+companySymbol[1]]" [time_values]="priceData[0].labels" [values]="[priceData[0].values, priceData[1].values]" -->
                        <app-timeplot [chart_title]="['Number of shares '+companySymbol[0], 'Number of shares '+companySymbol[1]]" [time_values]="shareOutstanding[0].labels" [values]="[shareOutstanding[0].values, shareOutstanding[1].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="my-2" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
                    <mat-card-header>
                        <mat-card-subtitle>Total non current liabilities</mat-card-subtitle>
                        <mat-card-title>Long term liabilities for {{this.companySymbol[0]}} and {{this.companySymbol[1]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-timeplot [chart_title]="['Long term liabilities '+companySymbol[0], 'Long term liabilities '+companySymbol[1]]" [time_values]="longTermLiabilities[0].labels" [values]="[longTermLiabilities[0].values, longTermLiabilities[1].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="my-2" id="dividend-section" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
                    <mat-card-header>
                        <mat-card-subtitle>Dividends</mat-card-subtitle>
                        <mat-card-title>Dividends for {{this.companySymbol[0]}} and {{this.companySymbol[1]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-timeplot [chart_title]="['Dividends ($) '+companySymbol[0], 'Dividends ($) '+companySymbol[1]]" [time_values]="dividends[0].labels" [values]="[dividends[0].values, dividends[1].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="my-2" id="roic-section" *ngIf="this.companySymbol[0] && this.companySymbol[1]">
                    <mat-card-header>
                        <mat-card-subtitle>Return On Invested Capital</mat-card-subtitle>
                        <mat-card-title>ROIC for {{this.companySymbol[0]}} and {{this.companySymbol[1]}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-timeplot [chart_title]="['ROIC (%) '+companySymbol[0], 'ROIC (%) '+companySymbol[1]]" [time_values]="roicData[0].labels" [values]="[roicData[0].values, roicData[1].values]"></app-timeplot>
                    </mat-card-content>
                    <mat-card-content *ngIf="isLoading">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <app-explore-more></app-explore-more>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-12 my-4">
                Disclaimer: The information provided on this website is for educational and informational purposes only and should not be construed as financial advice. Users are solely responsible for any decisions or actions they take based on the information provided. This website makes no representations as to the accuracy, completeness, suitability, or validity of any information and will not be liable for any errors, omissions, or delays in this information or any losses, injuries, or damages arising from its display or use. It is recommended that users consult with a qualified financial advisor or professional before making any investment decisions.
            </div>
        </div>
    </div>
</section>

<section>
    <app-footer></app-footer>
</section>
