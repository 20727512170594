import { Directive, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
  selector: '[appScrollToTop]'
})
export class ScrollToTopDirective {

  constructor(private router: Router) { }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    // Listen for the scroll event and store the current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    sessionStorage.setItem('scrollPosition', scrollPosition.toString());
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Listen for document click events (e.g., navigation links) and store the current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    sessionStorage.setItem('scrollPosition', scrollPosition.toString());
  }

  ngOnInit() {
    // Subscribe to router navigation events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // When navigation ends, scroll to the top of the page
        window.scrollTo(0, 0);

        // Optionally, you can restore the scroll position from sessionStorage
        const storedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (storedScrollPosition) {
          window.scrollTo(0, parseInt(storedScrollPosition, 10));
          sessionStorage.removeItem('scrollPosition'); // Remove the stored scroll position
        }
      }
    });
  }
}
