<section>
    <div class="container">
        <div class="row py-0 my-0 justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 text-center">
                <div class="mt-5">
                    <div class="nt-primary my-4" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                        System Status
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <ul>
                <li>Nb total events: {{this.eventUpdate.length}}</li>
                <li>Nb data updated events: {{this.eventDataUpdated.length}}</li>
                <li>Nb pillars computed events: {{this.eventPillarsComputed.length}}</li>
            </ul>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-6 col-sm-12">
                <h3>Event Data Updated</h3>
                <ul>
                    <li *ngFor="let d of this.eventDataUpdated">
                        {{d.event_name}} => {{d.timestamp}}
                    </li>
                </ul>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12">
                <h3>Event Pillars Computed</h3>
                <ul>
                    <li *ngFor="let d of this.eventPillarsComputed">
                        {{d.event_name}} => {{d.timestamp}}
                    </li>
                </ul>
            </div>
        </div>
    </div>    
</section>

<section>
    <div style="box-sizing: border-box; z-index: 14;border-top: 1px solid rgb(204, 204, 204); margin-top: 30px;">
        <div class="" style="margin-top: 8px; height: 45px;">
            <div style="z-index: 13; font-family: Inter; font-size: 14px; font-weight: 300; color: rgb(107, 114, 128); text-align: center; line-height: 1.4; padding-top: 8px;">
                Copyrights © NeoTradr 2023. All Rights Reserved.
            </div>
        </div>
    </div>
</section>
