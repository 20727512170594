import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-login-required',
  templateUrl: './login-required.component.html',
  styleUrls: ['./login-required.component.scss']
})
export class LoginRequiredComponent {
  @Input() tooltipText: string = "Login to access this feature"
  @Input() isLoggedIn: boolean = false
}
