import { arrayRange, leastSquares1d } from "src/app/core/helpers";
import { PillarData } from "src/app/core/pillars.service";

export interface TechnicalDataForPillars {
    /**
     * Contains all the data needed to compute any technical pillars
     */
    stockPrice: Array<number>,
    smaShortTermData: Array<number>,
    smaLongTermData: Array<number>,
    rsiData: Array<number>,
    metadata: {
        shortTermSMAPeriod: number,
        longTermSMAPeriod: number,
        rsiPeriod: number,
        shortTermSMAOffset: number,
        longTermSMAOffset: number,
        rsiOffset: number,
    }
}

export const computeSma = (data: Array<number>, window: number): {data: Array<number>, offset: number} => {
    let res = null;
    for(let i = window; i < data.length; i++){
      const slice = data.slice(i-window, i);
      const sum = slice.reduce((prev, cur) => prev + cur, 0);
      if(res == null){
        // res = Array.from(Array(window)).map(x => sum / window)
        res = Array.from(Array(window)).map(x => Number.NaN)
      }
      res.push(sum/window)
    }
    return {data: res ? res : [], offset: window}
}

export const computeRSI = (prices: number[], window: number): {data: Array<number>, offset: number} => {
    /**
     * 
     * @param prices : list of prices as number
     * @returns list of rsi data with same length as original data. Padding on left.
     */
    const computeSingleRSI = (closingPrices: Array<number>) => {
        // Calculate the average of the upward price changes
        let avgUpwardChange = 0;
        for (let i = 1; i < closingPrices.length; i++) {
            avgUpwardChange += Math.max(0, closingPrices[i] - closingPrices[i - 1]);
        }
        avgUpwardChange /= closingPrices.length;
      
        // Calculate the average of the downward price changes
        let avgDownwardChange = 0;
        for (let i = 1; i < closingPrices.length; i++) {
            avgDownwardChange += Math.max(0, closingPrices[i - 1] - closingPrices[i]);
        }
        avgDownwardChange /= closingPrices.length;
      
        // Calculate the RSI
        const rsi = 100 - (100 / (1 + (avgUpwardChange / avgDownwardChange)));
        return rsi;
    }
    
    let res = null;
    for(let i = window; i < prices.length; i++){
      const slice = prices.slice(i-window, i);
      const rsi = computeSingleRSI(slice)
      if(res == null){
        res = Array.from(Array(window)).map(x => rsi)
      }
      res.push(rsi)
    }
    return {data: res ? res : [], offset: window}
}

export const computeTechnicalData = (
  stockPriceData: Array<number>, 
  shortTermMAPeriod=5,
  longTermMAPeriod=25,
  rsiPeriod=14): TechnicalDataForPillars => {
    const smaShort = computeSma(stockPriceData, shortTermMAPeriod)
    const smaLong = computeSma(stockPriceData, longTermMAPeriod)
    const rsi =  computeRSI(stockPriceData, rsiPeriod)
    return {
        stockPrice: stockPriceData,
        smaShortTermData: smaShort.data,
        smaLongTermData: smaLong.data,
        rsiData: rsi.data, 
        metadata:{
            shortTermSMAPeriod: shortTermMAPeriod,
            longTermSMAPeriod: longTermMAPeriod,
            rsiPeriod: rsiPeriod,
            shortTermSMAOffset: smaShort.offset,
            longTermSMAOffset: smaLong.offset,
            rsiOffset: rsi.offset,
        }
    }
}

export const extractTechnicalSentiment = (technicalPillars: Array<PillarData>, decisionThreshold: number=0.2): 
  {
    sentiment: 'buy'|'no_buy'|'no_decision',
    global_score: number,
    global_score_percentage: number,
    global_score_color: string,
  } => {
    /**
     * Extracting Technical action to take according to pillars data.
     * Compared to Value Investing Pillars the technical pillars are fighting around 0
     */
    let sentiment:'buy'|'no_buy'|'no_decision' = 'no_decision'
    const maximum_possible_score = technicalPillars.reduce((acc, cur) => acc + cur.weight_max_value, 0)
    const minimum_possible_score = technicalPillars.reduce((acc, cur) => acc + cur.weight_min_value, 0)
    const tapScore = technicalPillars.reduce((acc, cur) => acc + cur.weight, 0)
    let color = "black"
    if(tapScore < -technicalPillars.length * decisionThreshold){
        sentiment = 'no_buy'
        color = 'red'
    } else if(tapScore > technicalPillars.length * decisionThreshold){
        sentiment = 'buy'
        color = 'green'
    } else {
        sentiment = 'no_decision'
        color = 'orange'
    }

    const percentage = 100*(tapScore - minimum_possible_score)/(maximum_possible_score-minimum_possible_score)

    return {
      global_score: tapScore,
      sentiment: sentiment,
      global_score_percentage: percentage,
      global_score_color: color
    }
}

export const computeTechnicalPillar1 = (technicalData: TechnicalDataForPillars): PillarData => {
    /**
     * Return True if stock price is greater than long term trend
     */
    const warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
    const lastSmaLT = technicalData.smaLongTermData[technicalData.smaLongTermData.length-1]
    const lastStockPrice = technicalData.stockPrice[technicalData.stockPrice.length-1]
    if(lastStockPrice > lastSmaLT){
      score = 0.5
      is_valid = true
    } else {
      score = -1
      is_valid = false
    }
    // Short term price is higher than long term moving average
    return  {
      id: "tap1",
      short_description: "Short term trend analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -1,
      weight_max_value: 0.5,
      data_info: undefined,
      result: is_valid,
      value: value
  
    }
}
  
export const computeTechnicalPillar2 = (technicalData: TechnicalDataForPillars): PillarData => {
    /**
     * Return True if global trend positive, otherwise false
     */
    const warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
  
    const recentSelection = technicalData.smaLongTermData.slice(technicalData.smaLongTermData.length-5, technicalData.smaLongTermData.length-1)
    const [lt_growth, b] = leastSquares1d(arrayRange(0, recentSelection.length, 1), recentSelection)
  
    if(lt_growth*100 > 1){
      score = 0.8
      is_valid = true
    } else if(lt_growth < -1) {
      score = -1
      is_valid = false
    } else {
      score = -0.5
      is_valid = false
    }
  
    return  {
      id: "tap2",
      short_description: "Long term trend analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -1,
      weight_max_value: 0.8,
      data_info: undefined,
      result: is_valid,
      value: value
    }
}
  
export const computeTechnicalPillar3 = (technicalData: TechnicalDataForPillars): PillarData => {
    /**
     * If RSI is decreasing and below 50(40): not good : -1
     * If RSI is decreasing 50(40) and increasing: not good : -0.5
     * If RSI is above 50(60) and < 90 and increasing: good: +1
     * If RSI is above 50(60) and < 90 and decreasing: good: +0.5
     * If RSI is above 90 and decreasing: 0
     * If RSI is in middle zone: 0
     */
    const warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
  
    const lastRsi = technicalData.rsiData[technicalData.rsiData.length-1]
    const recentRsi = technicalData.rsiData.slice(technicalData.rsiData.length-5, technicalData.rsiData.length-1)
    const [lt_growth, b] = leastSquares1d(arrayRange(0, recentRsi.length, 1), recentRsi)

    const rsiLowerBound = 43
    const rsiUpperBound = 57
    const rsiExtremeUpperBound = 90
    if(lastRsi < rsiLowerBound && lt_growth < 0){
      score = -1
    }
    else if(lastRsi < rsiLowerBound && lt_growth > 0){
      score = -0.5
    }
    else if(lastRsi > rsiUpperBound && lastRsi < rsiExtremeUpperBound && lt_growth > 0){
      score = 1
    }
    else if(lastRsi > rsiUpperBound && lastRsi < rsiExtremeUpperBound && lt_growth < 0){
      score = 0.5
    }
    else if(lastRsi > rsiExtremeUpperBound) {
      score = 0
    }
    else{
      score = 0
    }
  
    return  {
      id: "tap3",
      short_description: "Relative Strengh Index analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -1,
      weight_max_value: 1,
      data_info: undefined,
      result: is_valid,
      value: value
  
    }
}

export const getTechnicalPillarsFunctions = () => {
    return [
        computeTechnicalPillar1,
        computeTechnicalPillar2,
        computeTechnicalPillar3
    ]
}
export const computeAllTechnicalPillars = (technicalData: TechnicalDataForPillars): Array<PillarData> => {
    const res = []
    res.push(computeTechnicalPillar1(technicalData))
    res.push(computeTechnicalPillar2(technicalData))
    res.push(computeTechnicalPillar3(technicalData))
    return res
}
