<h1 mat-dialog-title>Pillar {{data.id}} data</h1>
<div mat-dialog-content>
    <h2>{{data.short_description}}</h2>

    <h3>Warnings</h3>
    <div *ngIf="data.warnings">
        <mat-list>
            <mat-list-item *ngFor="let data of data.warnings">
                <span matLine>⚠️ {{data}}</span>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="!data.warnings || !data.warnings.length">
        <span>No data to display</span>
    </div>

    <hr>

    <h3>Data used to compute the pillar</h3>
    <div *ngIf="data.data_info">
        <mat-list>
            <mat-list-item lines="5" *ngFor="let data of data.data_info">
                <span matListItemTitle>{{data?.financial_id}}</span>
                <span >- symbol: {{data?.symbol}}</span><br>
                <span >- value: {{data?.value}}</span><br>
                <span >- lastly computed on {{data?.timestamp | date}} (data date: {{data?.data_date | date}})</span><br>
                <span >- source: {{data?.source}}</span><br>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="!data.data_info || !data.data_info.length">
        <span>No data to display</span>
    </div>
    
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Ok</button>
</div>
