import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/core/login.service';
import { LoginWindowComponent } from './login-window/login-window.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input('color') color: string = "primary"
  
  constructor(private loginService: LoginService, public dialog: MatDialog) { }

  ngOnInit(): void {
    //TODO: Make a popup pop with different login methods
  }

  buttonClicked(): void {
    let dialogRef = this.dialog.open(LoginWindowComponent, {
      
    });
  }
}
