import { Component, Inject, Input, OnInit } from '@angular/core';
import { PillarData, PillarsService } from 'src/app/core/pillars.service';
import { getResultIcon as _getResultIcon } from 'src/app/core/helpers';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog'
@Component({
  selector: 'app-pillars',
  templateUrl: './pillars.component.html',
  styleUrls: ['./pillars.component.scss']
})
export class PillarsComponent implements OnInit {
  private _pillars: Array<PillarData> = []
  public vipScore: number|undefined
  
  @Input() 
  set pillars(value: Array<PillarData>){
    this._pillars = value
    this.vipScore = this.pillarsService.computeVipScore(this._pillars)
  }
  get pillars(): Array<PillarData>{
    return this._pillars
  }
  
  displayedColumns: string[] = [
    'id', 
    // 'result',
    'short_description', 
    'value',
    'info'];

  constructor(private pillarsService: PillarsService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  getResultIcon(res: boolean|undefined): string {
    return _getResultIcon(res)
  }

  openDataInfo(pillar: PillarData): void {
    const _dialogRef = this.dialog.open(PillarDataDialog, {
      data: pillar,
      width: '90%'
    });
  }

}


@Component({
  selector: 'pillar-data-dialog',
  templateUrl: 'pillar_data_dialog.html',
})
export class PillarDataDialog {
  constructor(
    public dialogRef: MatDialogRef<PillarDataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PillarData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}