export class FinancialData {
    static ID = class {
        static BOOK_VALUE_PER_SHARE="book_value_per_share"
        static COMPANY_NAME="company_name"
        static COMPANY_DESCRIPTION="company_description"
        static COMPANY_INDUSTRY="company_industry"
        static COMPANY_SECTOR="company_sector"
        static DCF_VALUATION="dcf_valuation"
        static DIVIDEND="dividend"
        static DIVIDEND_YIELD="dividend_yield"
        static EBT="ebt"
        static EBITDA="ebitda"
        static ENTERPRISE_VALUE="enterprise_value"
        static FREE_CASHFLOW="free_cashflow"
        static MARKET_CAPITALIZATION="market_capitalization"
        static NET_INCOME="net_income"
        static NET_INCOME_PER_SHARE="net_income_per_share"
        static NUMBER_SHARES="number_shares"
        static PAYOUT_RATIO="payout_ratio"
        static PER="per"
        static PRICE_TO_BOOK_RATIO="price_to_book_ratio"
        static REVENUE="revenue"
        static RETURN_ON_EQUITY="roe"
        static ROIC="roic"
        static STOCK_COUNTRY="stock_country"
        static STOCK_CURRENCY="stock_currency"
        static STOCK_EXCHANGE="stock_exchange"
        static STOCK_PRICE="stock_price"
        static STOCK_PRICE_DAILY="stock_price_daily"
        static STOCK_VOLUME="stock_volume"
        static TOTAL_NON_CURRENT_LIABILITIES="total_non_current_liabilities"
    }
    static NAME = class {
        static BOOK_VALUE_PER_SHARE="Book value per share"
        static COMPANY_NAME="Company name"
        static DIVIDEND="Dividend"
        static DIVIDEND_YIELD="Dividend yield"
        static EBT="Earnings before taxes"
        static EBITDA="EBITDA"
        static ENTERPRISE_VALUE="enterprise_value"
        static FREE_CASHFLOW="Free cash flow"
        static MARKET_CAPITALIZATION="Market capitalization"
        static NET_INCOME="Net Income"
        static NET_INCOME_PER_SHARE="Net income per share"
        static NUMBER_SHARES="Number of shares"
        static PAYOUT_RATIO="Payout Ratio"
        static PER="Price / Earnings ratio"
        static PRICE_TO_BOOK_RATIO="Price / Book"
        static REVENUE="Revenue"
        static RETURN_ON_EQUITY="Return on Equity"
        static ROIC="Roic"
        static STOCK_EXCHANGE="Stock exchange"
        static STOCK_PRICE="Stock price"
        static STOCK_PRICE_DAILY="Stock price daily"
        static STOCK_VOLUME="Stock volume"
        static TOTAL_NON_CURRENT_LIABILITIES="Total Non current liabilities"
    }
}

export interface DbData {
    data_date: Date,
    financial_id: string,
    source: string,
    symbol: string,
    data_format: 'float'|'text',
    timestamp: Date,
    value: number
    text_value?: string
}
