import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { getPorfolioIdNameList } from 'src/app/portfolio-page/portfolio_data';

function getRandomElementsFromArray<T>(array: T[], N: number): T[] {
  const result: T[] = [];
  if (N <= 0) {
    return result;
  }
  if (N >= array.length) {
    return [...array];
  }

  const indices: number[] = [];
  while (indices.length < N) {
    const randomIndex = Math.floor(Math.random() * array.length);
    if (!indices.includes(randomIndex)) {
      indices.push(randomIndex);
    }
  }

  for (const index of indices) {
    result.push(array[index]);
  }
  return result;
}


@Component({
  selector: 'app-explore-more',
  templateUrl: './explore-more.component.html',
  styleUrls: ['./explore-more.component.scss']
})
export class ExploreMoreComponent {
  buttons: Array<{name: string, link: string, icon?: string}> = []
  @Input() nbButtons = 6
  @Input() title: string = "Explore more!"
  usableLink: Array<{name: string, link: string, icon?: string}> = [
    {name: "Best ranked companies", link: 'discover', icon: 'stars'},
    {name: "Business news", link: 'news', icon: 'subject'},
    {name: "Apple Analysis", link: 'company/AAPL', icon: 'trending_up'},
    {name: "Microsoft Analysis", link: 'company/MSFT', icon: 'trending_up'},
    {name: "Adobe Analysis", link: 'company/ADBE', icon: 'trending_up'},
    {name: "NVidia Analysis", link: 'company/NVDA', icon: 'trending_up'},
    {name: "CF Analysis", link: 'company/CF', icon: 'trending_up'},
    {name: "ADM Analysis", link: 'company/ADM', icon: 'trending_up'},
    {name: "Realty Income Analysis", link: 'company/O', icon: 'trending_up'},
    {name: "Coca-Cola Analysis", link: 'company/KO', icon: 'trending_up'},
    {name: "Johnson & Johnson Analysis", link: 'company/JNJ', icon: 'trending_up'},
    {name: "Microsoft | Apple", link: 'compare?company1=MSFT&company2=AAPL', icon: 'compare_arrows'},
    {name: "Visa | Mastercard", link: 'compare?company1=V&company2=MA', icon: 'compare_arrows'},
    {name: "Tesla | Ford", link: 'compare?company2=F&company1=TSLA', icon: 'compare_arrows'},
    {name: "Walmart | Costco", link: 'compare?company2=COST&company1=WMT', icon: 'compare_arrows'},
    ...getPorfolioIdNameList().map(x => { 
      return {name: x.portfolio_name, link: `portfolio/${x.id}`, icon: 'bar_chart'} 
    })
  ]
  constructor(private router: Router){}

  ngOnInit(): void {
    this.buttons = getRandomElementsFromArray(this.usableLink, this.nbButtons)    
  }

  goTo(path: string): void {
    this.router.navigate([path])
  }
}
