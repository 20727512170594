import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { convert_ext_to_int_symbol, convert_int_to_ext_symbol, getColorFromScore } from 'src/app/core/helpers';
import { LoginService } from 'src/app/core/login.service';
import { HighestScoreData } from 'src/app/core/pillars.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-most-analyzed-companies',
  templateUrl: './most-analyzed-companies.component.html',
  styleUrls: ['./most-analyzed-companies.component.scss']
})
export class MostAnalyzedCompaniesComponent {
  @Input() limit: number = 0
  highestRanking: Array<HighestScoreData> = []
  displayedColumns = ['symbol', 'action']
  dataSource: MatTableDataSource<HighestScoreData> = new MatTableDataSource()
  isLoading: boolean = false
  isLoggedIn: boolean = false
  convert_int_to_ext_symbol = convert_int_to_ext_symbol
  convert_ext_to_int_symbol = convert_ext_to_int_symbol
  getColorFromScore = getColorFromScore

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService
  ){}

  ngOnInit(): void {
    this.isLoading = true
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('isLoggedChanged: ', isLoggedIn)
      this.isLoggedIn = isLoggedIn
      this.refreshTable()
    })
    this.refreshTable()
  }

  refreshTable(): void {
    this.isLoading = true
    this.userService.getMostLikedCompanies().subscribe(mostLikedCompanies => {
      this.highestRanking = mostLikedCompanies.sort((a, b) => b.count - a.count)
      .map(x => {
          return {...x, 
            company_name: "FAKE Name FAKE Name FAKE Name",
            score: 100, 
            'company_sector': 'ND', 
            'company_industry': 'ND',
          }
      })
      
      this.highestRanking = this.limit > 0 ? this.highestRanking.slice(0, this.limit) : this.highestRanking
      this.dataSource = new MatTableDataSource<HighestScoreData>(this.highestRanking)
      this.isLoading = false
    })
    
    

    // this.pillarsService.getHighestScoreRanking().subscribe(highestRanking => {
    //   this.highestRanking = highestRanking.filter((val, i, arr) => {
    //     return arr.findIndex(x => x.symbol == val.symbol) == i ? true : false
    //   }).map((val, index) => {
    //     if(!this.isLoggedIn && (/*index < 5 ||*/ index > 18)){
    //       val.score = 100
    //       val.symbol = "FAKE"
    //       val.company_name = "FAKE Industries"
    //       return {...val, 'blur': true}
    //     } else {
    //       return {...val, 'blur': false}
    //     }
    //   })
    //   this.highestRanking = this.limit > 0 ? this.highestRanking.slice(0, this.limit) : this.highestRanking
    //   this.dataSource = new MatTableDataSource<HighestScoreData & {blur: boolean}>(this.highestRanking)
    //   this.isLoading = false
    // })
  }

  goToCompanyPage(symbol: string): void {
    if(symbol != "FAKE"){
      this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
    }
  }
}
