<div class="outerContainer">
    <table mat-table [dataSource]="this.pillars" class="">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID# </th>
        <td [ngClass]="element.result? 'bg-green':'bg-red'" mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef> Result </th>
        <td mat-cell *matCellDef="let element">{{getResultIcon(element.result)}}</td>
      </ng-container>

      <ng-container matColumnDef="short_description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element"> {{element.short_description}} </td>
      </ng-container>
    
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element"> {{element.value}} </td>
      </ng-container>

      <ng-container matColumnDef="info">
        <th mat-header-cell *matHeaderCellDef> Info </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.data_info" mat-icon-button color="primary" (click)="openDataInfo(element)">
            <mat-icon>info</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Footer columns -->
      <ng-container matColumnDef="vip_score_number">
        <td mat-footer-cell *matFooterCellDef=""><b>Result</b></td>
      </ng-container>
      <ng-container matColumnDef="vip_score">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          VIP Score: <b>{{vipScore |number:'1.0-0'}}</b> /100
        </td>
      </ng-container>
      
      <!-- Table structure -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.result? 'bg-green-light':'bg-red-light'"></tr>
      <tr mat-footer-row *matFooterRowDef="['vip_score_number', 'vip_score']"></tr>
    </table>
</div>

