import { Component } from '@angular/core';
import { FreeTrialService } from '../../core/free-trial.service';
import { LoginService } from '../../core/login.service';
import { StripeService } from '../../core/stripe.service';

@Component({
  selector: 'app-counter-banner',
  templateUrl: './counter-banner.component.html',
  styleUrls: ['./counter-banner.component.scss']
})
export class CounterBannerComponent {
  remainingAnalysis: number;
  isBannerVisible: boolean = false;


  constructor(
    private freeTrialService: FreeTrialService,
    private loginService: LoginService,
    private stripe: StripeService) {
    this.remainingAnalysis = this.freeTrialService.getNumberOfAnalyses();
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      if(isLoggedIn){
        this.loginService.getIdToken().then(idToken => {
          this.stripe.hasValidSubscription(idToken).subscribe(hasValidSubscription => {
            this.isBannerVisible = !hasValidSubscription
          })
        })
      } else {
        this.isBannerVisible = true
      }
    })

    this.freeTrialService.updatedAnalysis.subscribe(value => {
      this.updateRemainingAnalyses()
    })
    
  }

  ngOnInit(): void {
    this.updateRemainingAnalyses();
  }

  updateRemainingAnalyses(): void {
    this.remainingAnalysis = this.freeTrialService.getNumberOfAnalyses();
    // this.isBannerVisible = true
  }

  closeBanner(): void {
    this.isBannerVisible = false;
  }

}
