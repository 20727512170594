<button mat-icon-button (click)="sidenav.toggle()" style="position:fixed; z-index: 10;">
    <mat-icon>menu</mat-icon>
</button>
<mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="sidebarMode" [opened]="sidebarOpened" class="sidenav"
                   [fixedInViewport]="true" [fixedTopGap]="65"
                   [fixedBottomGap]="" >
        <h2 class="mt-5 mx-2"></h2>
        <a mat-button href="/discover" class="sidebar_button">
            <mat-icon>stars</mat-icon>
            Best ranked companies
        </a>
        <hr>
        <a mat-button class="sidebar_button" href="/news">
            <mat-icon>article</mat-icon>
            <span>Business news</span>
        </a>
        <hr>
        <a mat-button class="sidebar_button" href="/compare">
            <mat-icon>compare_arrows</mat-icon>
            <span>Comparison Tool</span>
        </a>
        <hr>
        
        <a mat-button class="sidebar_button" [matMenuTriggerFor]="portfolio_menu">
            <mat-icon>bar_chart</mat-icon>
            <span>Portfolio analysis</span>
        </a>
        <mat-menu #portfolio_menu="matMenu">
            <div *ngFor="let link of links">
                <button mat-menu-item (click)="goTo(link.page)">
                    <mat-icon>bar_chart</mat-icon>
                    {{link.name}}
                </button>
            </div>
        </mat-menu>
        <hr>
        <a mat-button class="sidebar_button" href="/learn">
            <mat-icon>book</mat-icon>
            <span>Learn</span>
        </a>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="header-sections">
            <section>
                <div class="container">
                    <div class="row py-0 my-0 justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <div class="mt-4">
                                <div class="nt-title my-3">
                                    Dashboard
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="getWelcomeMessage()" class="row py-0 my-0 justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <div class="mt-1">
                                <div class="nt-subtitle my-0">
                                    {{getWelcomeMessage()}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section class="my-4">
                <div class="container">
                    <div class="row py-0 my-0 justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <app-search-bar [comparison_enable]="true"></app-search-bar>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <mat-tab-group>
            <mat-tab label="Your favorites">
                <div class="container mt-2">
                    <!-- <div class="row" *ngIf="!isLoading && !isLoadingFavorite">
                        <div class="col-12">
                            <div class="nt-subsubtitle">
                                Your Best Value Investing Picks:
                            </div>
                            <div *ngFor="let favorite_data of top_3_scores_favorite">
                                <div class="nt-content">
                                    <mat-icon style="color: green;">arrow_forward_ios</mat-icon>
                                    <a (click)="goToCompanyPage(favorite_data.symbol)">{{favorite_data.symbol}}</a>: {{favorite_data.score | number:'1.0-0'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="nt-subsubtitle">
                                Your worst Value Investing Picks:
                            </div>
                            <div *ngFor="let favorite_data of worst_3_scores_favorite">
                                <div class="nt-content">
                                    <mat-icon style="color: red;">arrow_forward_ios</mat-icon>{{favorite_data.symbol}}: {{favorite_data.score | number:'1.0-0'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div *ngIf="dataSource" class="nt-subsubtitle">
                                You currently have {{dataSource.data.length}} companies in your favorite list with an average score of <span style="color:#F1C40F">{{average_score_favorite | number:'1.0-0'}}/100</span>.
                            </div>
                        </div>
                    </div> -->
                    <div class="row" *ngIf="isLoading || isLoadingFavorite">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        <div  class="nt-subtitle">
                            Loading your data...
                        </div>
                    </div>
                    <div class="row py-0 my-0 justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="nt-subtitle">
                                Favorite list
                            </div>
                            <div>
                                <mat-progress-bar *ngIf="this.isLoadingFavorite" mode="indeterminate"></mat-progress-bar>
                                <table mat-table matSort [dataSource]="dataSource">
                                    <ng-container matColumnDef="favicon">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-icon class="favorite-icon">stars</mat-icon>
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="symbol">
                                      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol"> Symbol</th>
                                      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="company_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Name</th>
                                        <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="stock_price">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by price"> Last price</th>
                                        <td mat-cell *matCellDef="let element">{{element.stock_price | number}}</td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="stock_price_trend">
                                        <th mat-header-cell *matHeaderCellDef> Weekly trend</th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-icon *ngIf="element.short_term_trend > 0.4" style="color: #2ECC71;">trending_up</mat-icon>
                                            <mat-icon *ngIf="element.short_term_trend > -0.4 && element.short_term_trend < 0.4" style="color: #F1C40F;">trending_flat</mat-icon>
                                            <mat-icon *ngIf="element.short_term_trend <  -0.4" style="color: #E74C3C;">trending_down</mat-icon>
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="vip_score">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by score"> VIP Score</th>
                                        <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color':getColorFromScore(element.score)}"> {{element.score | number:'1.0-0'}}/100</td>
                                    </ng-container>
                            
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef> Actions</th>
                                        <td mat-cell *matCellDef="let element">
                                            <button mat-icon-button matTooltip="Go to company page" (click)="goToCompanyPage(element.symbol)"><mat-icon>keyboard_arrow_right</mat-icon></button>
                                            <button mat-icon-button [matMenuTriggerFor]="companyMore">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #companyMore="matMenu">
                                                <button mat-menu-item (click)="goToComparison(element.symbol)">
                                                    <mat-icon>compare_arrows</mat-icon>
                                                    Compare with...
                                                </button>
                                                <button mat-menu-item (click)="goToCompanyPage(element.symbol)">
                                                    <mat-icon>arrow_right</mat-icon>
                                                    Value Investing analysis
                                                </button>
                                                <button mat-menu-item (click)="goToMomentumInvesting(element.symbol)">
                                                    <mat-icon>arrow_right</mat-icon>
                                                    Momentum analysis
                                                </button>
                                                <button mat-menu-item (click)="openPriceTargetModalWindow(element.symbol)">
                                                    <mat-icon>alarm</mat-icon>
                                                    Set price target
                                                </button>
                                                <button mat-menu-item (click)="removeFavorite(element.symbol)">
                                                    <mat-icon>delete</mat-icon>
                                                    Remove favorite
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </ng-container>
                                  
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row (click)="onRowSelected(row)"
                                        [class.row-is-clicked]="selectedRow && row == selectedRow"
                                        *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons
                                aria-label="Select page of periodic elements"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Price targets">
                <div class="container mt-2">
                    <div class="row py-0 my-0 justify-content-center">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                            <div class="nt-subtitle">
                                Price targets
                            </div>
                            <app-price-target-list></app-price-target-list>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="News">
                <div class="container mt-2">
                    <div class="row py-0 my-0 justify-content-center ">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="nt-subtitle">
                                Business news
                            </div>
                            <app-main-news [short]="false" [n_data]="10"></app-main-news>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        
        <section>
            <app-explore-more></app-explore-more>
        </section>
        
        <section>
            <app-footer></app-footer>
        </section>        
    </mat-sidenav-content>
</mat-sidenav-container>

