<div class="container mt-4">
    <div class="row">
        <div class="col-12">
            <div class="nt-primary my-4" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
                NeoTradr
            </div>
            <p>Welcome to NeoTradr, your go-to destination for simplified financial analysis 
            through the lens of value investing. NeoTradr was born out of a personal need 
            for faster and more effective company analysis. As a creator, I embarked on 
            a journey of extensive readings, training, and exploration to develop the 
            pillars that form the foundation of NeoTradr.</p>

            <div class="nt-primary my-4" style="font-family: Inter; font-size: 28px; font-weight: 800; line-height: 1.2;">
                Our Mission and Values
            </div>
            <p>At NeoTradr, our mission is simple: to provide straightforward and insightful 
            analyses of companies, empowering you to make informed investment decisions. 
            We adhere to the principles of value investing, believing that a focus on the 
            intrinsic value of a company is key to long-term success. Because value is not all 
            we also investigate technical analysis timing to let you invest in the right company 
            at the right time.</p>

            <h2>Our Story</h2>
            <p>NeoTradr's story begins with the desire to streamline the process of analyzing 
            companies. Built as a simple tool to analyse locally company statements, NeoTradr has 
            evolve into a complete online suit of tools for financial analysis.
            Fueled by a passion for value investing, we set out to create a tool that 
            not only simplifies the analysis but also provides deep value insights of analyzed
            companies.</p>

            <!-- <h2>Meet the Team</h2>
            <p>Our team at NeoTradr is a group of dedicated individuals with a shared passion 
            for finance and technology. Each member brings unique expertise to the table, 
            contributing to the success of our mission. Get to know the faces behind NeoTradr, 
            and understand the collective drive that propels us forward.</p>
            <div class="row mx-auto text-center">
                <div class="col-12">
                    <img class="face-circle" src="https://www.romain-ledonge.com/img/photo-profil.png">
                    <div>Romain - Founder/dev/finance expert/investor</div>
                </div>
            </div> -->

            <h2>What Sets Us Apart</h2>
            <p>NeoTradr stands out in the financial analysis landscape due to our commitment 
            to simplicity and adherence to the principles of value investing. Our unique 
            approach, honed through extensive research and learning, sets us apart from 
            the crowd. Discover how NeoTradr can be your trusted companion in the world of 
            financial analysis.</p>

            <h2>User Benefits</h2>
            <p>Experience the benefits of NeoTradr firsthand. Our platform is designed to make 
            financial analysis accessible to everyone. Hear the success stories of users who 
            have utilized NeoTradr to enhance their investment decisions. Your journey to 
            empowered investing starts here.</p>

            <h2>Future Vision</h2>
            <p>As we look ahead, our vision for NeoTradr is one of continuous improvement and 
            expansion. We aim to introduce new features and tools that further simplify the 
            analysis process and go deeper into value analysis, ensuring that NeoTradr remains 
            at the forefront of value investing insights.</p>

            <h2>Transparency and Privacy</h2>
            <p>
                NeoTradr is built on a foundation of transparency and respect for user privacy. 
                We take your data security seriously and strive to provide a clear understanding 
                of our operations and practices. Your trust is our priority.
                Visit our <i><a href="/legal">Privacy policy page</a></i> to know more about it.
            </p>

            <h2>Get in Touch</h2>
            <p>Have questions or want to learn more about NeoTradr? <i><a href="/contact-us">We encourage you to reach out</a></i>.
            Connect with us through our contact information and follow us on social media to 
            stay updated on the latest from NeoTradr.</p>

            <p>Thank you for choosing NeoTradr as your partner in value investing analysis. 
                Let's embark on this financial journey together!</p>
        </div>
    </div>
</div>
<app-footer></app-footer>
