<div class="container">
    <div class="row">
        <div class="col-12">
            <div *ngIf="this.success === undefined">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="this.success === true" class="text-center">
                <mat-icon class="sub-success">check_circle_outline</mat-icon>
                <h1>Subscription succeed</h1>
            </div>
            <div *ngIf="this.success === false" class="text-center">
                <mat-icon class="sub-failure">error_outline</mat-icon>
                <h1>Subscription failed</h1>
            </div>
        </div>
        <div class="col-12 text-center">
            <a mat-raised-button color="primary" href="/user">Continue</a>
        </div>
    </div>
</div>
