import { Component } from '@angular/core';

@Component({
  selector: 'app-premium-feature-icon',
  templateUrl: './premium-feature-icon.component.html',
  styleUrls: ['./premium-feature-icon.component.scss']
})
export class PremiumFeatureIconComponent {

}
