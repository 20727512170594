import { AllSymbolData } from "./pillars.service"
import { DbData } from "./common_types"

export const getFidFromAllData = (all_data: AllSymbolData, fid: string): Array<DbData>|null => {
    const filtered = all_data.result.filter(x => x.financial_id == fid)
    if(filtered.length > 0){
        return filtered[0].data
    } else {
        return null
    }
}

export const getLatestFidFromAllData = (all_data: AllSymbolData, fid: string): DbData|null => {
    const fidData = getFidFromAllData(all_data, fid)
    if(fidData == null){
        return null
    }
    fidData.sort((a, b) => Number(b.data_date) - Number(a.data_date))
    if(fidData.length > 0){
        return fidData[0]
    } else {
        return null
    }
}

export const getAvgValueFromDbDataArray = (data: Array<DbData>): number => {
    return data.map(x => x.value).reduce((prev, cur) => prev + cur)/data.length
}

export const getResultIcon = (res: boolean|undefined): string => {
    return res == true ? "✅" : res == false ? "🛑" : "🙃"
}

export const groupBy = (array: Array<any>, key: string): Object => {
    /*
    Returns:
    {
        "key_value": [
            {
                ...OriginalObject
            }
        ]
    }
    */
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
};

export const arrayRange = (start: number, stop: number, step: number): Array<number> => {
    /**
     * Return sequence 'from' 'to' incrementing 'step'
     */
    return Array.from({ length: (stop - start) / step }, (value, index) => start + index * step);
}

export const leastSquares1d = (values_x: Array<number>, values_y: Array<number>): [number, number] => {
    /**
     * Returns: m and b the parameters of the equations: y = m.x + b
     */
    var x_sum = 0;
    var y_sum = 0;
    var xy_sum = 0;
    var xx_sum = 0;
    var count = 0;

    /*
     * The above is just for quick access, makes the program faster
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
        throw new Error('The parameters values_x and values_y need to have same size!');
    }

    /*
     * Above and below cover edge cases
     */
    if (values_length === 0) {
        return [0, 0]
    }

    /*
     * Calculate the sum for each of the parts necessary.
     */
    for (let i = 0; i< values_length; i++) {
        x = values_x[i];
        y = values_y[i];
        x_sum+= x;
        y_sum+= y;
        xx_sum += x*x;
        xy_sum += x*y;
        count++;
    }

    /*
     * Calculate m and b for the line equation:
     * y = x * m + b
     */
    var m = (count*xy_sum - x_sum*y_sum) / (count*xx_sum - x_sum*x_sum);
    var b = (y_sum/count) - (m*x_sum)/count;

    /*
     * We then return the x and y data points according to our fit
     */
    // var result_values_x = [];
    // var result_values_y = [];

    // for (let i = 0; i < values_length; i++) {
    //     x = values_x[i];
    //     y = x * m + b;
    //     result_values_x.push(x);
    //     result_values_y.push(y);
    // }
    // return [result_values_x, result_values_y];
    return [m, b]
}

export const getGreenColoGradient = (percent: number): string => {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#${colorChoosen}0FF${colorChoosen}0`
}

export const getRedColoGradient = (percent: number): string => {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#FF${colorChoosen}0${colorChoosen}0`
}

export const  getColorFromScore = (percent: number): string => {
    let value = ""
    if(percent > 50){
        value = getGreenColoGradient((percent-50)*2)
    } else {
        value = getRedColoGradient((50 - percent)*2)
    }
    return value;
}

export function convert_int_to_ext_symbol(i_symbol: string): string {
    return i_symbol.replace(/\./g, '__dot__')
}

export function convert_ext_to_int_symbol(i_symbol: string): string {
    return i_symbol.replace('__dot__', '.')
}

let checkOrder = (values: Array<any>, order: "INC"|"DEC"): boolean => {
    if(order == "INC"){
        for(let i = 0; i < values.length - 1; i++){
            if(values[i] > values[i+1]){
                return false;
            }
        }
        return true;
    } else if(order == "DEC"){
        for(let i = 0; i < values.length - 1; i++){
            if(values[i] < values[i+1]){
                return false;
            }
        }
        return true;
    } else {
        throw("Unknow order: "+ order);
    }
}

