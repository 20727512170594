import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from './articles.service';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage-manager';

export interface ArticleData {
  _id: string,
  text_used: string,
  article: Article,
}
export interface GroupedNewsGroup {
  _id: string,
  cluster_id: string,
  num_documents: number,
  articles_data: Array<ArticleData>
}
export interface GroupedNewsResult {
  _id: string,
  tag: string,
  timestamp: string,
  groups: Array<GroupedNewsGroup>
}

@Injectable({
  providedIn: 'root'
})
export class IntelligenceService {
  baseUrl = environment.sannewsEndpoint
  constructor(private http: HttpClient) { }

  public getGroupedNews(): Observable<GroupedNewsResult> {
    const cacheData = getLocalStorageItem(`grouped-news-data`)
    if(cacheData){
      return of(cacheData)
    } else {
      return this.http.get<GroupedNewsResult>(`${this.baseUrl}/api/intelligence/grouped_news`).pipe(
        tap(x => {
          if((<any>x)["status"] != "ok"){
            throw new Error("Server did not return ok")
          }
        }),
        map(x => (<any>x)['result']),
        tap(x => setLocalStorageItem(`grouped-news-data`, x, 60*60*2)),
        catchError(this.handleError<GroupedNewsResult>('getGroupedNews', {_id:'', groups:[], tag: '', timestamp: ''}))
      )
    }
  }

  private handleError<T>(operation= 'unknown', result?: T){
    return (error: any): Observable<T> => {
      console.log(`${operation} failed: ${error.message}, status:${error.status}`);
      return of(result as T);
    }
  }
}
