import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage-manager';

export interface Article {
  title: String,
  content: String,
  source: String,
  url: String,
  published_at: Date,
  img: String,
  datetime: Date,
  notified: Boolean,
  langage?: string,
  sentiment: {polarity: number, subjectivity: number},
  companies?: Array<{symbol: string, company_name: string}>
}

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  backend_server = environment.sannewsEndpoint

  constructor(private httpClient: HttpClient) { }

  getArticlesFrom(from: Number): Observable<Article[]>{
    const cacheData = getLocalStorageItem(`news-data-${from}`)
    if(cacheData){
      return of(cacheData)
    } else {
      return this.httpClient.get(`${this.backend_server}/api/news?from=${from}`).pipe(
        map(result => (<any>result)["articles"]),
        tap(x => setLocalStorageItem(`news-data-${from}`, x, 60*60*2)),
        catchError(this.handleError<Article[]>('getArticlesFrom', []))
      );
    }
  }

  getSearchArticlesFrom(searchQuery: string, from: Number): Observable<Article[]>{
    console.log('Warning: this news article search call is not cached')
    return this.httpClient.get(`${this.backend_server}/api/news/search?query=${searchQuery}&from=${from}`).pipe(
      map(result => (<any>result)["articles"]),
      catchError(this.handleError<Article[]>('getSearchArticlesFrom', []))
    )
  }

  private handleError<T>(operation= 'unknown', result?: T){
    return (error: any): Observable<T> => {
      console.log(`${operation} failed: ${error.message}, status:${error.status}`);
      return of(result as T);
    }
  }
}
