import { getTechnicalPillarsFunctions, TechnicalDataForPillars } from "../components/technical-pillars-list/technical_pillars";
import { arrayRange, leastSquares1d } from "../core/helpers";
import { PillarData } from "../core/pillars.service";

export interface MomentumDataForPillars {
    /**
     * Contains all the data needed to compute any momentum pillars
     */
    priceTechnicalData: TechnicalDataForPillars, 
    volumeTechnicalData: TechnicalDataForPillars,
    priceToVolumeData: Array<number>,
    companyIndustry: string,
    companySector: string,
}

export const computeMomentumPillar1 = (momentumData: MomentumDataForPillars): PillarData => {
    let warnings: string[] = []
    const momentum_industries = [
      "Technology", "Defense", "Renewable Energy", "Fintech", "Healthcare", "Consumer Services"
    ]
    // Search if company_industry contains parts of momentum_industries
    let is_momentum = false
    momentum_industries.forEach(industry => {
      if(momentumData.companyIndustry.toLowerCase().includes(industry.toLowerCase())){
        is_momentum = true
      }
    })

    momentum_industries.forEach(industry => {
      if(momentumData.companySector.toLowerCase().includes(industry.toLowerCase())){
        is_momentum = true
      }
    })
  
    return {
      id: "map1",
      short_description: "Momentum industry",
      warnings: warnings,
      weight: is_momentum ? 0.3 : -0.3,
      weight_min_value: -0.3,
      weight_max_value: 0.3,
      data_info: undefined,
      result: is_momentum,
      value: ""
    }
}

export const computeMomentumPillar2 = (momentumData: MomentumDataForPillars): PillarData => {
    /**
     * Compute momentum pillars 2
     * Return true if the volume value is greater than the long term moving average
     */
    let warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
    const lastSmaLT = momentumData.volumeTechnicalData.smaLongTermData[momentumData.volumeTechnicalData.smaLongTermData.length-1]
    const lastVolume = momentumData.volumeTechnicalData.stockPrice[momentumData.volumeTechnicalData.stockPrice.length-1]
    if(lastVolume > lastSmaLT){
      score = 0.2
      is_valid = true
    } else {
      score = -0.2
      is_valid = false
    }
    return {
      id: "map2",
      short_description: "Volume trend analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -0.2,
      weight_max_value: 0.2,
      data_info: undefined,
      result: is_valid,
      value: value
    }
}

// export const computeMomentumPillar3 = (momentumData: MomentumDataForPillars): PillarData => {
//     /**
//      * Compute momentum pillars 3
//      * Return true if the short term average volume is trending up, false otherwise
//      */
//     let warnings: string[] = []
//     let score = 0
//     let is_valid = false
//     let value = ''
//     const recentSelection = momentumData.volumeTechnicalData.smaShortTermData.slice(momentumData.volumeTechnicalData.smaShortTermData.length-10, momentumData.volumeTechnicalData.smaShortTermData.length-1)
//     const recentSelectionAverage = recentSelection.reduce((a, b) => a + b, 0) / recentSelection.length
//     const [st_growth, b] = leastSquares1d(arrayRange(0, recentSelection.length, 1), recentSelection)
//     const percentageGrowth = st_growth/recentSelectionAverage

//     if(percentageGrowth > 0.01){
//       score = 0.2
//       is_valid = true
//     } else if(percentageGrowth < -0.01) {
//       score = -0.2
//       is_valid = false
//     } else {
//       score = 0.0
//       is_valid = false
//     }
//     return {
//       id: "map3",
//       short_description: "Short Volume trend analysis",
//       warnings: warnings,
//       weight: score,
//       weight_min_value: -0.5,
//       weight_max_value: 0.5,
//       data_info: undefined,
//       result: is_valid,
//       value: value
//     }
// }

export const computeMomentumPillar4 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 4
   * Assign score if Close price is higher than the SMA Long term
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''

  const lastSmaLT = momentumData.priceTechnicalData.smaLongTermData[momentumData.priceTechnicalData.smaLongTermData.length-1]
  const lastStockPrice = momentumData.priceTechnicalData.stockPrice[momentumData.priceTechnicalData.stockPrice.length-1]
  if(lastStockPrice > lastSmaLT){
    score = 1.0
    is_valid = true
  } else {
    score = -1
    is_valid = false
  }
  
  return {
    id: "map4",
    short_description: "Long term trend vs price analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar5 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 5
   * Assign score if Close is higher than the SMA Short term
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  const lastSmaST = momentumData.priceTechnicalData.smaShortTermData[momentumData.priceTechnicalData.smaShortTermData.length-1]
  const lastStockPrice = momentumData.priceTechnicalData.stockPrice[momentumData.priceTechnicalData.stockPrice.length-1]
  if(lastStockPrice > lastSmaST){
    score = 1.0
    is_valid = true
  } else {
    score = -1.0
    is_valid = false
  }
  
  return {
    id: "map5",
    short_description: "Short term trend vs price analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar6 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 6
   * Assign score if SMA Long term is trending up (on the last data)
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  const lastSmaLT = momentumData.priceTechnicalData.smaLongTermData[momentumData.priceTechnicalData.smaLongTermData.length-1]
  const lastSmaLT2 = momentumData.priceTechnicalData.smaLongTermData[momentumData.priceTechnicalData.smaLongTermData.length-2]
  if(lastSmaLT > lastSmaLT2){
    score = 1.0
    is_valid = true
  } else {
    score = -1.0
    is_valid = false
  }
  
  return {
    id: "map6",
    short_description: "Long term trend analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar7 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 7
   * Assign score if SMA Long term is trending up since at least 20 days (1 month)
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  const recentSelection = momentumData.priceTechnicalData.smaLongTermData.slice(momentumData.priceTechnicalData.smaLongTermData.length-20, momentumData.priceTechnicalData.smaLongTermData.length-1)
  const [lt_growth, b] = leastSquares1d(arrayRange(0, recentSelection.length, 1), recentSelection)
  if(lt_growth > 0){
    score = 1.0
    is_valid = true
  } else {
    score = -1.0
    is_valid = false
  }
  
  return {
    id: "map7",
    short_description: "Long term trend direction analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar8 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 8
   * Assign score if SMA Short term is trending up (last data)
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  const lastSmaST = momentumData.priceTechnicalData.smaShortTermData[momentumData.priceTechnicalData.smaShortTermData.length-1]
  const lastSmaST2 = momentumData.priceTechnicalData.smaShortTermData[momentumData.priceTechnicalData.smaShortTermData.length-2]
  if(lastSmaST > lastSmaST2){
    score = 1.0
    is_valid = true
  } else {
    score = -1.0
    is_valid = false
  }
  
  return {
    id: "map8",
    short_description: "Short term trend analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar9 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 9
   * Assign score if RSI is above 60, below 40, or in between (trend direction)
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  const lastRsi = momentumData.priceTechnicalData.rsiData[momentumData.priceTechnicalData.rsiData.length-1]
  if(lastRsi > 60){
    score = 1.0
    is_valid = true
  } else if(lastRsi < 40){
    score = -1.0
    is_valid = false
  } else if(lastRsi > 50 && lastRsi < 60){
    score = 0.5
    is_valid = true
  } else if (lastRsi > 40 && lastRsi < 50){
    score = -0.5
    is_valid = false
  } else {
    score = 0.0
    is_valid = false
  }
  
  return {
    id: "map9",
    short_description: "RSI analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar10 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 10
   * Assign score if SuperTrend is above the price or below
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  // To be done 
  
  return {
    id: "map10",
    short_description: "SuperTrend analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeMomentumPillar11 = (momentumData: MomentumDataForPillars): PillarData => {
  /**
   * Compute momentum pillars 11
   * Assign score if Relative strength is new High or Low: +1, -1
   */
  let warnings: string[] = []
  let score = 0
  let is_valid = false
  let value = ''
  
  // To be done 
  
  return {
    id: "map11",
    short_description: "Price trend analysis",
    warnings: warnings,
    weight: score,
    weight_min_value: -1,
    weight_max_value: 1,
    data_info: undefined,
    result: is_valid,
    value: value
  }
}

export const computeAllMomentumPillars = (momentumData: MomentumDataForPillars): Array<PillarData> => {
    const res: Array<PillarData> = []
    // for (const pillar of getTechnicalPillarsFunctions()) {
    //     res.push(pillar(momentumData.priceTechnicalData))
    // }
    res.push(computeMomentumPillar1(momentumData))
    res.push(computeMomentumPillar2(momentumData))
    // res.push(computeMomentumPillar3(momentumData))
    res.push(computeMomentumPillar4(momentumData))
    res.push(computeMomentumPillar5(momentumData))
    res.push(computeMomentumPillar6(momentumData))
    res.push(computeMomentumPillar7(momentumData))
    res.push(computeMomentumPillar8(momentumData))
    res.push(computeMomentumPillar9(momentumData))
    return res
}


/**
 * Helpers functions starting here
 */


export const computePriceToVolumeRatio = (priceData: Array<number>, volumeData: Array<number>): Array<number> => {
  const priceToVolumeRatio: Array<number> = [];
  if (priceData.length !== volumeData.length) {
    console.error('Price and volume data must have the same length.');
  }

  for (let i = 0; i < priceData.length; i++) {
    const price = priceData[i];
    const volume = volumeData[i];

    // Check to avoid division by zero
    if (volume === 0) {
        priceToVolumeRatio.push(0); // Push 0 or another appropriate value if volume is zero
    } else {
        priceToVolumeRatio.push(price / volume); // Calculate price-to-volume ratio
    }
  }
  return priceToVolumeRatio;
}
