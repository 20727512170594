import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage-manager';
import { catchError, map, Observable, of, tap } from 'rxjs';

export interface BlogArticleData {
  url: string // Relative URL (articles/2024/08/company_analysis_TTE.PA_12_08_2024.html)
  created_on: Date // "2024-08-12 15:15:04"
  title: string // "A Comprehensive Analysis of TotalEnergies SE (TTE)
  symbol: string // "TTE.PA
}

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blog_origin_url = 'https://blog.neotradr.com'
  constructor(private http: HttpClient) { }

  getBlogArticleList(): Observable<Array<BlogArticleData>> {
    const cacheData = getLocalStorageItem(`blog_article_list`)
    if(cacheData){
      return of(cacheData)
    } else {
        return this.http.get<{version: string, articles: Array<BlogArticleData>}>(`${this.blog_origin_url}/index.json`)
        .pipe(
          map(x => {
            return x.articles 
          }),
          tap(x => setLocalStorageItem(`blog_article_list`, x, 60*60*24)),
          catchError(err => {
            console.error(err)
            return of([])
          })
        )
    }
  }

  getBlogArticleDataForSymbol(symbol: string): Observable<Array<BlogArticleData>> {
    return this.getBlogArticleList()
    .pipe(
      map(x => x.filter(y => y.symbol === symbol))
    )
  }
}

