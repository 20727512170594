import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-failure-page',
  templateUrl: './subscription-failure-page.component.html',
  styleUrls: ['./subscription-failure-page.component.scss']
})
export class SubscriptionFailurePageComponent {

}
