import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SupportedSymbolPageComponent } from './supported-symbol-page/supported-symbol-page.component';
import { DiscoverPageComponent } from './discover-page/discover-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { UserPageComponent } from './user-page/user-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { SubscriptionPageComponent } from './subscription-page/subscription-page.component';
import { PortfolioPageComponent } from './portfolio-page/portfolio-page.component';
import { LegalPageComponent } from './pages/legal-page/legal-page.component';
import { SubscriptionSuccessPageComponent } from './subscription-page/subscription-success-page/subscription-success-page.component';
import { SubscriptionFailurePageComponent } from './subscription-page/subscription-failure-page/subscription-failure-page.component';
import { DisclaimerPageComponent } from './pages/disclaimer-page/disclaimer-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { BlogPageComponent } from './blog-page/blog-page.component';
import { ComparisonPageComponent } from './comparison-page/comparison-page.component';
import { UnsubscribePageComponent } from './pages/unsubscribe-page/unsubscribe-page.component';
import { CompanyPageSimpleComponent } from './company-page-simple/company-page-simple.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MomentumPageComponent } from './momentum-page/momentum-page.component';
import { authGuard } from './core/auth-guard';
import { LearnPageComponent } from './pages/learn-page/learn-page.component';

const routes: Routes = [
  {component: LandingPageComponent, path: ''},
  {component: LoginPageComponent, path: 'login'},
  
  {component: CompanyPageSimpleComponent, path: 'company'},
  {component: CompanyPageSimpleComponent, path: 'company/:symbol'},
  {component: CompanyPageSimpleComponent, path: 'value/:symbol'},

  {component: MomentumPageComponent, path: 'momentum'},
  {component: MomentumPageComponent, path: 'momentum/:symbol'},

  {component: DiscoverPageComponent, path: 'discover'},
  {component: NewsPageComponent, path: 'news'},
  {component: UserPageComponent, path: 'user', canActivate: [authGuard]},
  {component: DashboardPageComponent, path: 'dashboard', canActivate: [authGuard]},

  {component: SystemStatusComponent, path: 'system-status'},

  {component: SubscriptionPageComponent, path: 'subscribe'},
  {component: SubscriptionSuccessPageComponent, path: 'subscription-success'},
  {component: SubscriptionFailurePageComponent, path: 'subscription-failure'},
  
  {component: PortfolioPageComponent, path: 'portfolio/:portfolio_id'},

  {component: LegalPageComponent, path: 'legal'},
  {component: DisclaimerPageComponent, path: 'disclaimer'},
  {component: ContactUsPageComponent, path: 'contact-us'},
  {component: AboutUsPageComponent, path: 'about-us'},

  {component: BlogPageComponent, path: 'blog'},
  {component: LearnPageComponent, path: 'learn'},
  
  {component: ComparisonPageComponent, path: 'compare'},
  {component: UnsubscribePageComponent, path: 'unsubscribe'},
  {component: PageNotFoundComponent, path: '**'}
];

// /!\ Update the 'update_sitemap.py' scripts with new routes + prerender static files

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
