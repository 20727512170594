
<div class="search-container mb-0">
    <mat-form-field appearance="fill">
        <mat-label>Search for questions</mat-label>
        <input matInput [(ngModel)]="searchQuery" (input)="filterQaList()" />
    </mat-form-field>
</div>

<div *ngFor="let qa of filteredQaList" class="mt-0">
  <mat-expansion-panel style="border-radius: 0;">
    <mat-expansion-panel-header>
      {{ qa.question }}
    </mat-expansion-panel-header>
    <p>{{ qa.answer }}</p>
  </mat-expansion-panel>
</div>
