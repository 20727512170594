<button mat-icon-button (click)="sidenav.toggle()" style="position:fixed; z-index: 10;">
    <mat-icon>menu</mat-icon>
</button>
<mat-sidenav-container>
    <mat-sidenav #sidenav [mode]="sidebarMode" [opened]="sidebarOpened" class="sidenav"
                   [fixedInViewport]="true" [fixedTopGap]="65"
                   [fixedBottomGap]="" >
        
        <div class="nt-subsubtitle mt-5 mx-2">Browse learning</div>
        <button mat-button (click)="scrollToSection('investing')" class="sidebar_button">
            What is investing?
        </button>
        <hr>
        <button mat-button (click)="scrollToSection('value_investing')" class="sidebar_button">
            What is Value Investing
        </button>
        <hr>
        <button mat-button (click)="scrollToSection('momentum_investing')" class="sidebar_button">
            What is Momentum Investing
        </button>
        <hr>
        <button mat-button (click)="scrollToSection('value_momentum_investing')" class="sidebar_button">
            Value Investing vs. Momentum Investing
        </button>
        <hr>
        <button mat-button (click)="scrollToSection('indicators')" class="sidebar_button">
            Financial Indicators
        </button>

        <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <button mat-icon-button disabled></button>
              {{node.name}}
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.name}}
            </mat-tree-node>
        </mat-tree> -->
    </mat-sidenav>
  
      <mat-sidenav-content>
        <div class="container mt-5 mb-2">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-sm-12">
                    <div class="nt-title">Learn</div>
                    <div class="nt-title-muted">
                        Learn about finance in a simple way and understand how NeoTradr analysis works.
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-sm-12">      
                    <section class="my-3" id="investing">
                        <div class="nt-subtitle">What is investing?</div>
                        <p>Investing is a way to grow your money over time. Instead of keeping all your savings in a bank account, where it may earn a little interest, you can invest it in things like stocks, which have the potential to give you much higher returns.</p>
        
                        <div class="nt-subsubtitle">Understanding Stocks</div>
                        <p>When you buy a stock, you are buying a small piece of a company. Imagine a big company like Apple or Coca-Cola. These companies are divided into millions of tiny pieces called "shares" or "stocks." When you buy a share, you own a small part of that company.</p>
        
                        <p>Why do companies sell shares? Companies sell stocks to raise money. They use this money to grow their business—whether it's developing new products, expanding into new markets, or hiring more employees.</p>
                        <img src="../../../assets/img/learn/diagram_what_is_share.webp" class="learn_img" alt="Stock Market">
                        <small class="text-muted">Image generated with generative AI</small>
                    </section>
                    <section class="my-3" id="investing_how_investing_work">
                        <div class="nt-subsubtitle">How Does Investing Work?</div>
                        <p>When you buy a stock, you hope that the company will grow and become more profitable. As the company becomes more valuable, the price of its stocks usually goes up. If the stock price goes up, the value of your investment increases. At some point, you can sell the stock at a higher price than you bought it and make a profit.</p>
            
                        <p>For example, let’s say you buy one share of a company for $10. If the company's stock price rises to $15, and you sell it, you’ve made a $5 profit. This is called "capital gain."</p>
            
                        <p>But stocks don’t always go up. Sometimes companies face challenges, and their stock price can fall. If you sell the stock for less than you paid, you lose money.</p>
                    </section>
                    <section class="my-3" id="investing_dividends">
                        <div class="nt-subsubtitle">Dividends: Another Way to Make Money</div>
                        <p>Some companies pay a portion of their profits to their shareholders, known as dividends. Dividends are usually paid every few months. Not all companies pay dividends, but if they do, it’s like earning a small bonus on top of your investment.</p>
                        <p>On NeoTradr you can find the dividend history in the <i>Graph</i> tab of the Value Investing analysis.</p>
                        <img class="learn_img" src="../../../assets/img/learn/tte_dividend.png">
                    </section>

                    <section class="my-3" id="investing_risk_rewards">
                        <div class="nt-subsubtitle">Risk and Reward</div>
                        <p>Investing in stocks involves risk. Stocks can go up, but they can also go down. Sometimes a company may even go out of business, and its stock becomes worthless. The key to successful investing is balancing this risk with potential rewards.</p>
                        <p>The higher the risk, the higher the potential reward. For example, investing in a new technology company could give you huge profits if the company grows quickly. But if the company fails, you might lose your investment.</p>
                    
                        <div class="nt-subsubtitle">Diversification: Not Putting All Your Eggs in One Basket</div>
                        <p>To reduce risk, many investors buy stocks from different companies and industries. This is called diversification. If one company doesn’t perform well, your other investments might still do well, reducing the overall risk of losing money.</p>
                    </section>
                    
                    <section class="my-3" id="investing_long_term">
                        <div class="nt-subsubtitle">Long-Term Strategy</div>
                        <p>Investing isn’t usually about getting rich quickly. It’s more about building wealth over time. Historically, the stock market has grown in value over the long term, even if there are short-term ups and downs. Many successful investors buy stocks and hold onto them for years, allowing their investments to grow steadily.</p>
                    </section>
                    
                    <section class="my-3" id="investing_why_invest">
                        <div class="nt-subsubtitle">Why Do People Invest?</div>
                        <p>People invest to reach financial goals. Some common reasons include:</p>
                        <ul>
                            <li>Saving for retirement</li>
                            <li>Buying a house</li>
                            <li>Paying for a child's education</li>
                            <li>Building wealth for the future</li>
                        </ul>
                        <p>Investing in stocks can help your money grow faster than if it were just sitting in a bank. However, it's important to understand that there's always a risk of losing money, which is why learning about the stock market and making informed decisions is crucial.</p>
            
                        <div class="nt-subsubtitle">How to Start Investing?</div>
                        <p>Starting to invest can be as simple as opening an account with a brokerage or an app that allows you to buy and sell stocks. You can start with small amounts and gradually build your portfolio as you learn more about investing.</p>
                        <p>We are mainly using Interactive Brokers thanks to low fees, powerful features and global market coverage at NeoTradr.</p>
                        <app-advertise [adName]="AdName.IBKR"></app-advertise>
                    </section>
                    
                    <section class="my-3" id="value_investing">
                        <hr>
                        <div class="nt-subtitle">What is Value Investing?</div>
                        <p>Value investing is a strategy used by investors to buy stocks at a price that is believed to be lower than their actual value. The goal is to find companies that are undervalued by the stock market and invest in them, with the hope that over time, the market will realize the true worth of these companies, and the stock price will increase.</p>
                        <p>The main idea behind value investing is that sometimes, a company's stock price doesn't reflect the true value of the company. This could be due to market overreactions, temporary problems, or simply a lack of attention from investors. A value investor looks for these opportunities to buy stocks at a discount.</p>
                    </section>
        
                    <section class="my-3" id="value_investing_how_it_work">
                        <div class="nt-subsubtitle">How Does Value Investing Work?</div>
                        <p>Value investors follow a few key steps:</p>
        
                        <p><u>Research the Company's Fundamentals:</u> Value investors focus on the company itself, not just its stock price. They look at the company’s earnings, assets, liabilities, and overall business model. They study financial reports to understand how the company makes money and how well it is managed. They want to know if the company is financially healthy and if it has the potential to grow in the future.</p>
                        <p><u>Identify Undervalued Stocks:</u> Value investors use various financial ratios to determine if a stock is undervalued. One common ratio is the price-to-earnings (P/E) ratio, which compares a company's stock price to its earnings. A low P/E ratio can indicate that the stock is undervalued compared to other companies in the same industry. Investors also consider the company’s price-to-book (P/B) ratio, which compares the stock price to the company’s assets, and the debt-to-equity ratio, to assess financial stability. NeoTradr uses a lot of other factors called pillars to evaluate value as well.</p>
                        <p><u>Buy and Hold for the Long Term:</u> Value investing is a long-term strategy. Value investors believe that it takes time for the market to realize the true value of a company. So, they are patient and hold onto their investments for years, sometimes even decades, waiting for the stock price to rise.</p>
                        <p><u>Look for a Margin of Safety: </u>One of the key principles of value investing is the margin of safety. This means buying stocks at a price lower than the estimated true value, to reduce the risk of loss. For example, if an investor believes a stock is worth $100 but can buy it for $70, that $30 difference provides a cushion if things don’t go as planned.</p>
                        <p>NeoTradr is using a list of pillars to automatically analyze in an efficient way companies, as shown below:</p>
                        <img src="../../../assets/img/learn/pillars_overview.png" class="learn_img">
                    </section>
                    <section class="my-3" id="value_investing_how_it_work">
                        <div class="nt-subsubtitle">Why Do Companies Become Undervalued?</div>
                        <p>There are many reasons why a company might be undervalued:</p>
            
                        <p>Market Overreaction: Sometimes the stock market reacts strongly to bad news, such as a company missing earnings expectations or a temporary setback in the business. The market may push the stock price lower than it should be, creating an opportunity for value investors.</p>
                        <p>Lack of Attention: Smaller companies or companies in less popular industries may not get much attention from large investors or the media. This lack of attention can cause their stocks to be undervalued, even if the companies themselves are doing well.</p>
                        <p>Temporary Problems: A company might face a temporary problem, like a product recall or a drop in sales, which causes its stock price to fall. If a value investor believes the problem is temporary and the company will recover, they may buy the stock at a discount.</p>
                    </section>
                    
                    <section class="my-3" id="value_investing_how_it_work">
                        <div class="nt-subsubtitle">Famous Value Investors</div>
                        <p>One of the most famous value investors is Warren Buffett, the CEO of Berkshire Hathaway. Buffett has made billions of dollars by following value investing principles. He looks for companies with strong fundamentals that are temporarily undervalued by the market, buys their stock, and holds on to them for years as they grow in value.</p>
                        <a mat-raised-button href="/portfolio/berkshire-hathaway-portfolio">Berkshire Hathaway Portfolio</a>
                        <p>Buffett was greatly influenced by another well-known value investor, Benjamin Graham, who is often called the "father of value investing." Graham’s book, The Intelligent Investor, is considered one of the most important texts on the subject.</p>
                    </section>
                    
                    <section class="my-3" id="value_investing_can_be_effective">
                        <div class="nt-subsubtitle">Why Value Investing Can Be Effective</div>
                        <p>Value investing can be effective because it is based on careful research and a long-term view. While other investors may try to make quick profits by predicting short-term market movements, value investors focus on the underlying strength of a company. This approach reduces the risk of making emotional decisions based on market fluctuations.</p>
                        <p>By buying stocks at a discount, value investors increase their chances of making a profit when the market corrects itself and recognizes the company’s true worth. However, value investing also requires patience, discipline, and the ability to withstand short-term volatility.</p>
                    </section>
                    
                    <section class="my-3" id="value_investing_risks">
                        <div class="nt-subsubtitle">Risks of Value Investing</div>
                        <p>While value investing can be a successful strategy, it is not without risks:</p>
            
                        <p>Misjudging the Company’s Value: Sometimes, a stock may appear undervalued, but the investor’s assessment may be wrong. The company may be facing deeper problems than expected, and its stock price may never recover.</p>
                        <p>Long Wait for Returns: Value investing requires patience. It can take a long time for a stock to reach its full value, and in the meantime, the investor may not see significant returns.</p>
                        <p>Market Changes: The stock market is unpredictable. Even if a company is strong and undervalued, changes in the economy, competition, or industry can affect its performance and stock price.</p>
                    </section>
                    
                    <p>Value investing is a strategy for investors who are willing to do their homework and think long term. By identifying stocks that are undervalued, buying them at a discount, and holding on to them until their true value is realized, value investors aim to make a profit. While it requires patience and careful research, value investing can be a powerful way to grow wealth over time, especially for those who are focused on the fundamentals of a business rather than short-term market trends.</p>
                    
                    <section class="my-3" id="momentum_investing">
                        <hr>
                        <div class="nt-subtitle">What is Momentum Investing?</div>
                        <p>Momentum investing is a strategy that focuses on buying stocks that have been rising in price and selling those that have been falling. The basic idea behind momentum investing is that stocks that have performed well recently are likely to continue performing well in the near future, and stocks that have performed poorly are likely to keep underperforming.</p>
                        <p>Momentum investors try to take advantage of this trend by riding the wave of a stock’s recent performance, whether it’s going up or down. This strategy is based on the observation that stock prices tend to follow trends for some time, either because of investor behavior or broader market conditions.</p>
                    </section>
                    
                    <section class="my-3" id="momentum_investing_how_it_works">
                        <div class="nt-subsubtitle">How Does Momentum Investing Work?</div>
                        <p>Momentum investors don’t look at a company’s fundamentals, like its earnings or financial health, the way value investors do. Instead, they focus on the stock’s price movements and trends. Here’s how it works:</p>
        
                        <p><u>Identify Stocks with Strong Trends:</u> Momentum investors look for stocks that have been <i>steadily rising</i> or falling over a certain period, such as the last 3, 6, or 12 months. These stocks show a clear trend in their price movements.</p>
                        <p><u>Buy High, Sell Higher:</u> Momentum investors believe in the saying, <i>“The trend is your friend.”</i> They buy stocks that have already been rising, expecting the trend to continue. The idea is that stocks that have gone up recently are likely to keep going up, so they buy now with the hope of selling them at an even higher price.</p>
                        <p><u>Sell Falling Stocks:</u> On the flip side, momentum investors might sell or <i>avoid stocks that have been consistently dropping</i> in price. They assume these stocks will continue to perform poorly, so they avoid investing in them or might even sell them short to profit from the decline.</p>
                        <p><u>Timing the Market:</u> Momentum investing <i>is all about timing.</i> Investors don’t plan to hold onto a stock for the long term. Instead, they stay invested while the stock’s price keeps moving in their favor, and they sell once the momentum starts to slow down or reverse.</p>
                    </section>

                    <section class="my-3" id="momentum_investing_psychology">
                        <div class="nt-subsubtitle">The Psychology Behind Momentum Investing</div>
                        <p>Momentum investing is largely driven by market psychology. When a stock price starts rising, more and more investors take notice. This causes even more buying, which pushes the price higher. Similarly, when a stock starts falling, many investors rush to sell, pushing the price down further.</p>
                        <p>This kind of behavior is often referred to as herd mentality, where investors follow the actions of others, assuming that if everyone is buying or selling, they should do the same. Momentum investors aim to capitalize on these emotional responses by getting in on the trend before it becomes too crowded.</p>

                    </section>
                    
                    <section class="my-3" id="momentum_investing_tools_and_indicators">
                        <div class="nt-subsubtitle">Tools and Indicators for Momentum Investing</div>
                        <p>Momentum investors use various tools and indicators to spot trends and assess whether a stock’s price is likely to keep moving in the same direction:</p>
                        <p><u>Moving Averages:</u> One of the most common indicators is the moving average, which smooths out price fluctuations and helps identify the direction of a stock’s trend. A stock trading above its moving average may signal that its price is likely to continue rising.</p>
                        <p><u>Relative Strength Index (RSI):</u> The RSI measures the strength and speed of a stock’s price movements. It helps investors determine whether a stock is overbought (prices have risen too much and might fall soon) or oversold (prices have dropped too much and might rise soon).</p>
                        <p><u>Momentum Indicators:</u> These are mathematical formulas that calculate the rate of change in a stock’s price. If a stock is rising quickly, a momentum indicator will show a high value, signaling that the stock is gaining strength.</p>
                        <p><u>Trading Volume:</u> Momentum investors also pay attention to trading volume. If a stock is rising and the volume of shares being traded is increasing, it can be a strong sign that the trend will continue. If the price is rising but the volume is low, the trend might not last.</p>
                        <p>NeoTradr is using the same technics to analyze momentum of companies:</p>
                        <img src="../../../assets/img/learn/technical_analysis_pillars.png" class="learn_img">
                    </section>
                    
                    <section class="my-3" id="momentum_investing_can_work">
                        <div class="nt-subsubtitle">Why Momentum Investing Can Work</div>
                        <p>Momentum investing can be effective because it takes advantage of market trends and investor behavior. Stocks that are going up tend to keep going up because they attract more attention, leading to more buying. Similarly, stocks that are going down tend to keep falling as more investors sell their shares to cut losses.</p>
                        <p>Momentum investing works best in trending markets, where prices are moving strongly in one direction, either up or down. It can be especially useful during periods of market volatility, where big price swings create opportunities for momentum traders to profit.</p>
                    </section>
                    
                    <section class="my-3" id="momentum_investing_risks">
                        <div class="nt-subsubtitle">Risks of Momentum Investing</div>
                        <p>While momentum investing can be profitable, it is also risky for several reasons:</p>
            
                        <p>Reversals Can Be Sudden: One of the biggest risks in momentum investing is that trends can change quickly. A stock that has been rising for months can suddenly reverse course due to unexpected news, changes in the market, or other factors. If you don’t react fast enough, you could end up losing money.</p>
                        <p>Chasing Trends: Sometimes, investors jump into a trend too late. By the time they buy a stock, the price may already be near its peak. If the trend reverses shortly after, they may suffer losses.</p>
                        <p>High Transaction Costs: Momentum investing often involves frequent buying and selling, which can result in higher transaction fees. For small investors, these fees can eat into profits.</p>
                        <p>Volatility: Momentum stocks tend to be more volatile, meaning their prices can swing wildly in short periods. This can make it difficult to predict the right time to buy or sell.</p>
                        <p>Market Conditions: Momentum investing works best in certain types of markets. During a bull market, when prices are generally rising, momentum strategies can be very successful. But in a sideways or bear market (when prices are stagnant or falling), finding strong trends can be more challenging.</p>
                        
                    </section>
                    
                    <section class="my-3" id="momentum_investing_risks">
                        <div class="nt-subsubtitle">Famous Momentum Investors</div>
                        <p>One of the best-known investors who applied momentum principles is Richard Driehaus, a fund manager who made a name for himself by buying stocks that were already performing well, rather than looking for undervalued or struggling companies. Driehaus emphasized the importance of buying stocks with strong upward momentum and riding the trend.</p>    
                        <p>While value investors like Warren Buffett focus on the fundamentals of a company and prefer to buy stocks at a discount, momentum investors, like Driehaus, believe that success lies in following the trend and capitalizing on short- to medium-term price movements.</p>
                    </section>
                    
                    <section class="my-3" id="momentum_investing_summary">
                        <div class="nt-subsubtitle">Summary</div>
                        <p>Momentum investing is a strategy that involves riding the waves of stock price trends, buying stocks that are going up and avoiding or selling those that are going down. It is based on the idea that trends often persist for some time due to market psychology and investor behavior.</p>
                        <p>While momentum investing can be profitable in trending markets, it comes with risks, including sudden reversals and high transaction costs. It requires close attention to market movements and timing, as well as a willingness to act quickly when trends change.</p>
                        <p>Ultimately, momentum investing is a dynamic approach that seeks to capture the power of market trends, offering potential rewards for those who can handle its fast-paced and sometimes volatile nature.</p>    
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing">
                        <hr>
                        <div class="nt-subtitle">Value Investing vs. Momentum Investing</div>
                        <p>When it comes to investing in the stock market, two popular strategies are Value Investing and Momentum Investing. Both approaches aim to make a profit, but they take very different paths to get there.</p>
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing_what_is_value_investing">
                        <div class="nt-subsubtitle">What is Value Investing?</div>
                        <p>Value investing is about finding stocks that are undervalued—meaning their current price is lower than what the company is really worth. Value investors look for companies that are strong and healthy but for some reason are being ignored or undervalued by the market.</p>
                        <p>The idea is that these stocks are "on sale," and by buying them at a low price, the investor can make money when the stock price eventually rises as the market realizes the company's true worth.</p>
                        <button mat-button (click)="scrollToSection('value_investing')">Check out the Value Investing section to know more</button>
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing_what_is_momentum_investing">
                        <div class="nt-subsubtitle">What is Momentum Investing?</div>
                        <p>Momentum investing, on the other hand, focuses on buying stocks that are already going up in price and selling those that are falling. Momentum investors don’t worry about whether a stock is cheap or expensive—they care about the trend. If a stock is going up, they expect it to keep going up, so they jump on the trend and ride the wave.</p>
                        <button mat-button (click)="scrollToSection('momentum_investing')">Check out the Momentum Investing section to know more</button>
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing_differences">
                        <div class="nt-subsubtitle">The Key Differences</div>
                        <p>Value investing looks for undervalued stocks based on a company's financial health, earnings, and potential for future growth.</p>
                        <p>Momentum investing focuses on trends, buying stocks that are performing well and selling those that are not, regardless of the company’s fundamentals.</p>
                        
                        <h3>Advantages of Value Investing</h3>
                        <p>Less Risk of Overpaying: Since value investors buy stocks that are undervalued, they have a margin of safety. Even if the stock doesn’t perform well right away, they know they didn’t overpay.</p>
                        <p>Focus on Strong Companies: Value investors focus on a company's financial health, which means they are buying into solid, stable businesses.</p>
                        <p>Long-Term Strategy: Value investing is usually a long-term approach. Investors buy stocks and hold onto them for years, allowing time for the stock’s price to rise.</p>
                        
                        <h3>Disadvantages of Value Investing</h3>
                        <p>Patience is Required: It can take a long time for the market to realize the true value of a company, so value investors have to be patient.</p>
                        <p>Misjudging Value: Sometimes, a stock might seem undervalued but could be facing serious problems that prevent it from ever recovering.</p>
                        <p>Market Fluctuations: The market doesn’t always act rationally, and a stock that looks like a good deal might not perform well for reasons beyond the company’s control.</p>
                        
                        <h3>Advantages of Momentum Investing</h3>
                        <p>Fast Results: Momentum investing can lead to quick profits, as investors buy into stocks that are already on the rise.</p>
                        <p>Taking Advantage of Trends: Momentum investors capitalize on trends in the market. If a stock is going up, they can profit from the continued momentum.</p>
                        <p>No Need to Study Fundamentals: Momentum investors focus on price movements, so they don’t need to dive deep into a company's financials. They just need to recognize a strong trend.</p>
                        
                        <h3>Disadvantages of Momentum Investing</h3>
                        <p>High Risk of Reversals: Trends can change quickly, and a stock that’s rising one day can fall the next. Momentum investors need to react fast to avoid losses.</p>
                        <p>Short-Term Focus: Momentum investing is often a short-term strategy, which can mean frequent buying and selling, leading to higher transaction costs.</p>
                        <p>Volatility: Momentum stocks are often more volatile, meaning their prices can swing up and down quickly, making it hard to predict their behavior.</p>
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing_which_is_better">
                        <div class="nt-subsubtitle">Which Strategy is Better?</div>
                        <p>There’s no one-size-fits-all answer to whether value investing or momentum investing is better. It depends on the type of investor you are:</p>
                        <p>If you are patient and prefer to invest in stable companies with strong fundamentals, value investing may suit you better. It’s a long-term strategy that requires discipline and a focus on the company’s health rather than its short-term stock price movements.</p>
                        <p>If you like fast results and are comfortable with higher risk, momentum investing could be more exciting. It’s a short-term approach that relies on market trends and quick decision-making.</p>
                    </section>
                    
                    <section class="my-3" id="value_momentum_investing_summary">
                        <div class="nt-subsubtitle">Summary</div>
                        <p>Both value investing and momentum investing have their pros and cons. Value investors seek to buy undervalued stocks and hold them for the long term, while momentum investors look for trends and try to profit from short-term price movements. The best strategy for you depends on your investment goals, risk tolerance, and how much time you’re willing to wait for returns.</p>
                    </section>
                    
                    <section class="my-3" id="indicators">
                        <div class="nt-subtitle">Indicators</div>
                        <p>Indicators are tools used by traders and investors to help them make decisions about buying and selling stocks. They are based on mathematical formulas that analyze price movements, volume, and other market data to identify trends and patterns.</p>
                        <p>There are many different types of indicators, each with its own purpose and method of calculation. Some indicators help traders identify trends, while others signal when a stock is overbought or oversold. Some indicators are used to confirm price movements, while others predict future price movements.</p>
                        <p>Indicators can be divided into two main categories: <i>technical indicators</i> and <i>fundamental indicators</i>.</p>
                        <p>Technical indicators are based on price and volume data, while fundamental indicators are based on a company’s financial information, such as earnings, revenue, and growth prospects.</p>
                        <p>On NeoTradr, we are using a lot of indicators to analyze companies, either in pillars or on company page as shown below:</p>
                        <img src="../../../assets/img/learn/fundamentals_indicators.png" class="learn_img">
                    </section>

                    <section class="my-3" id="indicators_technical">
                        <div class="nt-subsubtitle">Technical Indicators</div>
                        <p>Technical indicators are used to analyze price movements and volume data to identify trends and patterns in the stock market. They are based on mathematical formulas that calculate various aspects of a stock’s price, such as its moving average, relative strength, and momentum.</p>
                        <p>Some common technical indicators include:</p>
                        <ul>
                            <li>Moving Averages: These indicators smooth out price fluctuations to identify the direction of a stock’s trend.</li>
                            <li>Relative Strength Index (RSI): The RSI measures the strength and speed of a stock’s price movements to determine if it is overbought or oversold.</li>
                            <li>MACD (Moving Average Convergence Divergence): The MACD is a trend-following indicator that shows the relationship between two moving averages of a stock’s price.</li>
                            <li>Bollinger Bands: These indicators show the volatility of a stock by plotting two standard deviations above and below a moving average.</li>
                        </ul>
                        <p>Technical indicators are used by traders to make short-term decisions about buying and selling stocks. They help traders identify entry and exit points, confirm price movements, and predict future price movements based on historical data.</p>
                    </section>

                    <section class="my-3" id="indicators_fundamental">
                        <div class="nt-subsubtitle">Fundamental Indicators</div>
                        <p>Fundamental indicators are used to analyze a company’s financial information to determine its value and growth potential. They are based on a company’s earnings, revenue, assets, liabilities, and other financial data.</p>
                        <p>Some common fundamental indicators include:</p>
                        <ul>
                            <li>Price-to-Earnings (P/E) Ratio: The P/E ratio compares a company’s stock price to its earnings per share, indicating how much investors are willing to pay for each dollar of earnings.</li>
                            <li>Price-to-Book (P/B) Ratio: The P/B ratio compares a company’s stock price to its book value, showing how much investors are willing to pay for each dollar of assets.</li>
                            <li>Debt-to-Equity Ratio: The debt-to-equity ratio measures a company’s financial leverage by comparing its debt to its equity.</li>
                            <li>Dividend Yield: The dividend yield shows how much a company pays in dividends relative to its stock price.</li>
                        </ul>
                        <p>Fundamental indicators are used by investors to make long-term decisions about buying and holding stocks. They help investors assess a company’s financial health, growth prospects, and valuation to determine if it is a good investment.</p>
                    </section>



                    <section class="my-3" id="value_momentum_investing_summary">
                        <div class="nt-subtitle">The end</div>
                        <p>Something is missing? You expected to learn something more? You didn't get the answer you were hoping for?</p>
                        <p>Feel free to send us an email: <a href="/contact-us">using this link here</a></p>
                    </section>
                </div>
            </div>
        </div>
        

        <section class="mt-5">
            <div class="container">
                <div class="row py-0 my-0 justify-content-center">
                    <div class="col-md-12 col-lg-12 col-sm-12">
                        <app-explore-more></app-explore-more>
                    </div>
                </div>
            </div>
        </section>
        
        <section>
            <app-footer></app-footer>
        </section>
        
      </mat-sidenav-content>
    </mat-sidenav-container>
