import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/login.service';
import { StripeService } from 'src/app/core/stripe.service';

@Component({
  selector: 'app-go-premium-button',
  templateUrl: './go-premium-button.component.html',
  styleUrls: ['./go-premium-button.component.scss']
})
export class GoPremiumButtonComponent {
  @Input('color') color: string = "primary"
  
  constructor(
    private loginService: LoginService,
    private stripeService: StripeService,
    private router: Router) { }

  ngOnInit(): void {
    //TODO: Make a popup pop with different login methods
  }

  buttonClicked(): void {
   this.router.navigate(['/subscribe'])
  }
}
