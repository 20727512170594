import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface EventUpdateData {
  "type": string,
  "event_name": string,
  "timestamp": Date,
}

@Injectable({
  providedIn: 'root'
})
export class SystemStatusService {
  dataServiceUrl: string = environment.dataServiceUrl
  constructor(private http: HttpClient) { }

  getEventUpdate(): Observable<Array<EventUpdateData>> {
    return this.http.get<{
      "body": {
        "result": Array<EventUpdateData>
      }
    }>(`${this.dataServiceUrl}/components_update`).pipe(
      map(x => x.body),
      map(x => x.result)
    )
  }
}
