import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, concatMap, delay, map, Observable, ObservableInput, of, retryWhen, take, tap, throwError } from 'rxjs';


export interface IServerResponse<T> {
    body: T,
    statusCode: number,
}

export function isIServerResponse<T>(res: any): res is IServerResponse<T>{
    return ((<IServerResponse<T>>res).body !== undefined && (<IServerResponse<T>>res).statusCode !== undefined)
}
  
export interface IStaticResponse<T> {
    data: T,
    date_created: number
}
export function isIStaticResponse<T>(res: any): res is IStaticResponse<T>{
    return ((<IStaticResponse<T>>res).data !== undefined && (<IStaticResponse<T>>res).date_created !== undefined)
}

export function smartApiCaller<T>(http: HttpClient, restApiUrl: string, staticUrl?:string):Observable<T> {
    if(staticUrl){
        return http.get<T>(staticUrl).pipe(
            tap(x => {
                console.log(`Got ${staticUrl} from static`);
                // console.log('Checking JSON validity...');
                // try { // It was useless because received object 
                //     console.log(typeof x)
                //     JSON.parse(<any>x)
                // } catch(err) {
                //     console.log(`Problem identified: Wrong JSON received: ${err}`)
                //     console.log(x)
                // }
            }),
            tap(x => {
                if(x && typeof x === 'object' && "date_created" in x){
                    console.log('Static data freshness: ', x.date_created)
                }
            }), 
            catchError( (err:HttpErrorResponse, caught:Observable<T>) => {
                if(err.status == 404 || err.status == 403){
                    return http.get<T>(restApiUrl).pipe(tap(x => console.log(`Got ${restApiUrl} from REST API instead of ${staticUrl}`)))
                } else {
                    console.log(`Unknown error caught`)
                    console.log(err)
                    console.log(caught)
                    return http.get<T>(restApiUrl).pipe(tap(x => console.log(`Got ${restApiUrl} from REST API instead of ${staticUrl}`)))
                    // return throwError(() => err)
                }
            })
            )
    } else {
        return http.get<T>(restApiUrl)
    }
}

export enum Region {
    /**
     * Region supported today (not all are present)
     */
    Asia,
    Europe,
    America,
    Other
}
export const getUserMainRegion = (): Region => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(timeZone.startsWith('Asia')){
        return Region.Asia
    } else if(timeZone.startsWith('Europe')){
        return Region.Europe
    } else if(timeZone.startsWith('America')){
        return Region.America
    } else {
        return Region.Other
    }
}
