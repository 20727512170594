<div *ngIf="displayAd" class="col-12">
    <a class="ad-link" [href]="adLink" target="_blank">
        <div class="row">
            <div class="left-top-part col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <img [src]="adImage" alt="advertise" class="ibkr-ad">    
            </div>
            <div class="right-bottom-part col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                {{ adText }}
            </div>
        </div>
    </a>
</div>
