import { Component, Input } from '@angular/core';
import { LoginService } from 'src/app/core/login.service';
import { PillarData } from 'src/app/core/pillars.service';
import { TechnicalDataForPillars, computeAllTechnicalPillars, computeTechnicalData, extractTechnicalSentiment } from './technical_pillars';
import { StripeService } from 'src/app/core/stripe.service';
import { FreeTrialService } from 'src/app/core/free-trial.service';

@Component({
  selector: 'app-technical-pillars-list',
  templateUrl: './technical-pillars-list.component.html',
  styleUrls: ['./technical-pillars-list.component.scss']
})
export class TechnicalPillarsListComponent {
  isLoggedIn: boolean = false
  hasValidSubscription: boolean|null = null
  freeTrial: boolean
  @Input() stockPriceData: {labels: any[]; values: number[]} = {labels: [], values: []};
  @Input() expanded: boolean = true

  technical_data_for_pillars: TechnicalDataForPillars|null = null
  technical_pillars_results: Array<PillarData> = []
  technical_pillars_results_augmented: Array<PillarData & {score_percentage: number, score_color: string}> = []
  tapScoreState: {
    sentiment: 'buy'|'no_buy'|'no_decision',
    global_score: number,
    global_score_percentage: number,
    global_score_color: string,
  } = { sentiment: 'no_decision', global_score: -1, global_score_percentage: 0, global_score_color: 'black'}

  constructor(
    private loginService: LoginService,
    private stripeService: StripeService,
    private freeTrialService: FreeTrialService
    ){
      this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    }
  ngOnInit(): void {
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('User isLoggedIn ?:', isLoggedIn)
      this.isLoggedIn = isLoggedIn

      this.loginService.getIdToken().then(idToken => {
        this.stripeService.hasValidSubscription(idToken).subscribe(hasValidSubscription => {
          this.hasValidSubscription = hasValidSubscription
        })
      })
    })
  }

  ngOnChanges(): void {
    this.computePillars()
  }

  computePillars(): void {
    this.freeTrial = this.freeTrialService.isFreeTrialAvailable()
    this.technical_data_for_pillars = computeTechnicalData(this.stockPriceData.values)
    this.technical_pillars_results = computeAllTechnicalPillars(this.technical_data_for_pillars!)
    this.technical_pillars_results_augmented = this.technical_pillars_results.map(x => {
      const percentage = 100*(x.weight - x.weight_min_value)/(x.weight_max_value-x.weight_min_value)
      return {
        ...x,
        score_percentage: percentage,
        score_color: percentage > 66 ? 'green' : percentage < 33 ? 'red' : 'orange'
      }
    })
    this.tapScoreState = extractTechnicalSentiment(this.technical_pillars_results)
  }

  haveAccess(): boolean {
    return (this.isLoggedIn && this.hasValidSubscription) || this.freeTrial ? true : false
  }
  
}
