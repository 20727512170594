import { Injectable } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from './login.service';
import { Observable, ObservableInput, catchError, map, of, reduce, retry, switchMap, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

interface StripeSubscription {
  object: 'subscription'|string,
  customer: string,
  collection_method?: 'charge_automatically'|string,
  start_date?: number,
  currency?: string,
  id: string,
  status: 'active'|string,
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private userEndpoint: string = environment.userEndpoint
  private stripe: Stripe|null = null;
  private premiumProduct: string = environment.premiumProduct

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private snackBar: MatSnackBar) {
    this.initStripe()
  }

  handleError(err: any, caught: Observable<any>): ObservableInput<any> {
    console.error('StripeService Any error:', err, caught)
    if(err.statusCode == 403){
      this.loginService.getIdToken(true)
      this.snackBar.open(
        'It looks like your token expires, please retry in few seconds. If the problem persists try to logout and login again.',
        'Ok', {duration: 10000})
    }
    return of(null)
  }

  private async initStripe() {
    this.stripe = await loadStripe(environment.stripePubKey)
  }

  private createSubscriptionCheckoutSession(idToken: string, productId: string) {
    this.http.post(`${this.userEndpoint}/create-subscription-checkout-session`, {
      'lookup_key': productId
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      catchError(this.handleError)
    ).subscribe(resp => {
      console.log('Response from subscription checkout session:')
      console.log(resp)

      if('redirect_url' in resp){
        (<any>window.location.href) = resp.redirect_url;
      } else {
        //TODO: Do something with error cases
        //   console.log(`Error when trying to subscribe: ${JSON.stringify(resp)}`);
        //   this.snackBar.open(`${resp.message}`, 'ok', {
      }
    })
  }

  private createPortalSession(idToken: string){
    this.http.post(`${this.userEndpoint}/create-portal-session`, {}, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      catchError(this.handleError)
    ).subscribe(resp => {
      console.log('Response from portal session:')
      console.log(resp)

      if('redirect_url' in resp){
        (<any>window.location.href) = resp.redirect_url;
      } else {
        //TODO: Do something with error cases
        //   console.log(`Error when trying to subscribe: ${JSON.stringify(resp)}`);
        //   this.snackBar.open(`${resp.message}`, 'ok', {
      }
    })
  }

  public getSubscribedProduct(idToken: string): Observable<Array<StripeSubscription>> {
    return this.http.get<{'status': string, 'result': Array<StripeSubscription>}>(`${this.userEndpoint}/products`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      map(x => 'status' in x && x.status == 'ok' && 'result' in x ? x.result : []),
      catchError( (err:HttpErrorResponse, caught:Observable<any>) => {
        if(err.status == 403){
          this.loginService.resetAuthCache()
          return of(null)
        } else {
          return of(null)
        }
    })
    )
  }

  public async subscribeToPremium() {
    // Test product id: price_1O8IOrK3tTZOwvJrapcy9hRG
    this.loginService.getIdToken().then(idToken => {
      this.createSubscriptionCheckoutSession(idToken, this.premiumProduct)
    })
  }

  public async buyOneMonthOfPremium(customerEmail: string){
    // Test product id: price_1O8ISFK3tTZOwvJr7fqVNRqr
  }

  public async manageSubscription() {
    this.loginService.getIdToken().then(idToken => {
      this.createPortalSession(idToken)
    })
  }

  public hasValidSubscription(idToken: string): Observable<boolean> {
    return this.getSubscribedProduct(idToken).pipe(
      switchMap(subs => {
        if(!subs) return of(false)
        
        for(const sub of subs){
          if(sub.status == 'active'){
            return of(true)
          }
        }
        return of(false)
      })
    )
  }

}
