<div class="container">
    <div class="row">
        <div class="col-12">
            <h2>Disclaimer</h2>
            <p>
                1. <b>General Information:</b> NeoTradr provides financial insights and analysis 
                for informational purposes only. The information on this website is not intended 
                as financial advice and should not be construed as such. 
                You should not interpret any information on this website or other material as legal, 
                tax, investment, financial, or other advice.
            </p>
            <p>
                2. <b>No Investment Advice:</b> NeoTradr does not provide investment advice or 
                recommendations to buy, sell, or hold any securities. All decisions made based 
                on the information provided on this website are the <b>responsibility of the user</b>.
                Nothing contained on this website constitutes professional and/or financial advice
                , nor does any information on the website constitute a comprehensive or complete
                statement of the matters discussed or the law relating thereto. 

            </p>
            <p>
                3. <b>Risk of Loss:</b> Trading and investing in financial markets involve a risk 
                of substantial losses. Users are solely responsible for any losses incurred as a 
                result of their financial decisions. NeoTradr is not liable for any financial losses 
                or damages arising from the use of this website.
            </p>
            <p>
                4. <b>Accuracy of Information:</b> While we strive to provide accurate and up-to-date 
                information, NeoTradr does not guarantee the accuracy, completeness, or timeliness 
                of the information on this website. Users should independently verify all information 
                before making any financial decisions.
            </p>
            <p>
                5. <b>Third-Party Content:</b> NeoTradr may include links to third-party websites, 
                articles, or content for informational purposes. The inclusion of such links does 
                not imply endorsement or approval of the content. NeoTradr is not responsible for 
                the content of third-party websites.
            </p>
            <p>
                6. <b>Educational Purpose:</b> The content on NeoTradr is intended for educational 
                and informational purposes only. Users should seek professional financial advice 
                and conduct their own research before making any investment decisions.
            </p>
            <p>
                7. <b>No Guarantee of Future Results:</b> Past performance is not indicative of 
                future results. NeoTradr makes no guarantees or warranties regarding future financial 
                performance based on the information provided on this website.
            </p>
            <p>
                8. <b>Legal Disclaimer:</b> NeoTradr is not a registered financial advisor or 
                broker-dealer. The use of this website does not create a financial advisory or 
                client relationship.
            </p>
            <p>
                9. <b>Terms of Use:</b> By using this website, you agree to the terms and conditions 
                outlined in this disclaimer. NeoTradr reserves the right to modify or update these 
                terms at any time without prior notice.
            </p>
            <p>
                10. <b>Consultation with Professionals:</b> Users are encouraged to consult with 
                qualified financial professionals and legal advisors to assess their individual 
                financial situation and legal obligations.
            </p>
            <p>
                <b>Contact Information:</b>
                If you have any questions or concerns regarding this disclaimer, please contact 
                us at <i>contact&#64;neotradr.com</i>.
            
            </p>
            Last Updated: 26 November, 2023            
        </div>
    </div>
</div>
<app-footer></app-footer>