import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/login.service';
import { HighestScoreData, PillarsService } from 'src/app/core/pillars.service';
import { convert_int_to_ext_symbol, getColorFromScore } from 'src/app/core/helpers';

@Component({
  selector: 'app-highest-score-ranking',
  templateUrl: './highest-score-ranking.component.html',
  styleUrls: ['./highest-score-ranking.component.scss']
})
export class HighestScoreRankingComponent implements OnInit {
  @Input() limit: number = 0
  highestRanking: Array<HighestScoreData & {blur: boolean}> = []
  displayedColumns = ['score', 'symbol', 'action']
  dataSource: MatTableDataSource<HighestScoreData & {blur: boolean}> = new MatTableDataSource()
  isLoading: boolean = false
  isLoggedIn: boolean = false
  convert_int_to_ext_symbol = convert_int_to_ext_symbol
  getColorFromScore = getColorFromScore

  
  constructor(
    private pillarsService: PillarsService,
    private router: Router,
    private loginService: LoginService) {
    }

  ngOnInit(): void {
    this.isLoading = true
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('isLoggedChanged: ', isLoggedIn)
      this.isLoggedIn = isLoggedIn
      this.refreshTable()
    })
    this.refreshTable()
  }

  refreshTable(): void {
    this.isLoading = true
    this.pillarsService.getHighestScoreRanking().subscribe(highestRanking => {
      this.highestRanking = highestRanking.filter((val, i, arr) => {
        return arr.findIndex(x => x.symbol == val.symbol) == i ? true : false
      }).map((val, index) => {
        if(!this.isLoggedIn && (/*index < 5 ||*/ index > 18)){
          val.score = 100
          val.symbol = "FAKE"
          val.company_name = "FAKE Industries"
          return {...val, 'blur': true}
        } else {
          return {...val, 'blur': false}
        }
      })
      this.highestRanking = this.limit > 0 ? this.highestRanking.slice(0, this.limit) : this.highestRanking
      this.dataSource = new MatTableDataSource<HighestScoreData & {blur: boolean}>(this.highestRanking)
      this.isLoading = false
    })
  }

  goToCompanyPage(symbol: string): void {
    if(symbol != "FAKE"){
      this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
    }
  }

}
