<div class="input-form mx-auto">
    <mat-form-field
    class="input-form-field"
    appearance="outline">
        <mat-label class="input-field">Search</mat-label>
        <input  matInput placeholder="Company search" [(ngModel)]="symbol" [disabled]="_disabled" (keydown.enter)="onClick('value')">

        <mat-select class="search-bar-select mat-select-arrow" #singleSelect (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let item of search_list" [value]="item.symbol">
                {{item.symbol}}: {{item.company_name}}
            </mat-option>
        </mat-select>
        
        <button matSuffix class="action-button" mat-raised-button color="primary" 
        (click)="onClick('value')" [disabled]="_disabled" matTooltip="Analyze value">
            <mat-icon class="action-button-icon-value">search</mat-icon>
            <mat-icon class="action-button-icon-value">trending_up</mat-icon>
        </button>
        <button matSuffix class="action-button" mat-raised-button color="primary" 
        (click)="onClick('momentum')" [disabled]="_disabled" matTooltip="Analyze momentum">
            <mat-icon class="action-button-icon-momentum">search</mat-icon>
            <mat-icon class="action-button-icon-momentum">flash_on</mat-icon>
        </button>
        <button *ngIf="comparison_enable" matSuffix class="compare-button d-none d-md-inline" 
        mat-raised-button color="primary" (click)="goToComparison()" matTooltip="Compare with...">
            <mat-icon class="action-button-icon-default">compare_arrows</mat-icon>
        </button>
        <button *ngIf="shuffle_enable" matSuffix class="compare-button d-none d-md-inline" 
        mat-raised-button color="primary" (click)="onClickRandom()" matTooltip="Random">
            <mat-icon class="action-button-icon-default">shuffle</mat-icon>
        </button>
    </mat-form-field>
    <!-- <div class="d-md-none">
        <button *ngIf="comparison_enable" matSuffix class="compare-button d-md-none" mat-icon-button color="primary" (click)="goToComparison()" matTooltip="Compare with...">
            <mat-icon style="margin-left: auto; margin-right: auto;">compare_arrows</mat-icon>
        </button>
        <button matSuffix class="d-md-none" mat-icon-button color="primary" matTooltip="Random" (click)="onClickRandom()" [disabled]="_disabled">
            <mat-icon>shuffle</mat-icon>
        </button>
    </div>
    <button matSuffix class="d-none d-md-inline" mat-icon-button color="primary" matTooltip="Random" (click)="onClickRandom()" [disabled]="_disabled">
        <mat-icon>shuffle</mat-icon>
    </button> -->
</div>



<!-- <div class="search-bar-container">
        <input
        type="text"
        placeholder="Search..."
        [(ngModel)]="symbol"
        class="search-input"
        />
    
        <button class="icon-button" (click)="goToComparison()">
            <mat-icon>compare_arrows</mat-icon>
        </button>
    
        <button class="icon-button" (click)="onClickRandom()">
            <mat-icon>shuffle</mat-icon>
        </button>
    
        <button class="analyze-button" (click)="onClick()">
        Analyze
        </button>
</div> -->

