import { Component } from '@angular/core';
import { EventUpdateData, SystemStatusService } from '../core/system-status.service';

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent {
  eventUpdate: Array<EventUpdateData> = []
  eventDataUpdated: Array<EventUpdateData> = []
  eventPillarsComputed: Array<EventUpdateData> = []
  constructor(private systemStatus: SystemStatusService){}

  ngOnInit(): void {
    this.systemStatus.getEventUpdate().subscribe(resp => {
      this.eventUpdate = resp
      this.eventDataUpdated = this.eventUpdate.filter(x => x.event_name.includes("_data_updated"))
      this.eventPillarsComputed = this.eventUpdate.filter(x => x.event_name.includes("_pillars_computed"))
    })
  }
}
