<div>
    <div (click)="changeShort()">
        <app-simple-progress-bar color="#36a223" [percentage]="this.percentage"></app-simple-progress-bar>
    </div>
    <div *ngIf="!this.short">
        <div *ngFor="let strength of this.strengthList">
            <mat-icon class="strength">check_circle_outline</mat-icon><span [innerHTML]="strength"></span>
        </div>
    </div>
</div>
