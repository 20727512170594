<div class="container mt-5">
    <div class="row">
        <div class="col-3">
            <div style="box-sizing: border-box; z-index: 15; height: 100px; width: 100px;">
                <picture>
                    <source srcset="../../assets/img/logo.webp" type="image/webp">
                    <source srcset="../../assets/img/logo.png" type="image/png"> 
                    <img ngSrc="../../assets/img/logo.png" priority alt="Logo of NeoTradr" style="display: block; width: 100%; height: 100%;">
                </picture>
            </div>
            <div style="font-family: Inter; font-size: 14px; font-weight: 300; color: rgb(107, 114, 128); word-spacing: 1px; line-height: 1.5; padding-left: 5px; padding-right: 5px;">
                <span>
                    Thanks for trusting
                </span>
                <font class="nt-accent">
                    NeoTradr
                </font>
                <span>(v{{ntVersion}})</span>
            </div>
        </div>
        <div class="col-3">
        </div>
        <div class="col-3"></div>
        <div class="col-3"></div>
    </div>
</div>

<div style="box-sizing: border-box; z-index: 14;border-top: 1px solid rgb(204, 204, 204); margin-top: 30px;">
    <div class="row text-center m-0" style="color: rgb(107, 114, 128);">
        <div class="col-md-3"><a href="https://blog.neotradr.com" target="_blank">New NeoTradr Blog</a></div>
        <div class="col-md-3"><a href="https://neotradr.wordpress.com" target="_blank">NeoTradr Wordpress</a></div>
        <div class="col-md-3"><a href="https://www.facebook.com/NeoTradr?mibextid=2JQ9oc" target="_blank">Follow us on Facebook</a></div>
        <div class="col-md-3"><a href="https://www.linkedin.com/company/neotradr" target="_blank">Follow us on LinkedIn</a></div>
    </div>
    <div class="row text-center m-0" style="color: rgb(107, 114, 128);">
        <div class="col-md-3">
            <a href="/about-us">About us</a>
        </div>
        <div class="col-md-3">
            <a href="/legal">Privacy Policy</a>
        </div>
        <div class="col-md-3">
            <a href="/contact-us">Contact us</a>
        </div>
        <div class="col-md-3">
            <a href="/disclaimer">Diclaimer</a>
        </div>
    </div>
    <div class="row text-center m-0" style="color: rgb(107, 114, 128);">
        <div class="col-md-12">
            <a href="http://beta.neotradr.com" target="_blank">Try beta</a>
        </div>
    </div>
    <div class="" style="padding-top: 8px; height: 45px;">
        <div style="z-index: 13; font-family: Inter; font-size: 14px; font-weight: 300; color: rgb(107, 114, 128); text-align: center; line-height: 1.4; padding-top: 8px;">
            Copyrights © NeoTradr 2024. All Rights Reserved.
        </div>
    </div>
</div>
