<mat-card style="min-width: 50%;">
    <div class="login-google my-4">
        <button mat-raised-button color="primary" (click)="loginWithGoogle()">Login with Google</button>
        <br>
        or 
        <hr>
    </div>

    <mat-tab-group>
        <mat-tab label="Login">
            <mat-card-header>
                <mat-card-title>Login</mat-card-title>
                <mat-card-subtitle>
                    Your journey to value investing starts here
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form class="">
                    <mat-form-field class="">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput [formControl]="loginEmailFormControl" placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="loginEmailFormControl.hasError('email') && !loginEmailFormControl.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="loginEmailFormControl.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field class="">
                        <mat-label>Password</mat-label>
                        <input type="password" matInput [formControl]="loginPasswordFormControl">
                        <mat-error *ngIf="loginPasswordFormControl.hasError('required')">
                            Password is <strong>required</strong> with 6 characters minimum
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <button mat-raised-button (click)="loginClicked()">Login</button>
                </form>
            </mat-card-content>
        </mat-tab>
        <mat-tab label="Register">
            <mat-card-header>
                <mat-card-title>Register</mat-card-title>
                <mat-card-subtitle>
                    Your journey to value investing starts here
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form class="">
                    <mat-form-field class="">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput [formControl]="registerEmailFormControl" placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="registerEmailFormControl.hasError('email') && !registerEmailFormControl.hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="registerEmailFormControl.hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <mat-form-field class="">
                        <mat-label>Password</mat-label>
                        <input type="password" matInput [formControl]="registerPasswordFormControl">
                        <mat-error *ngIf="registerPasswordFormControl.hasError('required')">
                            Password is <strong>required</strong> with 6 characters minimum
                        </mat-error>
                    </mat-form-field>
                    <br>
                    <button mat-raised-button (click)="registerClicked()">Register</button>
                </form>
            </mat-card-content>
        </mat-tab>
    </mat-tab-group>
</mat-card>

