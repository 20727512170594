import { Component, Input } from '@angular/core';
import e from 'express';
import { environment } from 'src/environments/environment';

export enum AdName {
  IBKR,
  TradingView,
}

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent {
  @Input() adName: AdName|null = null
  ibkrReferralLink = environment.ibkrReferralLink;
  tradingViewReferralLink = environment.tradingViewReferralLink;
  displayAd = true;

  adLink: undefined|string = undefined;
  adImage: undefined|string = undefined;
  adText: undefined|string = undefined;

  ngOnInit() {
    if(this.adName === null){
      this.adName = Math.random() > 0.5 ? AdName.IBKR : AdName.TradingView;
    } 
  
    if(this.adName === AdName.IBKR){
      this.adLink = this.ibkrReferralLink;
      this.adText = "We are using and recommending Interactive Brokers for investing."
      this.adImage = "assets/img/ads/ibkr.png"
    } else if(this.adName === AdName.TradingView){
      this.adLink = this.tradingViewReferralLink;
      this.adText = "We are using TradingView to do advanced technical analysis."
      this.adImage = "assets/img/ads/tradingview.png"
    }
  }
}
