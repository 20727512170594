import { Component, Input } from '@angular/core';
import { GroupedNewsGroup, IntelligenceService } from '../../core/intelligence.service';

@Component({
  selector: 'app-news-ticker',
  templateUrl: './news-ticker.component.html',
  styleUrls: ['./news-ticker.component.scss']
})
export class NewsTickerComponent {
  @Input() n_data: number = 10
  
  grouped_news: Array<GroupedNewsGroup> = [];
  isLoading: boolean = true

  constructor(private intelligence: IntelligenceService) { }

  ngOnInit(): void {
    this.isLoading = true
    
    this.intelligence.getGroupedNews().subscribe(grouped_news_result => {
      try {
        if(grouped_news_result && grouped_news_result.groups){
          let grouped_news = grouped_news_result.groups
          grouped_news.forEach(group => {
            group.articles_data.sort((a, b) => {
              return a.article.datetime > b.article.datetime ? -1 : 1
            })
          });

          grouped_news.sort((a, b) => {
            return a.articles_data[0].article.datetime > b.articles_data[0].article.datetime ? -1 : 1
          })
          grouped_news = grouped_news.filter((v, i) => i < this.n_data)
          this.grouped_news = grouped_news // Assign only at the end make the UI refresh correctly
          this.isLoading = false
        } else {
          this.grouped_news = []
          this.isLoading = false
        }
        console.log('N Grouped News: ', this.grouped_news.length)
      } catch(err) {
        console.warn('Error when sorting data: ', err);
        this.isLoading = false
      }
    })
  }


}
