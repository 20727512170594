<div class="header-sections">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="mt-4">
                    <div class="mt-5">
                        <div class="nt-primary" style="font-family: Inter; font-size: 45px; font-weight: 800; line-height: 1.2;">
                            <span>Join <font class="nt-accent">Premium</font> Now</span>
                            <br>
                            <!-- <font class="nt-accent">And get much more than what you pay</font> -->
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="mb-5" style="font-family: Inter; font-size: 22px; color: rgb(107, 114, 128); line-height: 1.4;">
                            And get big value for half the price of a Starbucks a month!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mt-4">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 my-3" *ngIf="this.panels[0]">
            <div class="best-value-placeholder">You found me</div>
            <mat-card class="subscription-card mat-elevation-z8">
                <div class="top-description-login">Subscriber</div>
                <mat-card-header>
                    <mat-card-title>{{this.panels[0].title}}</mat-card-title>
                    <mat-card-subtitle>{{ this.panels[0].priceTag }} <s>{{this.panels[0].insteadOfPriceTag}}</s></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p class="my-2">{{this.panels[0].descriptionText}}</p>
                    <p>Features</p>
                    <p *ngFor="let feature of this.panels[0].featureList">
                        <mat-icon class="check-color">check</mat-icon> 
                        {{feature}}
                    </p>
                </mat-card-content>
                <mat-card-content *ngIf="this.ntUser">
                    <div class="login-text-highlight">
                        You already subscribed and you have access to all listed benefits!
                    </div>
                </mat-card-content>

                <mat-card-actions class="mx-auto">
                    <button mat-raised-button color="primary" (click)="this.login()" [disabled]="this.ntUser">Sign Up</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 my-3" *ngIf="this.panels[1]">
            <div class="best-value">Best value</div>
            <mat-card class="subscription-card mat-elevation-z8">
                <div class="top-description-premium">Premium</div>
                <div class="text-center mt-2" >
                    <app-premium-feature-icon></app-premium-feature-icon>
                </div>
                <mat-card-header>
                    <mat-card-title>{{this.panels[1].title}}</mat-card-title>
                    <mat-card-subtitle>{{ this.panels[1].priceTag }} <s>{{this.panels[1].insteadOfPriceTag}}</s></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p class="my-2">{{this.panels[1].descriptionText}}</p>
                    <p>Features</p>
                    <p *ngFor="let feature of this.panels[1].featureList">
                        <mat-icon class="check-color">check</mat-icon> 
                        {{feature}}
                    </p>
                </mat-card-content>
                
                <mat-card-content *ngIf="this.hasValidSubscription">
                    <div class="premium-text-highlight">
                        You are already a premium user and you have access to all listed benefits!
                    </div>
                </mat-card-content>

                <mat-card-content>
                    <img class="payment-methods mt-3" src="../../assets/payment-methods.png">
                </mat-card-content>
                
                <mat-card-actions class="mx-auto">
                    <button mat-raised-button color="primary" (click)="this.subscribeClicked()" [disabled]="this.hasValidSubscription">Join Premium</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 my-3" *ngIf="this.panels[2]">
            <div class="best-value-placeholder">You found me</div>
            <mat-card class="subscription-card mat-elevation-z8">
                <div class="top-description-premium">Premium</div>
                <div class="text-center mt-2" >
                    <app-premium-feature-icon></app-premium-feature-icon>
                </div>
                <mat-card-header>
                    <mat-card-title>{{this.panels[2].title}}</mat-card-title>
                    <mat-card-subtitle>{{ this.panels[2].priceTag }} <s>{{this.panels[2].insteadOfPriceTag}}</s></mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p class="my-2">{{this.panels[2].descriptionText}}</p>
                    <p>Features</p>
                    <p *ngFor="let feature of this.panels[2].featureList">
                        <mat-icon class="check-color">check</mat-icon> 
                        {{feature}}
                    </p>
                </mat-card-content>

                <mat-card-content *ngIf="this.hasValidSubscription">
                    <div class="premium-text-highlight">
                        You are already a premium user and you have access to all listed benefits!
                    </div>
                </mat-card-content>

                <mat-card-content>
                    <img class="payment-methods mt-3" src="../../assets/payment-methods.png">
                </mat-card-content>
                <mat-card-actions class="mx-auto">
                    <button [disabled]="buyDisable || this.hasValidSubscription" mat-raised-button color="primary" (click)="this.buyClicked()">Buy Premium</button>
                </mat-card-actions>
            </mat-card>
        </div>
    
    </div>       
</div>

<footer style="background-color: rgb(244, 245, 247); height: 222px;">
    <app-footer></app-footer>
</footer>

