import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { catchError, concatMap, delay, map, Observable, ObservableInput, of, retryWhen, take, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { convert_int_to_ext_symbol, getAvgValueFromDbDataArray, getFidFromAllData, getLatestFidFromAllData, groupBy, leastSquares1d } from './helpers';
import { getLocalStorageItem, setLocalStorageItem } from 'src/app/core/local-storage-manager'
import { formatCurrency, formatNumber, formatPercent } from '@angular/common';
import { FinancialData, DbData } from './common_types'
import { smartApiCaller, IServerResponse, IStaticResponse, isIStaticResponse, isIServerResponse } from './core_common';
import { FreeTrialService } from './free-trial.service';


export interface AllSymbolData {
  result: Array<{data: Array<DbData>, financial_id: string}>
  symbol: string
}

export interface StatusData {
  result: {
    "source": string,
    "status": string,
    "symbol": string,
    "timestamp": Date
  }
}

export interface HighestScoreData {
  symbol: string,
  score: number,
  company_name: string,
  company_sector: string,
  company_industry: string,
}

export interface PillarData {
  id: string
  short_description: string
  result?: boolean
  value?: string
  weight: number
  weight_min_value: number, // Minimum value weight can have (used with variable weight)
  weight_max_value: number, // Maximum value weight can have (used with variable weight)
  warnings: Array<string>,
  data_info?: Array<DbData>,
}

export interface PillarsData {
  pillars: Array<PillarData>,
  score: number,
  symbol: string,
  timestamp: Date,
  company_name?: string,
  company_sector?: string,
  company_industry?: string,
}

export interface SWOTData{
  strengths: Array<PillarData>,
  weaknesses: Array<PillarData>,
  opportunities: Array<PillarData>,
  threats: Array<PillarData>,
  n_internal_pillars: number,
  n_external_pillars: number,
}

interface AllPillarsBody {
  message: string,
  message_id:string,
  result: Array<PillarsData>
}

interface ChartJsData {
  labels: Array<any>
  values:Array<number>
}

interface SymbolRankingResultBody {
  message: string,
  message_id: string,
  result: Array<HighestScoreData>
}
interface SymbolSectorRankingResultBody {
  message: string,
  message_id: string,
  result: {[key: string]: Array<HighestScoreData>}
}


@Injectable({
  providedIn: 'root'
})
export class PillarsService {
  dataServiceUrl: string = environment.dataServiceUrl
  dataServiceStaticUrl: string = environment.dataServiceStaticUrl

  constructor(private http: HttpClient, @Inject(LOCALE_ID) public locale: string, private freeTrialService: FreeTrialService) {
    console.log('pillars.service: locale', locale)
  }

  errorHandlerAllData(err: any, caught: Observable<AllSymbolData>): ObservableInput<any> {
    console.error('AllData error:', err, caught)
    return of(null)
  }

  errorHandlerAny(err: any, caught: any): ObservableInput<any> {
    console.error('Any error:', err, caught)
    return of(null)
  }

  _buildRestUrlForResource(resourceName: string): string {
    return `${this.dataServiceUrl}/${resourceName}`
  }

  _buildStaticUrlForResource(resourceName: string): string {
      return `${this.dataServiceStaticUrl}/data/${resourceName}`
  }

  _buildRestUrlForSymbol(symbol: string, dataName: string): string {
      return `${this.dataServiceUrl}/${dataName}/${convert_int_to_ext_symbol(symbol)}`
  }
  _buildStaticUrlForSymbol(symbol: string, dataName: string): string {
      return `${this.dataServiceStaticUrl}/data/company/${symbol}/${dataName}`
  }

  getHighestScoreRanking(): Observable<Array<HighestScoreData>> {
    const cacheData = getLocalStorageItem(`highest-scores`)
    if(cacheData){
      return of(cacheData)
    } else {
      return smartApiCaller<IServerResponse<SymbolRankingResultBody>|IStaticResponse<SymbolRankingResultBody>>(
        this.http,  
        this._buildRestUrlForResource('highest_score'),
          this._buildStaticUrlForResource('highest_score'))
          .pipe(
            map(x => {
              if(isIServerResponse<SymbolRankingResultBody>(x)){
                return x.body.result
              } else if(isIStaticResponse<SymbolRankingResultBody>(x)){
                return x.data.result
              }
              throw new Error(`No correct value received for response of getHighestScoreRanking: ${JSON.stringify(x)}`)
            }),
            tap(x => setLocalStorageItem(`highest-scores`, x, 60*60*6)),
            catchError(this.errorHandlerAny)
            )
    }
  }

  getHighestPerSectorRanking(): Observable<{[key: string]: Array<HighestScoreData>}> {
    const cacheData = getLocalStorageItem(`highest-scores-by-sector`)
    if(cacheData){
      return of(cacheData)
    } else {
      return smartApiCaller<IServerResponse<SymbolSectorRankingResultBody>|IStaticResponse<SymbolSectorRankingResultBody>>(
        this.http,
        this._buildRestUrlForResource('highest_score_by_sector'),
        this._buildStaticUrlForResource('highest_score_by_sector'))
          .pipe(
            map(x => {
              if(isIServerResponse<SymbolSectorRankingResultBody>(x)){
                return x.body.result
              } else if(isIStaticResponse<SymbolSectorRankingResultBody>(x)){
                return x.data.result
              }
              throw new Error(`No correct value received for response of getHighestPerSectorRanking: ${JSON.stringify(x)}`)
            }),
            tap(x => setLocalStorageItem(`highest-scores-by-sector`, x, 60*60*6)),
            catchError(this.errorHandlerAny)
        )
    }
  }

  getAllSymbolData(symbol: string): Observable<AllSymbolData> {
    const cacheData = getLocalStorageItem(`${symbol}-allsymboldata`)
    if(cacheData){
      return of(cacheData)
    } else {
      return smartApiCaller<IServerResponse<AllSymbolData>|IStaticResponse<AllSymbolData>>(
        this.http,
        this._buildRestUrlForSymbol(symbol, 'get_all_data'),
        this._buildStaticUrlForSymbol(symbol, 'get_all_data')
      ).pipe(
        map(x => {
          if(isIStaticResponse<AllSymbolData>(x)){
            return x.data
          } else if(isIServerResponse<AllSymbolData>(x)){
            return x.body
          }
          throw new Error(`No correct value received for response of getAllSymbolData: ${JSON.stringify(x)}`)
        }),
        tap(x => setLocalStorageItem(`${symbol}-allsymboldata`, x, 60*60*6)),
        tap(() => this.freeTrialService.decrementAnalysisCount()),
        catchError(this.errorHandlerAllData)
      )
    }
  }

  getSymbolStatus(symbol: string): Observable<StatusData> {
    return this.http.get<{body: StatusData, statusCode: number}>(`${this.dataServiceUrl}/get_status/${convert_int_to_ext_symbol(symbol)}`).pipe(
      map(x => x.body),
      catchError(this.errorHandlerAny)
    )
  }
  
  getPortfolioPillars(cacheName: string, restUrl: string, staticUrl: string): Observable<Array<PillarsData>> {
    const cacheData = getLocalStorageItem(cacheName)
    if(cacheData){
      return of(cacheData)
    } else {
      return smartApiCaller<IServerResponse<AllPillarsBody>|IStaticResponse<AllPillarsBody>>(
        this.http,
        restUrl,
        staticUrl
      ).pipe(
        map(x => {
          if(isIServerResponse<AllPillarsBody>(x)){
            return x.body.result
          } else if(isIStaticResponse<AllPillarsBody>(x)){
            return x.data.result
          }
          throw new Error(`No correct value received for response of ${cacheName} (${restUrl}, ${staticUrl}): ${JSON.stringify(x)}`)
        }),
        tap(x => setLocalStorageItem(cacheName!, x, 60*60*6)),
        catchError(this.errorHandlerAny)
      )
    }
  }

  createComputedDbData(symbol: string, name: string, value: number): DbData {
    return {
      financial_id: name,
      source: 'computed',
      data_date: new Date(),
      data_format: 'float',
      timestamp: new Date(),
      value: value,
      symbol: symbol
    }
  }

  computePillar1(all_data: AllSymbolData): PillarData {
    /*
    Pillar 1: True if PER over last 5 year is lower than 22.5 (market medium and graham estimation)
    */
    let warnings: Array<string> = []
    let res: boolean|undefined
    let value: string|undefined
    let data_info: Array<DbData> = []
    
    const priceData = getLatestFidFromAllData(all_data, FinancialData.ID.STOCK_PRICE)
    priceData ? data_info.push(priceData) : null

    let price = priceData?.value
    price = price ? price : 0
    
    let net_income_per_share_data = getFidFromAllData(all_data, FinancialData.ID.NET_INCOME_PER_SHARE)

    console.log(`net_income_per_share data got: ${net_income_per_share_data?.length}`)
    if(net_income_per_share_data != null){
      net_income_per_share_data = net_income_per_share_data.slice(0, 5)
      if(net_income_per_share_data.length != 5){
        warnings.push(`Pillar 1 was computed over ${net_income_per_share_data.length} years instead of 5 due to missing data`)
      }
      net_income_per_share_data.forEach(d => data_info.push(d))

      const net_income_per_share_5y_avg = getAvgValueFromDbDataArray(net_income_per_share_data ? net_income_per_share_data : [])
      data_info.push(this.createComputedDbData(all_data.symbol, 'net_income_5y_avg', net_income_per_share_5y_avg))
      
      let per_5y = price / net_income_per_share_5y_avg
      value = `${formatNumber(per_5y, this.locale)}`
      if(per_5y > 0){
        res = per_5y < 22.5 ? true : false        
      } else {
        res = false
      }
    } else {
      warnings.push(`Net income data are missing for ${all_data.symbol}`)
    }
  
    return {
      id: "1",
      result: res,
      value: value,
      weight: 0.7,
      weight_min_value: 0.7,
      weight_max_value: 0.7,
      short_description: 'PER over last 5 year is lower than 22.5',
      warnings: warnings,
      data_info: data_info
    }
  }

  computePillar2(allData: AllSymbolData): PillarData {
    /*
    Return True if ROIC of last year vs 5 year ago is grater than 9% (which means growth)
    Indicate that the company if efficient at making cashflow on the money invested 
    */
   let res: boolean|undefined
   let value: string|undefined
   let warnings: Array<string> = []
   let data_info: Array<DbData> = []

    try {
      let roic_data = getFidFromAllData(allData, FinancialData.ID.ROIC)
      if(roic_data != null) {
        roic_data = roic_data.slice(0, 5)
        roic_data.forEach(d => data_info.push(d))

        if(roic_data.length != 5){
          warnings.push(`Pillar 2 was computed over ${roic_data.length} years instead of 5 due to missing data`)
        }
        // const roic_5y = getAvgValueFromDbDataArray(roic_data) //openbb_buffer.roic_5y_percent()
        const roic_5y = roic_data[0].value - roic_data[roic_data.length-1].value
        data_info.push(this.createComputedDbData(allData.symbol, 'roic_5y_avg', roic_5y))
        res = roic_5y > 0.09 ? true : false
        value = `${formatPercent(roic_5y, this.locale)}`
      } else {
        warnings.push(`Warning: ROIC data are missing for ${allData.symbol}`)
      }
    } catch(e) {
      console.error(e)
      warnings.push('Error: Unable to compute pillar')
    }

    return {
      id: "2",
      result: res,
      short_description: "ROIC last year vs 5y greater than 9%",
      weight: 0.9,
      weight_min_value: 0.9,
      weight_max_value: 0.9,
      value: value,
      warnings: warnings,
      data_info: data_info
    }
  }

  computePillar3(allData: AllSymbolData): PillarData {
    /*
    Pillar 3: return True if the Revenue last year is greater than 5 years ago
    */
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let revenue_data = getFidFromAllData(allData, FinancialData.ID.REVENUE)
    if(revenue_data != null){
      // revenue_delta_5y = openbb_buffer.get_delta_revenue_5y()
      revenue_data = revenue_data.slice(0, 5)
      if(revenue_data.length != 5){
        warnings.push(`Pillar 3 was computed over ${revenue_data.length} years instead of 5 due to missing data`)
      }
      revenue_data.forEach(d => data_info.push(d))

      const revenue_delta_5y = revenue_data[0].value - revenue_data[revenue_data.length-1].value
      data_info.push(this.createComputedDbData(allData.symbol, 'revenue_delta_5y', revenue_delta_5y))

      res = revenue_delta_5y > 0 ? true : false
      value = `${res ? "+ ": " "}${formatCurrency(revenue_delta_5y, this.locale, '$')}`
    } else {
      warnings.push(`Warning: revenue data are missing for ${allData.symbol}`)
    }

    // text = f"Pillar 3:  {get_icon(res)}: Revenue last year is greater than 5y ago: {revenue_delta_5y}"
    return {
      id: "3",
      result: res,
      short_description: "Revenue last year is greater than 5y ago",
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar4(allData: AllSymbolData): PillarData {
    /*
    Pillar 4: return True if the Net Income of last year is greater than 5 years ago
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let net_income_data = getFidFromAllData(allData, FinancialData.ID.NET_INCOME)
    if(net_income_data != null){
      // revenue_delta_5y = openbb_buffer.get_delta_revenue_5y()
      net_income_data = net_income_data.slice(0, 5)
      if(net_income_data.length != 5){
        warnings.push(`Pillar 4 was computed over ${net_income_data.length} years instead of 5 due to missing data`)
      }
      net_income_data.forEach(d => data_info.push(d))

      const net_income_delta_5y = net_income_data[0].value - net_income_data[net_income_data.length-1].value
      data_info.push(this.createComputedDbData(allData.symbol, 'net_income_delta_5y', net_income_delta_5y))
      res = net_income_delta_5y > 0 ? true : false
      value = `${res ? "+ ": " "}${formatCurrency(net_income_delta_5y, this.locale, '$')}`
    } else {
      warnings.push(`Warning: net income data are missing for ${allData.symbol}`)
    }

    return {
      id: "4",
      result: res,
      short_description: "Net income last year is greater than 5y ago",
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar5(allData: AllSymbolData): PillarData {
    /*
    Return True if there is less shares last year than 5 years ago
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let share_outstanding_data = getFidFromAllData(allData, FinancialData.ID.NUMBER_SHARES)
    if(share_outstanding_data != null){
      share_outstanding_data = share_outstanding_data.slice(0, 5)
      if(share_outstanding_data.length != 5){
        warnings.push(`Pillar 5 was computed over ${share_outstanding_data.length} years instead of 5 due to missing data`)
      }
      const latest_share_outstanding = share_outstanding_data[0].value
      data_info.push(this.createComputedDbData(allData.symbol, 'latest_share_outstanding', latest_share_outstanding))
      const share_output_5y_ago = share_outstanding_data[share_outstanding_data.length-1].value
      data_info.push(this.createComputedDbData(allData.symbol, 'share_outstanding_5y_ago', share_output_5y_ago))

      const share_outstanding_5y_percent = (latest_share_outstanding - share_output_5y_ago)/share_output_5y_ago
      data_info.push(this.createComputedDbData(allData.symbol, 'share_outstanding_5y_evolution', share_outstanding_5y_percent))

      res = share_outstanding_5y_percent <= 0 ? true : false
      value = `${formatPercent(share_outstanding_5y_percent, this.locale)}`
    } else {
      warnings.push(`Warning: number of shares data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "5",
      result: res,
      short_description: "Share outstanding last year lower than 5y ago",
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar6(allData: AllSymbolData): PillarData {
    /*
      Return True if ltl/5y_fcf if < 5
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let free_cashflow_avg_5y: number|undefined
    let free_cashflow_data = getFidFromAllData(allData, FinancialData.ID.FREE_CASHFLOW)
    if(free_cashflow_data != null && free_cashflow_data.length > 0){
      free_cashflow_data = free_cashflow_data.slice(0, 5)
      if(free_cashflow_data.length != 5){
        warnings.push(`Pillar 6 was computed with ${free_cashflow_data.length} years of free cashflow instead of 5 due to missing data`)
      }
      free_cashflow_data.forEach(d => data_info.push(d))

      free_cashflow_avg_5y = getAvgValueFromDbDataArray(free_cashflow_data)
      data_info.push(this.createComputedDbData(allData.symbol, 'free_cashflow_avg_5y', free_cashflow_avg_5y))
    } else {
      warnings.push(`Warning: free cashflow data are missing for ${allData.symbol}`)
    }

    let ltl_latest:number|undefined
    let ltl_data = getLatestFidFromAllData(allData, FinancialData.ID.TOTAL_NON_CURRENT_LIABILITIES)
    if(ltl_data != null){
      data_info.push(ltl_data)
      ltl_latest = ltl_data.value
    } else {
      warnings.push(`Warning: long term liabilities data are missing for ${allData.symbol}`)
    }

    let ltl_5y_fcf: number|undefined
    if(free_cashflow_avg_5y != undefined && ltl_latest != undefined){
      ltl_5y_fcf = ltl_latest / free_cashflow_avg_5y
      data_info.push(this.createComputedDbData(allData.symbol, 'ltl_5y_fcf', ltl_5y_fcf))
      res = ltl_5y_fcf < 5 ? true : false
      value = `${formatNumber(ltl_5y_fcf, this.locale)}`
    } else {
      warnings.push(`Warning: long term liabilities or free cashflow data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "6",
      result: res,
      short_description: "Long Term Liabilities / 5y Free Cashflow < 5",
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar7(allData: AllSymbolData): PillarData {
    /*
      Return True is last year fcf is greater than 5y fcf
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let free_cashflow_data = getFidFromAllData(allData, FinancialData.ID.FREE_CASHFLOW)
    if(free_cashflow_data != null && free_cashflow_data.length > 0){
      free_cashflow_data = free_cashflow_data.slice(0, 5)
      if(free_cashflow_data.length != 5){
        warnings.push(`Pillar 7 was computed with ${free_cashflow_data.length} years of free cashflow instead of 5 due to missing data`)
      }
      free_cashflow_data.forEach(d => data_info.push(d))

      const fcf_5y_delta = free_cashflow_data[0].value - free_cashflow_data[free_cashflow_data.length-1].value
      data_info.push(this.createComputedDbData(allData.symbol, 'fcf_5y_delta', fcf_5y_delta))

      res = fcf_5y_delta > 0 ? true : false
      value = `${res ? "+ ": " "}${formatCurrency(fcf_5y_delta, this.locale, '$')}`

    } else {
      warnings.push(`Warning: free cashflow data are missing for ${allData.symbol}`)
    }

    return {
      id: "7",
      result: res,
      short_description: "Latest Free Cashflow is higher than 5y ago",
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar8(allData: AllSymbolData): PillarData {
    /*
      Return True if price to 5y_fcf lower than 20
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let latest_share_outstanding = getLatestFidFromAllData(allData, FinancialData.ID.NUMBER_SHARES)?.value
    if(!latest_share_outstanding){
      warnings.push(`Warning: number of shares data are missing for ${allData.symbol}`)
    }
    latest_share_outstanding = latest_share_outstanding ? latest_share_outstanding : 0
    data_info.push(this.createComputedDbData(allData.symbol, 'latest_share_outstanding', latest_share_outstanding))

    let price = getLatestFidFromAllData(allData, FinancialData.ID.STOCK_PRICE)?.value
    if(!price){
      warnings.push(`Warning: stock price data are missing for ${allData.symbol}`)
    }
    price = price ? price : 0
    data_info.push(this.createComputedDbData(allData.symbol, 'price', price))
    
    const market_cap = latest_share_outstanding * price // Computed this way to have daily market cap value
    data_info.push(this.createComputedDbData(allData.symbol, 'market_cap', market_cap))

    let fcf_5y_avg: number|undefined
    let free_cashflow_data = getFidFromAllData(allData, FinancialData.ID.FREE_CASHFLOW)
    if(free_cashflow_data != null && free_cashflow_data.length > 0){
      free_cashflow_data = free_cashflow_data.slice(0, 5)
      if(free_cashflow_data.length != 5){
        warnings.push(`Pillar 8 was computed with ${free_cashflow_data.length} years of free cashflow instead of 5 due to missing data`)
      }
      free_cashflow_data.forEach(d => data_info.push(d))

      fcf_5y_avg = getAvgValueFromDbDataArray(free_cashflow_data)
      data_info.push(this.createComputedDbData(allData.symbol, 'fcf_5y_avg', fcf_5y_avg))
    } else {
      warnings.push(`Warning: free cashflow data are missing for ${allData.symbol}`)
    }
    if(fcf_5y_avg != null){
      const price_to_fcf_5y = market_cap / fcf_5y_avg
      data_info.push(this.createComputedDbData(allData.symbol, 'price_to_fcf_5y', price_to_fcf_5y))
      res = price_to_fcf_5y < 20 ? true : false
      value = `${formatNumber(price_to_fcf_5y, this.locale)}`
    } else {
      warnings.push(`Warning: 5 years of free cashflow data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "8",
      result: res,
      short_description: "Price / 5y_fcf < 20",
      weight: 0.7,
      weight_min_value: 0.7,
      weight_max_value: 0.7,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar9(allData: AllSymbolData): PillarData {
    /*
    Return True if the Graham number valuation is respected
    SQRT(22.5 * EarningsPerShare * BookValuePerShare)
    */
   
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const latestEpsData = getLatestFidFromAllData(allData, FinancialData.ID.NET_INCOME_PER_SHARE)
    data_info.push(latestEpsData!)
    const latest_eps = latestEpsData?.value

    const bookValuePerShareData = getLatestFidFromAllData(allData, FinancialData.ID.BOOK_VALUE_PER_SHARE)
    data_info.push(bookValuePerShareData!)
    const book_value_per_share = bookValuePerShareData?.value
    
    const latestPriceData = getLatestFidFromAllData(allData, FinancialData.ID.STOCK_PRICE)
    data_info.push(latestPriceData!)
    const latest_price = latestPriceData?.value

    if(latest_eps && book_value_per_share && latest_price){
      let graham_number = latest_eps * book_value_per_share * 22.5
      graham_number = graham_number > 0 ? Math.sqrt(graham_number): -1
      data_info.push(this.createComputedDbData(allData.symbol, 'graham_number', graham_number))

      res = latest_price <= graham_number ? true : false
      value = `${formatNumber(graham_number, this.locale)}`
    } else {
      warnings.push(`Warning: Some data are missing to compute Graham's number ${allData.symbol}`)
    }

    return {
      id: "9",
      result: res,
      short_description: "Stock Price <= Graham Number",
      weight: 0.6,
      weight_min_value: 0.6,
      weight_max_value: 0.6,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar10(allData: AllSymbolData): PillarData {
    /*
    Return True if 5xNetIncome < LTL: LTL/NetIncome < 5
    This is checking if net income can cover Long Term debt
    We are taking the avg of 5years net income VS latest debt level
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    // latest_ltl = openbb_buffer.get_latest_long_term_liabilities()
    let ltl_latest:number|undefined
    let ltl_data = getLatestFidFromAllData(allData, FinancialData.ID.TOTAL_NON_CURRENT_LIABILITIES)
    if(ltl_data != null){
      data_info.push(ltl_data)
      ltl_latest = ltl_data.value
    } else {
      warnings.push(`Warning: long term liabilities data are missing for ${allData.symbol}`)
    }

    // avg_5y_net_income = openbb_buffer.get_5y_net_income_avg()
    let net_income_5y_avg: number|undefined
    let net_income_data = getFidFromAllData(allData, FinancialData.ID.NET_INCOME)
    if(net_income_data != null){
      net_income_data = net_income_data.slice(0, 5)
      if(net_income_data.length != 5){
        warnings.push(`Pillar 10 was computed using net income data over ${net_income_data.length} years instead of 5 due to missing data`)
      }
      net_income_data.forEach(d => data_info.push(d))

      net_income_5y_avg = getAvgValueFromDbDataArray(net_income_data)
      data_info.push(this.createComputedDbData(allData.symbol, 'net_income_5y_avg', net_income_5y_avg))
    } else {
      warnings.push(`Net income data are missing for ${allData.symbol}`)
    }

    // ltl_over_net_income = latest_ltl / avg_5y_net_income
    if(ltl_latest != undefined && net_income_5y_avg != undefined){
      const ltl_over_net_income = ltl_latest / net_income_5y_avg
      data_info.push(this.createComputedDbData(allData.symbol, 'ltl_over_net_income', ltl_over_net_income))
      res = (net_income_5y_avg > 0 && ltl_over_net_income < 5) ? true : false
      value = `${formatNumber(ltl_over_net_income, this.locale)}`
    }
    // text = f"Pillar 10: {get_icon(res)}: LTL / (5Y Net Income) < 5 = {ltl_over_net_income}"

    return {
      id: "10",
      result: res,
      short_description: "Long Term liabilities / (5y Net Income) < 5",
      weight: 0.2,
      weight_min_value: 0.2,
      weight_max_value: 0.2,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar11(allData: AllSymbolData): PillarData {
    /*
      Return True if dividend is growing over the last 5 years of at least 5%
    */

    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const threshold = 0.05
    let dividend_data = getFidFromAllData(allData, FinancialData.ID.DIVIDEND)
    if(dividend_data != null){
      dividend_data.forEach(d => data_info.push(d))
      dividend_data = dividend_data.map(x => { 
        return {
          ...x, 
          'year': new Date(x.data_date).getFullYear(),
          'data_date': new Date(x['data_date']) 
        }
      }) // Adding year data as number + converting data_date to Date

      const groupedByYear = groupBy(dividend_data, 'year')
      const divPerYearRaw = (<[string, Array<DbData>][]>Object.entries(groupedByYear)).sort((a, b) => a[0] > b[0] ? -1 : 1) // [Key, Val] sorted from most recent to old
      const divPerYearFiltered = divPerYearRaw.slice(1, divPerYearRaw.length-1)
      const nbDivPerYear = Math.round(divPerYearFiltered.reduce((acc, cur) => acc+cur[0].length, 0)/divPerYearFiltered.length)
      console.log('Dividend per year:', divPerYearRaw)
      const dividendAnnualized = divPerYearRaw.map(x => {
        return [x[0], x[1].reduce((prev, cur) => prev + cur.value, 0)]
      })
      console.log('Dividend annualized: ', dividendAnnualized)
      let startIndex = 0
      if(divPerYearRaw[0][1].length != nbDivPerYear){ // Most recent -> data -> length
        startIndex = 1
      }
      const divData5y = divPerYearRaw.slice(startIndex, startIndex+5)
      if(divData5y.length != 5){
        warnings.push(`Pillar 11 was computed using ${divData5y.length} years instead of 5 due to missing data`)
      }
      const x_axis = divData5y.map(x => parseInt(x[0]))
      const y_axis = divData5y.map(x => x[1].reduce((acc: number, cur: any) => acc+cur.value, 0))
      const [dividend_growth, b] = leastSquares1d(x_axis, y_axis)
      data_info.push(this.createComputedDbData(allData.symbol, 'dividend_growth', dividend_growth))
      res = dividend_growth > threshold ? true : false
      value = `${formatPercent(dividend_growth, this.locale)}`
    } else {
      warnings.push(`Dividend data are missing for ${allData.symbol}`)
    }

    return {
      id: "11",
      result: res,
      short_description: `Dividend growth per year over the last 5Y > ${threshold*100}%`,
      weight: 1.0,
      weight_min_value: 1.0,
      weight_max_value: 1.0,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar12(allData: AllSymbolData): PillarData {
    /*
    Return True if market cap of company is greater than 10 Billions $
    */
    
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const latestMarketCapData = getLatestFidFromAllData(allData, FinancialData.ID.MARKET_CAPITALIZATION)
    const latestMarketCap = latestMarketCapData?.value
    if(latestMarketCap){
      data_info.push(latestMarketCapData)
      res = latestMarketCap > 10e9 ? true : false
      value = `${formatCurrency(latestMarketCap, this.locale, '$')}`
    } else {
      warnings.push(`Market cap data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "12",
      result: res,
      short_description: 'Market capitalization > 10B$',
      weight: 0.8,
      weight_min_value: 0.8,
      weight_max_value: 0.8,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar13(allData: AllSymbolData): PillarData {
    /*
    Return True if Stock Price < DCF Price estimation
    */
    
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const priceData = getLatestFidFromAllData(allData, FinancialData.ID.STOCK_PRICE)
    priceData ? data_info.push(priceData) : null

    const latestPrice = priceData?.value

    const latestDcfPriceData = getLatestFidFromAllData(allData, FinancialData.ID.DCF_VALUATION)
    const latestDcfPrice = latestDcfPriceData?.value
    if(latestDcfPrice && latestPrice){
      data_info.push(latestDcfPriceData)
      res = latestPrice < latestDcfPrice ? true : false
      value = `${formatNumber(Math.round(latestDcfPrice*100)/100, this.locale)}$`
    } else {
      warnings.push(`Market cap data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "13",
      result: res,
      short_description: 'Stock price < Estimated DCF price',
      weight: 0.7,
      weight_min_value: 0.7,
      weight_max_value: 0.7,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar14(allData: AllSymbolData): PillarData {
    /*
      Return True if PER > 3.0
    */
    
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const perData = getLatestFidFromAllData(allData, FinancialData.ID.PER)
    const per = perData?.value
    if(per){
      data_info.push(perData)
      res = per > 3.0 ? true : false
      value = `${formatNumber(per, this.locale)}`
    } else {
      warnings.push(`PER data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "14",
      result: res,
      short_description: 'PER > 3',
      weight: 0.3,
      weight_min_value: 0.3,
      weight_max_value: 0.3,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar15(allData: AllSymbolData): PillarData {
    /*
      Return True if net income is progressing since 3 years
    */
    
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    let net_income_data = getFidFromAllData(allData, FinancialData.ID.NET_INCOME)
    if(net_income_data != null){
      net_income_data = net_income_data.slice(0, 3)
      if(net_income_data.length != 3){
        warnings.push(`Pillar 15 was computed over ${net_income_data.length} years instead of 3 due to missing data`)
      }
      net_income_data.forEach(d => data_info.push(d))

      if(net_income_data[0].value > net_income_data[1].value && net_income_data[1].value > net_income_data[2].value){
        res = true
        value = `Ok`
      } else {
        res = false
        value = `Missed`
      }
    } else {
      warnings.push(`Warning: net income data are missing for ${allData.symbol}`)
    }
    
    return {
      id: "15",
      result: res,
      short_description: 'Net Income is increasing since at least 3 years',
      weight: 0.3,
      weight_min_value: 0.3,
      weight_max_value: 0.3,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar16(allData: AllSymbolData): PillarData {
    /*
      Return True if P/B ratio is lower than 4
    */
    
    let res: boolean|undefined
    let warnings: Array<string> = []
    let value: string|undefined
    let data_info: Array<DbData> = []

    const priceToBookData = getLatestFidFromAllData(allData, FinancialData.ID.PRICE_TO_BOOK_RATIO)
    if(priceToBookData?.value){
      data_info.push(priceToBookData)
      const priceToBook = priceToBookData.value
      res = priceToBook < 4.0 ? true : false
      value = `${formatNumber(priceToBook, this.locale)}`
    } else {
      warnings.push(`Pillar 16 was unable to be computed due to missing data`)
    }
    
    return {
      id: "16",
      result: res,
      short_description: 'Price to Book ratio is lower than 4',
      weight: 0.3,
      weight_min_value: 0.3,
      weight_max_value: 0.3,
      warnings: warnings,
      value: value,
      data_info: data_info
    }
  }

  computePillar17(allData: AllSymbolData): PillarData {
    /*
    Return True if ROIC is greater than 10%
    Indicate that the company if efficient at making cashflow on the money invested 
    */
   let res: boolean|undefined
   let value: string|undefined
   let warnings: Array<string> = []
   let data_info: Array<DbData> = []

    try {
      const latest_roic = getLatestFidFromAllData(allData, FinancialData.ID.ROIC)
      if(latest_roic != null) {
        data_info.push(latest_roic)
        res = latest_roic.value > 0.1 ? true : false
        value = `${formatPercent(latest_roic.value, this.locale)}`
      } else {
        warnings.push(`Warning: ROIC data are missing for ${allData.symbol}`)
      }
    } catch(e) {
      console.error(e)
      warnings.push('Error: Unable to compute pillar')
    }

    return {
      id: "17",
      result: res,
      short_description: "Latest ROIC is greater than 10%",
      weight: 0.7,
      weight_min_value: 0.7,
      weight_max_value: 0.7,
      value: value,
      warnings: warnings,
      data_info: data_info
    }
  }

  computePillar18(allData: AllSymbolData): PillarData {
    /*
    Return True if ROE is greater than 8%
    Indicate if the compqny is efficient at investing shareholder's equity
    */
   let res: boolean|undefined
   let value: string|undefined
   let warnings: Array<string> = []
   let data_info: Array<DbData> = []

    try {
      const latest_roe = getLatestFidFromAllData(allData, FinancialData.ID.RETURN_ON_EQUITY)
      if(latest_roe != null) {
        data_info.push(latest_roe)
        res = latest_roe.value > 0.08 ? true : false
        value = `${formatPercent(latest_roe.value, this.locale)}`
      } else {
        warnings.push(`Warning: ROE data are missing for ${allData.symbol}`)
      }
    } catch(e) {
      console.error(e)
      warnings.push('Error: Unable to compute pillar')
    }

    return {
      id: "18",
      result: res,
      short_description: "Latest ROE is greater than 8%",
      weight: 0.8,
      weight_min_value: 0.8,
      weight_max_value: 0.8,
      value: value,
      warnings: warnings,
      data_info: data_info
    }
  }


  computeVipScore(allResults: Array<PillarData>): number {
    const maxPossibleScore = allResults
    .map(result => result.weight)
    .reduce((acc, cur) => acc+cur, 0)
    
    const currentScore = allResults
    .map(result => result.result ? result.weight : 0)
    .reduce((acc, cur) => acc+cur, 0)

    return (currentScore / maxPossibleScore)*100
  }

  getCompanyName(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.COMPANY_NAME)?.text_value!
  }

  getCompanyDescription(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.COMPANY_DESCRIPTION)?.text_value!
  }

  getCompanyIndustry(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.COMPANY_INDUSTRY)?.text_value!
  }

  getCompanySector(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.COMPANY_SECTOR)?.text_value!
  }

  getCompanyCountry(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.STOCK_COUNTRY)?.text_value!
  }

  getCompanyCurrency(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.STOCK_CURRENCY)?.text_value!
  }

  getStockExchangeExchange(allData: AllSymbolData): string {
    return getLatestFidFromAllData(allData, FinancialData.ID.STOCK_EXCHANGE)?.text_value!
  }
  
  _getChartJsTimeDataFromFid(allData: AllSymbolData, fid: string):ChartJsData  {
    let data = getFidFromAllData(allData, fid)
    if(data){
      data = data.map(x => {
        return {...x, data_date: new Date(x.data_date)}
      })
      data = data.sort((a, b) => a.data_date.getTime() < b.data_date.getTime() ? -1 : 1)
      const labels = data?.map(x => x.data_date)
      const values = data?.map(x => x.value)
      return {labels: labels, values: values}
    } else {
      return {labels: [], values: []}
    }
  }

  getSWOTAnalysis(allPillars: Array<PillarData>): SWOTData {
    // Strenght / Weakness pillars:
    const internalPillarIds = [
      "2", /* ROIC*/
      "3", // Revenue 5Y
      "4", // Net Income
      "6", // LTL vs FCF
      "7", // LT FCF
      "10", // LTL / NetIncome
      "11", // Dividend growth int or ext?
      "15", // NetIncome growth
      "16", // P/B < 4
      "17", // ROIC > 10
    ]
    const externalPillarIds = [
      "1", // PER
      "5", // Share outstanding
      "8", // Price FCF
      "9", // Graham Number
      "12", // Market Cap
      "13", // Price DCF
      "14", // PER > 3.0
      "18", // ROE > 8
    ]

    const strengths: Array<PillarData> = []
    const weaknesses: Array<PillarData> = []
    const opportunities: Array<PillarData> = []
    const threats: Array<PillarData> = []
    
    for(let pData of allPillars){
      if(internalPillarIds.includes(pData.id)){
        if(pData.result == true){
          strengths.push(pData)
        } else if(pData.result == false){
          weaknesses.push(pData)
        } else { console.log(`Error pillar ${pData.id} is not computed`) }
      } else if(externalPillarIds.includes(pData.id)){
        if(pData.result == true){
          opportunities.push(pData)
        } else if(pData.result == false){
          threats.push(pData)
        } else { console.log(`Error pillar ${pData.id} is not computed`) }
      } else {
        console.log(`Could not determine if pillar ${pData.id} is internal or external`)
      }
    }

    return {
      strengths: strengths,
      weaknesses: weaknesses,
      opportunities: opportunities,
      threats: threats,
      n_internal_pillars: internalPillarIds.length,
      n_external_pillars: externalPillarIds.length
    }
  }

  getTextDescription(
    all_data: AllSymbolData,
    pillars: Array<PillarData>,
    technicalPillars: Array<PillarData>,
    tapScoreState: string,
    html = true): string {
    let lineBreak = () => {
      if(html){
        return "<br>"
      } else {
        return "\n"
      }
    }
    let output = ""
    output += `Company ${this.getCompanyName(all_data)} with symbol ${all_data.symbol} is acting
    in the ${this.getCompanySector(all_data)} sector (Industry: ${this.getCompanyIndustry(all_data)}).`

    if(!environment.production){
      output += lineBreak()
      output += "Value Investing Pillars for this company are:" + lineBreak()
      output += pillars.map(pillarData => {
        return `- Pillar ${pillarData.id} with description: ${pillarData.short_description} has the value ${pillarData.value}, the pillar is ${(pillarData.result ? 'respected' : 'not respected')}`
      }).join(lineBreak())

      output += lineBreak() + lineBreak()
      output += "Technical Analysis Pillars for this company are:" + lineBreak()
      output += technicalPillars.map(pillarData => {
        return `- Technical Pillar ${pillarData.id} with description: ${pillarData.short_description} has the weight ${pillarData.weight}, the pillar is ${(pillarData.result ? 'respected' : 'not respected')}`
      }).join(lineBreak())

      // output +=  + "The final result of the technical analysis is: "+tapScoreState + lineBreak()
      if(tapScoreState == 'no_buy'){
        output += lineBreak() + "Based on the Technical Analysis Pillars, we recommend to wait before buying." + lineBreak()
      }
      if(tapScoreState == 'buy'){
        output += lineBreak() + "Based on the Technical Analysis Pillars, it seems to be the right time to buy." + lineBreak()
      }
      if(tapScoreState== 'no_decision'){
        output += lineBreak() + "Based on the Technical Analysis Pillars, we recommand waiting for clearer signals." + lineBreak()
      }

      output += lineBreak() + lineBreak() + `The URL for this company is: https://neotradr.com/company/${convert_int_to_ext_symbol(all_data.symbol)}`
    }

    return output
  }

}
