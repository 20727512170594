<div class="container text-center">
    <div class="row">
        <div class="col-12">
            <h1>404</h1>
            <h2>Page not found</h2>
            <p>The page you are looking for might have been removed, had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" mat-raised-button>Go to Home</a>
            <br>
            <br>
            <a routerLink="/dashboard" mat-raised-button>or Dashboard</a>
            <br>
            <br>
            <app-explore-more></app-explore-more>
    </div>
</div>
