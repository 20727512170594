// dcf-calculator.component.ts

import { Component, Input, SimpleChanges } from '@angular/core';
import { DbData } from 'src/app/core/common_types';


interface Assumptions {
  revGrowth: {
    low: number;
    medium: number;
    high: number;
    name: string;
  };
  // profitMargin: {
  //   low: number;
  //   medium: number;
  //   high: number;
  //   name: string;
  // };
  // fcfMargin: {
  //   low: number;
  //   medium: number;
  //   high: number;
  //   name: string;
  // };
  P_E: {
    low: number;
    medium: number;
    high: number;
    name: string;
  };
  P_FCF: {
    low: number;
    medium: number;
    high: number;
    name: string;
  };
}

interface StockPrices {
  low: number;
  medium: number;
  high: number;
}

@Component({
  selector: 'app-dcf-calculator',
  templateUrl: './dcf-calculator.component.html',
  styleUrls: ['./dcf-calculator.component.scss']
})
export class DcfCalculatorComponent {
  Math = Math;
  @Input() freeCashflow: number = 0;
  @Input() sharesOutstanding: number = 0;
  @Input() stockPrice: number = 0;
  @Input() netIncome: number = 0;

  @Input() set priceEarning(value: number) {
    const percentage = value * 0.1;
    this.assumptions.P_E = {
      low: Math.floor(value - percentage),
      medium: Math.ceil(value),
      high: Math.ceil(value + percentage),
      name: 'P/E'
    };
  }

  @Input() set priceFreeCashflow(value: number) {
    const percentage = value * 0.1;
    this.assumptions.P_FCF = {
      low: Math.floor(value - percentage),
      medium: Math.ceil(value),
      high: Math.ceil(value + percentage),
      name: 'P/FCF'
    };
  }

  assumptions: Assumptions = {
    revGrowth: {
      low: 0.08, medium: 0.1, high: 0.12, name: 'Revenue Growth'
    },
    // profitMargin: {
    //   low: 0, medium: 0, high: 0, name: 'Profit Margin'
    // },
    // fcfMargin: {
    //   low: 0, medium: 0, high: 0, name: 'FCF Margin'
    // },
    P_E: {
      low: 0, medium: 0, high: 0, name: 'P/E'
    },
    P_FCF: {
      low: 0, medium: 0, high: 0, name: 'P/FCF'
    }
  };
  selectedYear = 5;

  stockPrices: StockPrices = {
    low: 0,
    medium: 0,
    high: 0
  };

  stockPrices2: StockPrices = {
    low: 0,
    medium: 0,
    high: 0
  };

  stockPricePE: StockPrices = {
    low: 0,
    medium: 0,
    high: 0
  };

  desiredAnnualReturn = 0.10;  // Desired annual return (10%)
  terminalGrowthRate = 0.03;   // Terminal growth rate (3%)

  ngOnChanges(changes: SimpleChanges) {
    this.calculateStockPrices();
  }

  calculateStockPrices() {
    this.stockPrices.low = this.calculateStockPrice(this.assumptions,'low');
    this.stockPrices.medium = this.calculateStockPrice(this.assumptions,'medium');
    this.stockPrices.high = this.calculateStockPrice(this.assumptions,'high');

    this.stockPrices2.low = this.calculateStockPrice2(this.assumptions,'low');
    this.stockPrices2.medium = this.calculateStockPrice2(this.assumptions,'medium');
    this.stockPrices2.high = this.calculateStockPrice2(this.assumptions,'high');

    this.stockPricePE.low = this.calculateStockPricePE(this.assumptions,'low');
    this.stockPricePE.medium = this.calculateStockPricePE(this.assumptions,'medium');
    this.stockPricePE.high = this.calculateStockPricePE(this.assumptions,'high');
  }

  calculateStockPrice(assumptions: Assumptions, on: 'low'|'medium'|'high'): number {
    if(this.freeCashflow === 0 || this.sharesOutstanding === 0) {
      return 0;
    }
    // const fcf = this.calculateFCF(this.freeCashflow, assumptions.profitMargin[on], assumptions.fcfMargin[on]);
    const fcf = this.freeCashflow
    const terminalValue = this.calculateTerminalValue(fcf * (1 + assumptions.revGrowth[on])**this.selectedYear, this.terminalGrowthRate, this.desiredAnnualReturn);
    const presentValueFCF = this.calculatePresentValue(fcf, this.desiredAnnualReturn, this.selectedYear);
    const presentValueTerminal = this.calculatePresentValue(terminalValue, this.desiredAnnualReturn, this.selectedYear);
    const equityValue = presentValueFCF + presentValueTerminal;
    return equityValue / this.sharesOutstanding;
  }

  calculateStockPrice2(assumptions: Assumptions, on: 'low'|'medium'|'high'): number {
    // const operatingIncome = this.freeCashflow * (1 - assumptions.profitMargin[on]);
    // const investedCapital = operatingIncome / assumptions.ROIC[on];
    // const depreciation = /* calculate depreciation */;
    // const taxes = /* calculate taxes */;
    // const fcf = operatingIncome - depreciation - taxes;
  
    const terminalFCF = this.freeCashflow * (1 + assumptions.revGrowth[on])**this.selectedYear; // Assuming constant growth
    const terminalEquityValue = terminalFCF * assumptions.P_FCF[on];
  
    // Present value of FCF
    const presentValueFCF = this.calculatePresentValue(this.freeCashflow, this.desiredAnnualReturn, this.selectedYear);
    // Present value of terminal equity value
    const presentValueTerminal = this.calculatePresentValue(terminalEquityValue, this.desiredAnnualReturn, this.selectedYear);
    // Equity value
    const equityValue = presentValueFCF + presentValueTerminal;
    
    return equityValue / this.sharesOutstanding;
  }

  calculateStockPricePE(assumptions: Assumptions, on: 'low'|'medium'|'high'): number {
    if(this.netIncome === 0 || this.sharesOutstanding === 0) {
      return 0;
    }
    const earnings = this.netIncome;
    const price = earnings * assumptions.P_E[on] / this.sharesOutstanding;
    return price;
  }
  

  calculatePresentValue(value: number, discount_rate: number, years: number) {
    return value / (1 + discount_rate) ** years;
  }

  calculateFCF(revenue: number, profitMargin: number, fcfMargin: number): number {
    return revenue * (1 - profitMargin) * (1 - fcfMargin);
  }

  calculateTerminalValue(fcfYear5: number, terminalGrowthRate: number, desiredAnnualReturn: number): number {
    return fcfYear5 * (1 + terminalGrowthRate) / (desiredAnnualReturn - terminalGrowthRate);
  }

  getGradient(dcfPrice: number, stockPrice: number): string {
    const ratio = Math.min(Math.max(dcfPrice / stockPrice, 0), 1);
    return `linear-gradient(to right, green ${ratio * 100}%, red ${ratio * 100}%)`;
  }

  updatePercent(value: string, assumption: any) {
    const numericValue = parseFloat(value.replace('%', '')) / 100;
    assumption.value.low = isNaN(numericValue) ? 0 : numericValue;
  }
}
