<div class="text-center mt-4">
    <h1>Unsubscribe Page</h1>

    <div class="mat-card">
        <h2>We are sad to see you leave</h2>
        <p>If you wish to unsubscribe from our service, 
            please send an email to <a href="mailto:contact@neotradr.com">contact&#64;neotradr.com</a>
            with <b>Unsubscribe</b> as the mail object and specify your email adress inside.</p>
    </div>

    <button mat-raised-button color="warn"><a href="mailto:contact@neotradr.com">Unsubscribe</a></button>
</div>
