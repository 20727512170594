import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss']
})
export class QaComponent {
  @Input() displayCategory: string = ""

  qaList:Array<{question:string, answer: string, categories: Array<string>}> = [
    {
      question: 'What is NeoTradr?',
      answer: "NeoTradr is an value investing analysis platform. \
      It analyzes hundreds of companies reports, financial statements and data to provide \
      you an understanding of the value of companies.",
      categories: ['general']
    },
    {
      question: 'Is NeoTradr using technical analysis?',
      answer: "NeoTradr is focused on Value Investing analysis but we are also using Technical Analysis \
      to provide timing information. We think that: a company can have value but it may not be the \
      right time to invest in it. That's why we are using technical analysis.",
      categories: ['general']
    },
    {
      question: 'Is NeoTradr using AI?',
      answer: 'Yes and No. We are not using AI as we imagine today to write content like ChatGPT. \
      We are using AI to do curve fitting (used in certain stock analysis), to group \
      news together, to detect sentiment in news. We are however investigating deeper into LLM \
      for text summarization and report reading.',
      categories: ['general']
    },
    {
      question: 'How often are the data updated on NeoTradr?',
      answer: "We are refreshing data every 6 to 24 hours. NeoTradr is a long term analysis platform. \
      We do not care about shorter time frames. We are not providing trading insights but investment \
      analysis.",
      categories: ['general', 'technical']
    },
    {
      question: "I don't find the company I am looking for, what can I do?",
      answer: "The company may not be publicly traded, as a consequence, we do not have the \
      data to analyze it. Or the company may not be added yet to our list, please feel free \
      to send a mail to contact@neotradr.com, we will do our best!",
      categories: ['help', 'issue']
    },
    {
      question: "Where are the data coming from?",
      answer: 'We are using publicly available data sources for NeoTradr. We use free as well as \
      paying API to have a complete view of the companies.',
      categories: ['general', 'help']
    },
    {
      question: 'How to add companies to favorite?',
      answer: 'You can just click on the star close to company name on top of any company page.',
      categories: ['help']
    }
  ];


  filteredQaList:Array<{question:string, answer: string, categories: Array<string>}> = [];
  searchQuery = '';


  constructor() {
  }

  ngOnInit(): void {
    this.filterQaList()
  }

  filterQaList() {
    this.filteredQaList = this.qaList.filter(qa => {
      if(this.displayCategory != "" && !qa.categories.includes(this.displayCategory)){
        return false
      }
      return qa.question.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
      qa.answer.toLowerCase().includes(this.searchQuery.toLowerCase())
    });
  }

}
