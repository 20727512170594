<div class="container">
    <div class="nt-primary my-4" style="font-family: Inter; font-size: 48px; font-weight: 800; line-height: 1.2;">
        Blog
    </div>
    <div class="row" *ngFor="let blog of blog_data; let i = index">
        <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="i % 2 != 0">
            <a [href]="'/company/'+blog.symbol"><img [src]="blog.img_url" style="width: 100%;"></a>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12">
            <div class="nt-primary mt-4" style="font-family: Inter; font-size: 28px; font-weight: 800; line-height: 1.2;">
                {{blog.title}}
            </div>
            <div class="mb-4" style="font-family: Inter; color: grey; font-size: 18px; font-weight: 800; line-height: 1.2;">
                {{blog.published_date | date}}
            </div>
            <div>
                <p [innerHTML]=""></p>
                <p *ngFor="let paragraph of blog.content" [innerHTML]="paragraph"></p>
            </div>
            <div class="text-center">
                Follow us on: 
                <a href="https://www.facebook.com/NeoTradr" class="social-icon facebook-icon" target="_blank">
                    <i class="fab fa-facebook"></i>
                </a>
                <a href="https://www.linkedin.com/company/neotradr" class="social-icon linkedin-icon" target="_blank">
                    <i class="fab fa-linkedin"></i>
                </a>
                <a href="https://neotradr.wordpress.com/" class="social-icon wordpress-icon" target="_blank">
                    <i class="fab fa-wordpress"></i>
                </a>
            </div>
            <div class="text-center mb-5 mt-2">
                <a mat-raised-button color="primary" class="mx-auto" [href]="'/company/'+blog.symbol">
                    Go to company page
                </a>
            </div>
        </div>
        <div class="col-md-6 col-lg-6 col-sm-12" *ngIf="i % 2 == 0">
            <a [href]="'/company/'+blog.symbol"><img [src]="blog.img_url" style="width: 100%;"></a>
        </div>
        <hr>
        <div class="mt-5"></div>
    </div>
</div>
<app-footer></app-footer>
