import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PillarData, PillarsService } from 'src/app/core/pillars.service';
import { getShortTextForPillar } from '../pillars-textual-strength/pillars-textual-strength.component';



@Component({
  selector: 'app-pillars-textual-threat',
  templateUrl: './pillars-textual-threat.component.html',
  styleUrls: ['./pillars-textual-threat.component.scss']
})
export class PillarsTextualThreatComponent {
  threatList: Array<string|null> = []
  percentage: number = 0
  @Input() pillars: Array<PillarData> = []
  @Input() use_strong?: boolean = true;
  @Input() limit?: number = 0;
  @Input() short?: boolean = false;
  @Output() shortChange = new EventEmitter<boolean>();

  constructor(private pillarsService: PillarsService){}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.computeRisk()
  }

  changeShort(): void {
    this.short = !this.short
    this.shortChange.emit(this.short)
  }

  computeRisk(): void {
    const swot = this.pillarsService.getSWOTAnalysis(this.pillars)
    this.percentage = (swot.threats.length / swot.n_external_pillars)*100
    let threats = swot.threats
    if(this.limit){
      threats = threats.slice(0, this.limit)
    }
    this.threatList = threats.map(x => getShortTextForPillar(x.id, !!x.result))
    // this.strengthList = strengths.map(x => getTextStrengthAssessmentForPillar(x, this.use_strong, this.short))
  }
}
