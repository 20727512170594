<!-- News Ticker displaying news title as ribbon -->
<div class="ticker-container">
    <div class="ticker-wrapper">
        <div class="ticker-transition">
            <div class="ticker-item" *ngFor="let group_of_news of grouped_news">
                <mat-icon style="position: relative; top: 5px">article</mat-icon>
                <a href="{{group_of_news.articles_data[0].article.url}}">
                    {{group_of_news.articles_data[0].article.title}}
                </a>
            </div>
        </div>
    </div>
</div>