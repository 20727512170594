import { Component, OnInit } from '@angular/core';
import { GroupedNewsGroup, IntelligenceService } from '../../core/intelligence.service';
import { flatMap } from 'rxjs';
import { NavigationService } from '../../navigation.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {
  isLoading: boolean = true
  short: boolean = false
  grouped_news: Array<GroupedNewsGroup> = [];

  constructor(
    private intelligence: IntelligenceService,
    private navigation: NavigationService) { }

  ngOnInit(): void {
    this.isLoading = true
    this.intelligence.getGroupedNews().subscribe(grouped_news_result => {
      try {
        if(grouped_news_result && grouped_news_result.groups){
          this.grouped_news = grouped_news_result.groups
          this.grouped_news.forEach(group => {
            group.articles_data.sort((a, b) => {
              return a.article.datetime > b.article.datetime ? -1 : 1
            })
          });

          this.grouped_news.sort((a, b) => {
            return a.articles_data[0].article.datetime > b.articles_data[0].article.datetime ? -1 : 1
          })
          this.isLoading = false
        } else {
          this.grouped_news = []
          this.isLoading = false
        }
      } catch(err) {
        console.warn('Error when sorting data: ', err);
      }
    })
  }

  back(): void {
    this.navigation.back()
  }
}
