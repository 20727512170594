<div class="header-sections z-4">
    <section>
        <div class="container">
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="mt-2 mb-1">
                        <div class="nt-title">
                            <!-- <span>Become a smarter investor,</span>
                            <br>
                            <font class="nt-accent">Be a value investor</font> -->
                            <span>Become a Smarter Investor</span>
                            <font class="nt-title-accent"> with Data-Driven Insights</font>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="row py-0 my-0 justify-content-center">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                    <div class="mt-2 mb-1">
                        <div class="nt-title-muted">
                            <!-- Get the best value stock you can buy right now at a wonderful price  -->
                            <!-- Become a smarter investor, Be a value investor -->
                            <!-- We Help You Analyze Companies with Value in a click -->
                            <!-- Unlock Quick Insights into Valuable Investments -->
                            Let us crunch numbers and provide you with the best insights to make the right investment decisions
                            <!-- We crunch numbers to let you make the right investments -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<section>
    <div class="row mx-0">
        <div class="col-12 px-0 mx-0">
            <app-news-ticker></app-news-ticker>
        </div>
    </div>
</section>

<section class="my-2">
    <div class="container">
        <div class="row justify-content-center text-center">
            <app-search-bar></app-search-bar>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="mat-elevation-z8">
                    <mat-card-header>
                        <mat-card-title class="nt-subtitle"><a href="/discover">Top value investing picks</a></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="px-0 pb-0">
                        <app-highest-score-ranking [limit]="11"></app-highest-score-ranking>        
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <mat-card class="mat-elevation-z8">
                    <mat-card-header>
                        <mat-card-title class="nt-subtitle">Most Popular companies</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <app-most-analyzed-companies [limit]="5"></app-most-analyzed-companies>    
                    </mat-card-content>
                </mat-card>

                <div>
                    <div class="mt-4 nt-subtitle text-center">
                        <a href="/news" class="nt-primary">Latest News</a>
                    </div>
                    <app-main-news [useCarousel]="false" [n_data]="3" [short]="true" [title_only]="true"></app-main-news>
                    <!-- A text "More news" with an arrow link to /news -->
                     <div class="mb-3 nt-primary text-center">
                        <a href="/news" class="nt-primary">
                            More news
                            <mat-icon style="position:relative; top: 6px;">arrow_forward</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="header-sections">
    <section> <!-- User oriented section-->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="mt-4 mb-2 nt-subtitle text-center">
                        <!-- Analyze your companies! -->
                        <!-- Empower Your Investment Decisions! -->
                        Ready to Start? Analyze your first company
                    </div>
                </div>
            </div>
            <div class="row justify-content-center text-center">
                <app-explore-more [nbButtons]="4" title=""></app-explore-more>
            </div>
    
            <div class="row justify-content-center text-center">
                <div class="mx-3 mt-1" style="float: left;">
                    <!-- <app-login-required [isLoggedIn]="isLoggedIn">
                        <a
                            mat-raised-button 
                            color="primary" href="/dashboard"
                            [disabled]="this.isLoggedIn ? false : true">
                            <div class="cta-big-text">
                                <mat-icon>dashboard</mat-icon>
                                <span class="aligned-with-icon">Explore Your Dashboard</span>
                            </div>
                        </a>
                    </app-login-required> -->
    
                    <a class="m-3" mat-raised-button color="primary" href="/discover">
                        <div class="cta-big-text">
                            <mat-icon>stars</mat-icon>
                            <span class="aligned-with-icon">Discover Top Picks</span>
                        </div>
                    </a>
    
                    <a class="m-3" mat-raised-button color="primary" href="{{regionCustomization.index.link}}">
                        <div class="cta-big-text">
                            <mat-icon>bar_chart</mat-icon>
                            <span class="aligned-with-icon">{{regionCustomization.index.description}}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>    
</div>

<section>
    <div class="pb-2 bg-nt-primary">
        <div class="container">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                    <div class="mt-4 nt-title text-center" style="color: rgb(255, 255, 255);">
                        Invest in qualitative and well valued companies
                    </div>
                </div>
                <div class="col-2"></div>
            </div>

            <div class="my-4"></div>
            
            <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-2">
                    <div class="product-description-medium-white">
                        <!-- We are providing automated analysis of companies to help you quickly see the <b>value</b> -->
                        Access our automated and data-driven analysis tool for fast and 
                        comprehensive valuation insights.
                        <br>We are investors not traders, we are not
                        selling trading signals, but we are giving you all the tools and data you 
                        need to make the best decisions for your long-term investments.

                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 my-2">
                    <div class="product-description-medium-white">
                        <!-- We give you high level as well as detailled view of companies to determine if the current <b>price</b> is worth the value you'll get -->
                        Gain both a high-level overview and in-depth analysis of 
                        companies to assess if the current market price aligns with their 
                        intrinsic <b>value</b>. 
                    </div>
                </div>
                <div class="col-xl-4 col-lg4 col-md-4 col-sm-12 col-xs-12 my-2">
                    <div class="product-description-medium-white">
                        <!-- We know that a company may have value but it may not be the correct time to buy.
                        We are also analysing the current market situation with technical analysis to provide 
                        <b>timing insights</b> on your investments. -->
                        Recognizing that a company holds value is one aspect; timing the investment 
                        optimally is another. Our analysis incorporates technical analysis of the 
                        current market scenario, offering <b>strategic timing</b> insights for 
                        your investments.

                    </div>
                </div>
                <div class="col-12 my-4 text-center">
                    <div *ngIf="!this.isLoggedIn" class="">
                        <app-login class="" mat-raised-button color="accent">
                            <div class="cta-big-text">Login or Sign up</div>
                        </app-login>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="middle-sections">
    <section class="pt-5">
        <div class="container">
            <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12" style="z-index: 10">
                    <div>
                        <div class="nt-primary" style="font-family: Inter; font-size: 16px; font-weight: 600; letter-spacing: 1px; line-height: 1.4; word-break: break-word;">
                            Benefits
                        </div>
                        <div class="nt-title" style="overflow: visible;">
                            <!-- How can we help? -->
                            Discover the NeoTradr Advantage
                        </div>
                        <div style="margin-top: 9px;">
                            <div class="nt-title-muted">
                                NeoTradr has been built to be simple and efficient
                            </div>
                        </div>
                        <div class="my-4">
                            <div class="sol-icon" style="float: left;">
                                <div class="sol-inner-icon">
                                    <i class="fa fa-bed"></i>
                                </div>
                            </div>
                            <div class="" style="overflow-x: overlay; margin-left: 80px;">
                                <div class="sol-text-title nt-primary">
                                    <!-- Simple -->
                                    Simple but powerful analysis
                                </div>
                                <div class="product-text-description">
                                    <!-- Use Value Scores and simple widgets to know if a company is a good investment or not -->
                                    Leverage Value Scores, intuitive widgets and graphs to assess if a company is a wise investment.
                                </div>
                            </div>
                        </div>
                        <div class="my-4">
                            <div class="sol-icon" style="float: left;">
                                <div class="sol-inner-icon">
                                    <i class="fa fa-brain"></i>
                                </div>
                            </div>
                            <div class="" style="overflow-x: overlay; margin-left: 80px;">
                                <div class="sol-text-title nt-primary">
                                    Data-driven solutions
                                </div>
                                <div class="product-text-description">
                                    We are using data to compute <i>Value Investing Pillars</i> to know if the price of a company align with its revenue, cashflow, debt,...
                                </div>
                            </div>
                        </div>
                        <div class="my-4">
                            <div class="sol-icon" style="float: left;">
                                <div class="sol-inner-icon">
                                    <i class="fa fa-clock"></i>
                                </div>
                            </div>
                            <div class="" style="overflow-x: overlay; margin-left: 80px;">
                                <div class="sol-text-title nt-primary">
                                    Save hours of research
                                </div>
                                <div class="product-text-description">
                                    <!-- We analyze company numbers and entire indices at light speed to provide you useful insights -->
                                    Simply analyze companies and entire indices with highlighted strenghts and weaknesses to save research time.
                                </div>
                            </div>
                        </div>
                        <div class="my-4" style="width: 100%;">
                            <div class="text-center">
                                <div *ngIf="!this.isLoggedIn" class="">
                                    <app-login class="" mat-raised-button color="accent">
                                        <div class="cta-big-text">Login or Sign up</div>
                                    </app-login>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <div class="side-image" style="height: 389px; width: 100%;">
                        <picture>
                            <source srcset="../../assets/img/header_feature_card.webp" type="image/webp">
                            <source srcset="../../assets/img/header_feature_card.png" type="image/png"> 
                            <img style="width: 100%;" ngSrc="../../assets/img/header_feature_card.png" priority alt="Header picture with widgets of NeoTradr">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section>
        <div class="container">
            <div class="row my-5">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 d-flex justify-content-center">
                    <div class="side-image" style="height: 389px; width: 100%;">
                        <picture>
                            <source srcset="../../assets/img/few_features.webp" type="image/webp">
                            <source srcset="../../assets/img/few_features.png" type="image/png"> 
                            <img ngSrc="../../assets/img/few_features.png" priority alt="Picture of pillars" style="position: relative; box-sizing: border-box; z-index: 3; height: 100%; left: 0px; top: 0px; background-repeat: no-repeat; border-radius: 5px;">
                        </picture>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12" style="z-index: 10">
                    <div>
                        <div class="nt-primary" style="font-family: Inter; font-size: 16px; font-weight: 600; letter-spacing: 1px; line-height: 1.4;  word-break: break-word;">
                            Features
                        </div>
                        <div class="nt-title">
                            <!-- Features you'll love -->
                            Experience Our Powerful Features
                        </div>
                        <div style="margin-top: 9px; ">
                            <div class="" style="left: 20px;">
                                <div class="nt-title-muted">
                                    Quick and easy features to discover and analyze hundreds of companies
                                </div>
                            </div>
                        </div>
                        
                        <div class="my-3">
                            <div class="">
                                <div class="sol-icon">
                                    <div class="sol-inner-icon">
                                        <i class="fa fa-pencil-alt"></i>
                                    </div>
                                </div>
                                <div class="sol-text">
                                    <div class="sol-text-title nt-primary">
                                        Get Value Investing Analysis
                                    </div>
                                    <div class="product-text-description">
                                        With our VIP (Value Investing Pillars) analysis you have a view of company strengths and weaknesses for 
                                        long term investment objectives.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="my-3">
                            <div class="">
                                <div class="sol-icon">
                                    <div class="sol-inner-icon">
                                        <i class="fa fa-chart-line"></i>
                                    </div>
                                </div>
                                <div class="sol-text">
                                    <div class="sol-text-title nt-primary">
                                        Compare companies together
                                    </div>
                                    <div class="product-text-description">
                                        We are providing Value Investing scores and pillars, SWOT analysis as well as detailed charts 
                                        to let you compare 2 companies side by side.
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="my-3">
                            <div class="">
                                <div class="sol-icon">
                                    <div class="sol-inner-icon">
                                        <i class="fa fa-sync-alt"></i>
                                    </div>
                                </div>
                                <div class="sol-text">
                                    <div class="sol-text-title nt-primary">
                                        Feeling more short term?
                                    </div>
                                    <div class="product-text-description">
                                        We developped TAP (Technical Analysis Pillars) to let you analyze the momentum of a company to enter a the best time and aim shorter investment objectives.
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <div class="text-center">
                                <div *ngIf="!this.isLoggedIn" class="">
                                    <app-login class="" mat-raised-button color="accent">
                                        <div class="cta-big-text">Login or Sign up</div>
                                    </app-login>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
</div>

<div class="my-5"></div>

<section>
    <div class="pb-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title class="nt-subtitle">
                                What features will you get by signing up?
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <ul style="list-style-type: none; padding-left: 20px;">
                                <li *ngFor="let feature of this.productDescription[0].featureList" style="font-size: 16px; line-height: 1.6; margin-bottom: 10px;">
                                    ✅ {{feature}}
                                </li>
                            </ul>
                        </mat-card-content>
                        <mat-card-content>
                            <div class="text-center">
                                <a mat-raised-button color="accent" [routerLink]="['/subscribe']">
                                    <div class="cta-big-text">Product page</div>
                                </a>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title class="nt-subtitle">
                                What features will you get with Premium?
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <ul style="list-style-type: none; padding-left: 20px;">
                                <li *ngFor="let feature of this.productDescription[1].featureList" style="font-size: 16px; line-height: 1.6; margin-bottom: 10px;">
                                    ✅ {{feature}}
                                </li>
                            </ul>
                        </mat-card-content>
                        <mat-card-content>
                            <div class="text-center">
                                <a mat-raised-button color="accent" [routerLink]="['/subscribe']">
                                    <div class="cta-big-text">Product page</div>
                                </a>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="my-5"></div>


<section>
    <div class="pb-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-8">
                    <div class="mt-4 nt-title text-center">
                        <!-- Our happy clients! -->
                        Hear What Our Clients Say
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="carousel-container">
                    <button mat-icon-button (click)="scrollCarousel(-1)">
                      <mat-icon>chevron_left</mat-icon>
                    </button>

                    <div class="feedback-list">
                        <div [class]="isXSmallScreen?'feedback-card-small' :'feedback-card'" *ngFor="let feedback of carouselItems">
                          <mat-card>
                            <div class="avatar-container">
                                <picture>
                                    <source [srcset]="feedback.avatarUrlWebp" type="image/webp">
                                    <source [srcset]="feedback.avatarUrl" type="image/png"> 
                                    <img [src]="feedback.avatarUrl" priority alt="Customer Avatar" class="avatar">
                                </picture>

                                <!-- <img class="avatar" [src]="feedback.avatarUrl" alt="Customer Avatar"/> -->
                            </div>
                            <mat-card-content>
                              <blockquote>
                                <p>"{{ feedback.feedbackText }}"</p>
                            </blockquote>
                            <footer><cite><p class="customer-name">{{ feedback.name }}</p></cite></footer>
                            </mat-card-content>
                          </mat-card>
                        </div>
                    </div>
                    
                    <button mat-icon-button (click)="scrollCarousel(1)">
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="my-5"></div>

<div class="bottom-section pb-5 pt-2">
    <section>
        <div class="container">
            <div class="mt-4 nt-title text-center">
                <!-- Q&A -->
                Frequently Asked Questions
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-8 col-xs-12 col-sm-12">
                    <app-qa displayCategory="general"></app-qa>
                </div>
            </div>
        </div>
    </section>    
</div>

<!-- <div class="my-5"></div> -->

<section class="bg-nt-primary">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="row align-items-center" style="border-radius: 5px; height: 268px;">
                    <div class="col-8 text-center mx-auto">
                        <div *ngIf="!this.isLoggedIn" class="">
                            <app-login class="" mat-raised-button color="accent">
                                <div class="cta-big-text">Get Started - Sign in or Sign up</div>
                            </app-login>
                        </div>
                        <div *ngIf="this.isLoggedIn">
                            <app-explore-more title=""></app-explore-more>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="my-5">
    <app-explore-more title="Ready to Start? Analyze your first company"></app-explore-more>
</section>

<div class="my-5"></div>

<footer style="background-color: rgb(244, 245, 247); height: 222px;">
    <app-footer></app-footer>
</footer>
