<div>
    <div *ngFor="let risk of this.riskList">
        <mat-icon class="warning">warning</mat-icon><span [innerHTML]="risk"></span>
    </div>
    <div *ngIf="this.riskList.length == 0">
        No major risk found
    </div>
    <div *ngIf="this.limit && initialNumberOfRisks > 0 && this.limit < initialNumberOfRisks">
        ({{initialNumberOfRisks - this.limit}} more risks to see)
    </div>
</div>
