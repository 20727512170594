import { Component } from '@angular/core';

interface BlogData {
  id: string,
  title: string,
  symbol: string,
  published_date: Date,
  content: Array<string>,
  img_url: string
}

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent {
  blog_data: Array<BlogData> = [
    {
      id: '16',
      published_date: new Date(2024, 0, 18),
      title: 'Hello investors, today let’s analyse Caterpillar Inc (CAT)!',
      symbol: 'CAT',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/3a219407-7dac-49a5-b4cf-9f643efc6004-28698-00001bbeb25bd71a_file.jpg',
      content: [
        "Hello investors, today let’s analyse Caterpillar Inc (CAT)!",
        "The company based in Irving, Texas, manufactures and sells construction equipment, mining machinery, engines, turbines, locomotives, and offers financial products and services globally since its founding in 1925.",
        "Let’s dig into the stock pros and cons with NeoTradr Pillars analysis.",
        "The PER is a bit high but not surprising for a known company like CAT. The ROIC is good at 11% but its growth a bit low at only 2% per year. All the pillars related to revenue, net income and free cashflow are good ✅.",
        "The warnings ⚠️ we have here are around the current price of the company that seems expensive. The price compared to free cashflow is a bit high, the price compared to Graham number and our DCF analysis seems expensive.",
        "One good point of this company for long term investors is the increasing dividend over time and the good growth of it (29% over the last 5 years)",
        "Is it a good time to jump in the company right now?",
        "Maybe not, even is the long term trend is positive (and very long term, very positive!), today may not be the right time to buy the stock. The current price is trading lower than the long term trend, the RSI is around the neutrality zone and slightly trending down. There may be a correction coming on the stock. But looking at the long term trend, the correction may not be huge.",
        "It can be a good idea to enter with a DCA strategy if you believe in the company and have a long term vision.",
        "Don’t forget that this is not a buy/sell signal for you. You are the only one responsible for the investments you make (and the gain or loss you’ll have). Investors are responsible people. NeoTradr is here to crunch numbers and give you insights, not to tell you to buy or sell immediately.",
        "You can submit us the next company you want to be analysed! Keep investing and see you next time",
        "#ValueInvesting #NeoTradr",
      ]
    },
    {
      id: '15',
      published_date: new Date(2024, 0, 10),
      title: 'Today we are going to compare the 2 biggest credit card providers in the world',
      symbol: 'V',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/img_1762-1.png',
      content: [
        "Good morning to all Value Investors,",
        "Today we are going to compare the 2 biggest credit card providers in the world, Visa (https://neotradr.com/company/V) and Mastercard (https://neotradr.com/company/MA)",
        "The two companies looks very similar, their VIP score are respectively 69/100 and 61/100. They both offer good metrics, good Strengths, same Weakness on the ROIC growth (lower than we expect), globally same opportunities except that Visa is buying back its own shares (very good for shareholders value) and finally same Threats indicating an expensive price.",
        "We can think that Visa is clearly better and that’s it, but let’s dig deeper into the numbers. On the valuation side, Visa wins: the PER is much lower (31 vs 60), the P/B the same way (14 vs 94) and our DCF analysis say that Visa is 2 times more expensive than the DCF value compared to 4 times for Mastercard! Definitely Mastercard seems over priced compared to Visa.",
        "But let’s focus on the Return the shareholders are getting, on this aspect Mastercard is winning: the ROIC is at 26% compared to 19% for Visa and the return on equity is at 170%! Compared to Visa’s 46%. We can see that Mastercard is much more efficient to generate value from shareholder’s money. We understand now why its price may be more expensive than its direct competitor.",
        "Finally on a purely technical point of view, we see than Mastercard has the “Wait” status while Visa has the “Buy” status. Why? On the charts we can see than Mastercard seems to reverse slowly with a current price lower than long term moving average and a ROIC decreasing and below the average. This is indicating us that the market may see the overvaluation of Mastercard which is translated by a decreasing momentum on the stock price.",
        "To conclude this analysis. What is the story telling us? The story is that Mastercard is more efficient than Visa to generate return. This is explaining the higher price compared to Visa, but, the market seems to have seen it has overpriced the company and now the price is starting to lose momentum. We think that Mastercard price may decrease soon (future weeks) and that it may be a good opportunity to buy but later. On the other hand, if you want to invest into the sector Visa seems today to be a better candidate!",
        "Visa: https://neotradr.com/company/V",
        "Mastercard: https://neotradr.com/company/MA",
        "Don’t forget that this is not a buy/sell signal for you. You are the only one responsible for the investments you make (and the gain or loss you’ll have). Investors are responsible people. NeoTradr is here to crunch numbers and give you insights, not to tell you to buy or sell immediately.",
        "You can submit us the next company you want to be analyzed! Keep investing and see you next time",
        "#ValueInvesting #NeoTradr",
      ]
    },
    {
      id: '14',
      published_date: new Date(2024, 0, 3),
      title: 'Today we dig into Nucor (NUE) a steel manufacturer and seller',
      symbol: 'NUE',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/img_1746-1.png',
      content: [
        "Hello and happy new year! This is the first post of the year 🥳",
        "Today we decided to dig into Nucor (NUE) a steel manufacturer and seller. Nucor is a Dividend King, which means they have increased their dividend during at least the last 25 years.",
        "Nucor has a pretty high VIP (Value) score of 86/100. Let us dig into NeoTradr’s analysis of this company. In the highlights we can see that:",
        "The ROIC growth is good at more than 9%",
        "The revenue and net income and free cashflow are up",
        "The dividend growth is greater than 5%",
        "The price to book ratio is good below 4",
        "And the value of the ROIC is greater than 10%",
        "Continuing with what we called the “Opportunities”, which are criteria influenced and influencing the price we also have a lot:",
        "The PER of the last 5 years is good",
        "There is no shareholders dilution",
        "The price compared to free cashflow is also good",
        "The company is big enough (more than 10B in market cap)",
        "The stock price is lower than our DCF price estimation",
        "PER is still greater than 3 (too low can be risky)",
        "And the return on equity is greater than 8%",
        "One bad point we can see here is that the price is still higher than the Graham Number (taking into account net income, book value per share and share price), but its not far.",
        "As we can see, only with the Highlights section of NeoTradr we can have a very good overview of a company financial value. This is confirming the score of 86/100 of this company.",
        "Let’s suppose with the numbers we saw, we decided to invest. The next question that should come to your mind is: “Should I buy now? Should I wait for a better price?”",
        "NeoTradr gives you insights to answer this question with the Technical Timing Analysis widget. This widget is analysing the long term trends, as well as the short term direction and the momentum of the price.",
        "With Nucor historical price, NeoTradr analysed that it is a good moment to buy this stock at the current price (again, if you decided to invest in the company).",
        "Don’t forget that this is not a buy/sell signal for you. You are the only one responsible for the investments you make (and the gain or loss you’ll have). Investors are responsible people. NeoTradr is here to crunch numbers and give you insights, not to tell you to buy or sell immediately.",
        "You can submit us the next company you want to be analysed! Keep investing and see you next time",
        "#ValueInvesting #NeoTradr",
      ]
    },
    {
      id: '13',
      published_date: new Date(2023, 11, 27),
      title: 'Today we are analysing Sanofi',
      symbol: 'SAN.PA',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/img_1745-1.png',
      content: [
        "Hello and good end of year celebration to you investors! 🎉",
        "Today we are analysing Sanofi the international healthcare company. Let’s start our analysis with our investment habits by starting with the value analysis radar chart. With it, we can notice a good valuation of the company, a good overall financial health and a good growth of the company. However, the return on investment (what the investors will get) and the liquidity and debt levels are not that good (maybe high debt? Let’s see!).",
        "Analysing the debt levels, we can see long term debts are decreasing over time since 3 years which is good news for us with high interest rates. On the liquidity risk side, we can see that the net income is slightly decreasing for 5 years (But free cashflow still increasing).",
        "Things are getting a bit worse on the return on invested capital, in other words the return to shareholders will get. The ROIC is below our expectations at only 5% (we expect 10% minimum) and the growth over the last 5 years is at only 3% (we expect 9%). On top of that, the investors are diluted since 5 years (around 1% more shares since 5 years).",
        "All other metrics are green, dividends are strong and pricing metrics are also green: PER is good, Price compared to free cashflow is good, and the current price is lower than the DCF estimated price.",
        "On a more technical analysis point of view, the indicators are indicating that the stock is a good buy: the long term trend is positive, the current price is higher than the long term moving average. The RSI is decreasing below the 50 area, but no clear signal yet.",
        "What do we think of Sanofi? We think that this is a good healthcare company, the value pillars are good overall and we can see some buying signals. Should you buy the stock? It depends, Sanofi is a cyclical company, you should buy it when the cycle is low, today by looking at the RSI, we can see a deceasing momentum which may indicate a potential price decrease in the next few weeks. You can wait for a cheaper price if you want to time the market.",
        "However, we also think that Sanofi is a big actor in the healthcare sector, you can buy it now and hold it for the long term enjoying strong dividend growth with this well established company.",
        "You can find all the data here: https://neotradr.com/company/SAN__dot__PA",
        "Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #ValueInvesting #NeoTradr",
        "Submit us the next company you want to be analysed!",
      ]
    },
    {
      id: '12',
      published_date: new Date(2023, 11, 20),
      title: '3M',
      symbol: 'MMM',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/img_1744-1.png',
      content: [
        "Hello and good to you investors! Today let’s analyse a very old company, known for a lot a of invention that are used everyday by almost everyone. This company is based in Minnesota and called 3M (symbol: MMM).",
        "To analyse quickly the company we are going to use the new company SWOT analysis feature on NeoTradr. This allow to have the company strengths, weaknesses, opportunities and threats.",
        "Starting with the Strengths, we have a company with increasing revenue and net income, the dividend growth is also good at a 6% rate. If we look at the return on invested capital it is also good at 12%.",
        "Continuing on the Weaknesses, the growth of the return on invested capital, is lower than our expectation at 2%. The company also has debt ratio a bit high compared to its free cash flow and net income, 5.5 and 5.6 instead of the expected maximum of 5, this is not critical. One important point however is the decreasing free cashflow since 2021, from 6.5 billions USD to 4 billions.",
        "Let’s focus now on the opportunities. The current stock price of the company compared to its earnings (PER) is lower than the market average, same for the price / free cashflow. This is indicating that the current price is good compared to the last 5 years of free cashflow.",
        "Finishing on the Threats, we see that the company may be overvalued today compared to our DCF model and Graham number model. Also the return on equity (shareholders value) is negative due to the last net income of the company that went negative.",
        "This is the SWOT analysis, it gives you a good overview of a company pros and cons.",
        "On timing point of view, 3M seems to be a good buy right now looking at the stock price trend compared to moving average and RSI values.",
        "To conclude, 3M is known by investors since very long time to be a dividend paying company. In fact, they are paying dividends since more than 100 years and since 64 years without interruption and with increasing value. With what we have seen today, is it going to continue in the future? The company definitely has a bit of trouble as we speak but if you are confident with the historical data, the current price and future growth of the company, we think it may be a good time to buy.",
        "You can find all the data here: https://neotradr.com/company/MMM",
        "Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #ValueInvesting #NeoTradr",
        "Submit us the next company you want to be analysed!",
      ]
    },
    {
      id: '11',
      published_date: new Date(2023, 11, 13),
      title: 'Today we are going to analyse media company: News Corporation (NWSA)',
      symbol: 'NWSA',
      img_url: 'https://neotradr.files.wordpress.com/2024/01/img_1743-1.png',
      content: [
        "Hello and good morning to you investors ☺️,",
        "Today we are going to analyse media company: News Corporation (NWSA).",
        "News Corporation is a dynamic global media powerhouse, delivering engaging content through digital real estate, subscription video, Dow Jones publications, book publishing, and news media. Making it leader of storytelling and information services worldwide.",
        "With a very average score of 50/100, let’s analyse if this company score is more a 50+ or 50-.",
        "With the new radar graph (now with 5 development axis), we can very easily see that the company is profitable and growing, the current valuation is “okay” but the liquidity, debt as well as the financial health of the company are very low, lets dig deeper to understand the reason why.",
        "First thing we notice is that the company as high debts (more than expected), the net income is not increasing since at least 3 years, the free cashflow is stagnating for the last 5 years. In the current economic context having high debt and low income is bad news.",
        "On the other hand the ROIC growth is good (more than 9% since last 5 years) but it was negative to reach 1%, conclusion, even if the company’s ROIC is growing the ROIC is low. Devil is in the details.",
        "One more point to notice is that the company is buying back its own share which is good for shareholders, but (always a but), all the valuation metrics (PER, DCF,…) are saying that the current price for the company is a bit expensive and so it is not (always) a smart solution to buy back shares to increase shareholder value.",
        "To conclude, NWSA looks “okay” at first glance, but after digging below the surface we have encountered warnings and even alerts. The company financial health is questionable, the debt are high, the income streams are stagnating or worse decreasing. And the company is buying back its own shares at a high price! With those levels of debt and cashflow, we think this is not the correct behaviour to adopt. Sometimes it’s better to focus of the company’s internal status rather than the stock price itself.",
        "As you may have guess, we do not recommend this company today.",
        "Check yourself here: https://neotradr.com/company/NWSA",
        "Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #ValueInvesting #NeoTradr",
        "Submit us the next company you want to be analysed!",
      ]
    },
    {
      id: '10',
      published_date: new Date(2023, 11, 6),
      title: 'Today we are investigating a beverage company that is making a lot of advertising during Christmas period named Coca Cola',
      symbol: 'KO',
      img_url: 'https://neotradr.files.wordpress.com/2023/12/ab1f970d-173e-422b-994f-c366337c4725-4849-000004353fb83bf9_file.jpg',
      content: [
        "Hello investors! Today we are investigating a beverage company that is making a lot of advertising during Christmas period named Coca Cola ! 🥤",
        "Let’s analyze the company that has a relatively low value score of 57/100.",
        "This is a very old company that is also a « dividend king », so no surprise that the dividend is increasing over time since more than 60 years! Impressive!",
        "Let’s look at the radar graph that is showing clearly that the financial performances are here, but the liquidity levels are high and value is low compared to company price.",
        "The pillars are demonstrating this in many ways, the price is high compared to the earnings and revenues of the company, the ROIC is not growing but still strong at 10%. The debts are higher than expected but still okay.",
        "On the good side net income and revenue are increasing, return on equity is very high at 41% and the dividend growth is good at a 6% rate.",
        "Should you buy KO or not? On a pure value perspective, no, Coca Cola is not the best value company at the current price, but!, if you are looking on the very long term it may be a good choice only for the dividend history. Keep in mind to look on the debt levels that can become dangerous depending on the market situation.",
        "You can follow us on Facebook, LinkedIn andWordpress!",
        "As always, these insights are not buy/sell signals, and thorough research is essential before making investment decisions.",
        "Your choices are your own, and responsible investing is key. For more details and to form your own opinion, you can visit the NeoTradr analysis page for Skyworks Solutions: https://neotradr.com/company/SWKS",
        "Happy investing! #InvestResponsibly #NeoTradr",
      ]
    },
    {
      id: '9',
      published_date: new Date(2023, 10, 29),
      title: 'We analyze Skyworks Solutions, Inc.',
      symbol: 'SWKS',
      img_url: 'https://neotradr.files.wordpress.com/2023/11/4e876c68-e5ad-44e2-abdd-22c3792617c3-36330-000017c0b468b375_file.jpg',
      content: [
        "Hello and good day, fellow investors!",
        "Today, we’ll be diving into the world of semiconductor manufacturing to analyze Skyworks Solutions, Inc. (SWKS).",
        "Skyworks Solutions, Inc. is a key player in the semiconductor industry, specializing in the design and manufacturing of innovative solutions for wireless communication. With a global footprint, the company operates in a highly competitive market.",
        "The main things to know about the company are that Skyworks Solutions has well-covered debts and maintains strong liquidity levels, a positive indicator for financial stability but also that NeoTradr’s risk analysis flags several concerns on the improvement of its cash generation efficiency, as evidenced by the stagnant return on invested capital (ROIC) over the past five years.",
        "Additionally, the current stock price exceeds the estimated price calculated by the Graham number, indicating a potential overvaluation.",
        "Let’s delve deeper into the pillars to understand the key metrics driving Skyworks Solutions score of 86/100.",
        "The company’s ROIC has not shown significant growth, with a recent decrease of 4%, indicating a potential efficiency challenge.",
        "Skyworks Solutions has not experienced an increase in net income over the past three years, raising concerns about the sustainability of its earnings.",
        "A positive note is that the company has decreased the number of shares by 31%, enhancing shareholder value.",
        "The company’s long-term liabilities relative to its 5-year free cash flow appear to be at an acceptable level, providing a measure of financial stability.",
        "Price/Earnings Ratio (PER): The PER over the last five years is lower than the market average, standing at 14.",
        "Skyworks Solutions has demonstrated strong dividend growth of 24% per year over the last five years, enhancing its appeal for income-focused investors.",
        "On the technical timing analysis side we have indicators saying that it might be the right time to buy Skyworks Solutions.",
        "In summary, Skyworks Solutions presents a mixed picture. While the company faces challenges in improving efficiency and net income growth, positive indicators include prudent management of long-term liabilities, a favorable PER, and robust dividend growth.",
        "Investors might find it opportune to consider a purchase, but a cautious approach is advised given the identified risks.",
        "As always, these insights are not buy/sell signals, and thorough research is essential before making investment decisions.",
        "Your choices are your own, and responsible investing is key. For more details and to form your own opinion, you can visit the NeoTradr analysis page for Skyworks Solutions: https://neotradr.com/company/SWKS",
        "Happy investing! #InvestResponsibly #NeoTradr",
      ]
    },
    {
      id: '8',
      published_date: new Date(2023, 10, 22),
      title: 'Align Technology analysis',
      symbol: 'ALGN',
      img_url: 'https://neotradr.files.wordpress.com/2023/11/img_1423-1.png',
      content: [
        'Hello everyone! Hope you are all okay!',
        'Today we are going to analyze a company that you proposed: Align Technology!',
        'For those who don’t know the company, Align Technology designs, manufactures and markets intraoral scanners and services for orthodontists and general practitioner dentists. The company operates internationally and mainly in United States, Switzerland and China.',
        'Let’s analyse this company! Today with NeoTradr new radar graph, we can have a fast and powerful vision of the company valuation, performance and liquidity levels.',
        'We immediately see that the company debts are well covered and the company has liquidities.',
        'Taking a look at the risk section of NeoTradr, we see that the company seems expensive compared to its revenues. Let’s dig deeper to see if its only a simple warning or a more serious alert.',
        'Looking at the pillars, we see that the ROIC has been decreasing since 3 years, the net income is also lower than 5 years ago as well as the free cashflow.',
        'The Return on Equity is just above our limit but the signal is not clear with a lower ROIC.',
        'For now Align Technologies seems solid, but a decreasing net income, free cashflow and a low ROIC, make us think, it can be good to wait to buy this company.',
        'If we look at the technical timing analysis of NeoTradr, the convergence of indicators are sending unclear signals.',
        'For us, it is better to wait for the company price to decrease to buy the company.',
        'Always remember that even if a company has value (Align has 46/100 with our VIP Score) it can continue to drop if the timing is wrong. We are suggesting to wait for a better value and a better timing.',
        'Check on: https://neotradr.com/company/ALGN to make your own opinion (still 100% free)',
        'Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #InvestResponsibly #NeoTradr',
        'Submit us the next company you want to be analysed!',
        'https://neotradr.com',
      ]
    },
    {
      id: '7',
      title: "Let’s analyse AMD",
      img_url: "https://neotradr.files.wordpress.com/2023/11/untitled-2-1.png",
      published_date: new Date(2023, 10, 8),
      symbol: "AMD",
      content: [
        "Hello everyone! Hope your day goes well!",
        "Thanks to you, we received a recommendation to analyse a big microprocessor company named Advanced Micro Devices, better known as AMD. Let’s analyse this company!",
        "The Value score for this company is below average at 44/100. We see a lot of red pillars here. Does it mean that this is a bad company?",
        "Of course not, but it may not be the right time to buy it! That’s why the pillars are here.",
        "A quick look at the risk section tells us that the price of the company seems expensive compared to its current cash generation capacity (net income, free cashflow).",
        "Also, one remark is that the ROIC is quite low a 2%. One more point demonstrating the stagnation of AMD is also the net income that is decreasing since 2022.",
        "One last point that is not beneficial for the investor is that the number of shares is increasing a lot since January 2022 going from 1.2 billions to 1.65 billions shares!",
        "Shareholders have seen their value diluted in this company since January 2022.",
        "The side of the current state of AMD is that the debt levels are well managed, which is very important in the high rate environment we have today.",
        "To us, AMD is a good company but not at the current price and with higher earnings.",
        "Should you buy AMD? For us again it is wise to wait for the metrics to improve.",
        "Check on: https://neotradr.com/company/AMD to make your own opinion (100% free)",
        "Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #InvestResponsibly #NeoTradr",
        "Submit us the next company you want to be analysed!",
      ]
    },
    {
      id: "6",
      title: "Hello and happy Halloween to you investors! 🎃",
      img_url: "https://neotradr.files.wordpress.com/2023/11/img_1453-1.png",
      published_date: new Date(2023, 10, 1),
      symbol: "MSFT",
      content: [
        "Hello and happy Halloween to you investors! 🎃",
        "Today with the inflation hitting us all, it is good to keep in mind that companies with low debt are good opportunities. We will particularly look at the long term liabilities indicators like the LTL divided by the 5 years free cashflow or net income.",
        "One company that has currently a good LTL/5 years FCF ratio is Microsoft.",
        "With a debt ratio of 2.117 (we expect lower than 5 on NeoTradr), Microsoft’s long term debts are well covered by the returns of this company. (See https://neotradr.com/company/MSFT)",
        "With a ValueInvestingPillars score of 67/100 Microsoft is well noted. Let’s dig into the numbers and graphs to know more about the situation of Microsoft.",
        "With the risk section on NeoTradr, we can quickly know that the current price of the stock seems expensive relative to its PER ratio, P/FCF ratio, to our DCF analysis, to the Graham Number valuation and to its book value!",
        "Microsoft PER is at 40, which is high compared to the market (around 22) but the tech industry has been higher (and sometimes much higher) than the global market. Compared to other companies Microsoft is in the range.",
        "Our DCF model value the company at 121$, the current price is 338$, the company is indeed overvalued with this metric",
        "The graham number is even lower at 77$ but it is know to be less relevant with tech companies due to their profiles (high expected future cashflow, high growth, etc…)",
        "For the price to book value, it is at 12 instead of 4 expected by NeoTradr! Does it means this is a bad company? No, because tech companies again, have a low book value compared to their price due to their intangible assets, patents, intellectual property, etc…",
        "The other vital signs of the company are good, the ROIC and ROE (how efficient is the company to generate value for shareholders) is high, their are buying back their shares which is a good sign but the price may be a bit high to do so at the current price.",
        "One negative point is that the company net income is not strictly increasing since at least 3 years (little decline since last year).",
        "To summarise, Microsoft is a strong company that will not go bankrupt tomorrow, the foundations are solid, the income streams are diversified with computers, cloud, and gaming segments. The recent acquisition of OpenAI makes it a good player in the AI field.",
        "Read the full company page for free here: https://neotradr.com/company/MSFT",
        "Good day to you and keep investing!",
        "Remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #NeoTradr #InvestResponsibly",
      ]
    },
    {
      id: "5",
      title: "Kering, one of the biggest luxury brands in the world",
      img_url: "https://neotradr.files.wordpress.com/2023/11/img_1452-1.png",
      published_date: new Date(2023, 9, 18),
      symbol: "KER__dot__PA",
      content: [
        "Hello and good day to you investors!",
        "Today we are going to France to analyse Kering, one of the biggest luxury brands in the world (Gucci, Balenciaga, Saint Laurent,…)",
        "First, the VIP Score of 83/100 is a good one! Let’s dig deeper into the stock.",
        "The Risks analysed on NeoTradr are:",
        "Risk on the return on invested capital that has not grown of at least 9% since 5 years. Taking a deeper look, show that the ROIC is at good 11%. To go even further we can also see that the return on equity is at 24%, which is a super good value and shows that the company is profitable and efficient in generating value for its shareholders.",
        "The second risk is on the price of the company that look expensive. The DCF fair value is estimated at 382$ but the current price is at 409$. The price is indeed higher than the DCF estimated fair price but this can be explained with the brand and the sector of this company. We may tolerate to pay a certain premium on this company.",
        "All the other pillars analysed for this company are green.",
        "The number of shares is going down, the debt is at a sustainable level, the dividend growth is high at almost 100% since 5 years (the trend is increasing). Check on NeoTradr for the complete analysis: https://neotradr.com/company/KER__dot__PA",
        "For us Kering is a good company to invest in and the current price is very good for the value you’ll get.",
        "Read the complete analysis here: https://neotradr.com/company/KER__dot__PA",
        "And remember, these insights are not buy/sell signals. Your own research is crucial before making decisions. You’re in charge of your choices. #InvestResponsibly #NeoTradr #ValueInvesting",
        "Have a good day!",
      ]
    },
    {
      id: "4",
      title: "Today let’s focus on Nike 👟!",
      img_url: "https://neotradr.files.wordpress.com/2023/11/img_1451-2-1.jpg",
      published_date: new Date(2023, 9, 11),
      symbol: "NKE",
      content: [
        "Good morning value investors!",
        "Today let’s focus on Nike 👟!",
        "(You check here for the complee analysis: https://neotradr.com/company/NKE )",
        "What are the pillars telling us? With a VIP score of 67/100 Nike is well positoned. Let’s investigate the risks on this company from a value perspective.",
        "The ROIC growth is low but still at a good 14%",
        "The PER and Price to FCF are higher than other ompanies, but this is premium we can expect from a brand like Nike",
        "The biggest risk we can see is that the net income of the company is going down since 3 years. It is something to mnitor closely for future growth",
        "The green lights for the company are that:",
        "the return on equity is high at 34%",
        "The stock price today is cheaper tha 2 years ago",
        "The long term liabilities are decreasing",
        "And the dividends this company return to ts shareholders are increasing since a long period of time and at a rate of 11% since the last 5 years",
        "To summarize, Nike seems to be a good deal today even if the PER is higher than the market average. This can be a good stock for dividend based icome, but net income has to be monitored to prevent dividend cut.",
        "Read the complete analysis here: https://neotradr.com/company/NKE",
        "And remember, these insights are not buy/sell signals. Your own reearch is crucial before making decisions. You’re in charge of your choices. #DYOR #InvestResponsibly",
      ]
    },
    {
      id: "3",
      title: 'Analyzing Singapore Airlines',
      img_url: 'https://neotradr.files.wordpress.com/2023/11/img_1442-1.png',
      published_date: new Date(2023, 8, 20),
      symbol: 'C6L__dot__SI',
      content: [
        "Hello and good morning investors!",
        "Today we are going to a small south-asian island called Singapore, to analyse its flagship airline: Singapore Airlines!",
        "The score of this company as analysed by NeoTradr is 69/100 which put this company in the best ranked companies.",
        "Let’s review the risks on this company:",
        "They issued shares during covid time, which has diluted investors value. However they didn’t issued new shares since April 2021, which is good news",
        "The ROIC has not grown as we expected and the present return on invested capital is at 4%",
        "The latest PER is only at 9 (while the average 5y PER is negative due to covid effect)",
        "The dividend is not very consistent over time",
        "The others growth metrics look increasing and promising for the future!",
        "The company seems to have suffered a lot from the covid like all the other airlines in the planet. This is why 5 years average of PER, free cashflow, etc… are still negative, but we can notice good results in the after covid world and good hope for the future!",
        "Take a free in depth look at the company here: https://neotradr.com/company/C6L__dot__SI",
        "/!\ If this company looks good, be careful, we are not saying to buy or sell it, we are simply providing you insight so you can save time and investigate more to specific points.",

      ]
    },
    {
      id: "2",
      title: 'Today we are analysing Occidental Petroleum (OXY)',
      symbol: 'OXY',
      img_url: 'https://neotradr.files.wordpress.com/2023/11/img_1441-1.png',
      published_date: new Date(2023, 8, 13),
      content: [
        "Hello and good morning/afternoon/evening investors!",
        "Today we are analysing Occidental Petroleum (OXY). OXY together with its subsidiaries, engages in the acquisition, exploration, and development of oil and gas properties in the United States, the Middle East, North Africa, and Latin America.",
        "The ValueInvestingPillars Score (VIP Score) of this company is 68 over 100, so we have things to say!",
        "With NeoTradr we can quickly see that the company has issued a little amount of shares since 3 years but in reasonable amount.",
        "NeoTradr also raise an alert on debt level that seems high. After analysing the graphs, we see that the debt level is decreasing over time which is a good news! We assume that this long-term debt is at an acceptable level if we consider the energy industry.",
        "Let’s now dig into the pros of this company:",
        "The Return on Invested Capital is growing at a rate of 18% compared to 5 years ago",
        "The Revenue, the Net Income and the Free Cashflow are increasing over time",
        "Our estimated valuation price with Graham number and DCF is higher than the current price which may indicate a discount on this company",
        "After reading the story of OXY with NeoTradr, the company looks interesting from a value perspective. You can find the complete company report here: https://neotradr.com/company/OXY",
        "It is free and you don’t need to login.",
        "/!\\ If this company looks good, be careful, we are not saying to buy or sell it, we are simply providing you insight so you can save time and investigate more to specific points.",
      ]
    },
    {
      id: '1',
      title: 'Today with NeoTradr we are analyzing LEN: Lennar Corporation.',
      symbol: 'LEN',
      published_date: new Date(2023, 8, 6),
      img_url: 'https://neotradr.files.wordpress.com/2023/11/img_1440-1.png',
      content: [
        'Hello and good morning investors!',
        'Today with NeoTradr we are analyzing LEN: Lennar Corporation.',
        'LEN is in the Real Estate business, based in Florida.',
        'With NeoTradr we can quickly see that the company is promising with a VIP Score of 92/100!',
        'The only risk observe on this company is that the ROIC has “only” grew from 6% since 5 years ago. The current ROIC is at 12% which is still not bad.',
        'Looking at the other pillars with NeoTradr we can see that all other metrics are in the green area:',
        'PER is lower than the market',
        'Revenue and Net Income are growing compared to 5 years ago',
        'The number of shares is decreasing (increasing shareholders value)',
        'Long term debt are under acceptable levels',
        'The dividend growth per year since the last 5 years is around 35%!',
        'Price to book ratio is also low at 1.4',
        '/!\ If this company looks good, be careful, we are not saying to buy or sell it, we are simply providing you insight so you can save time and investigate more to specific points.',
        'If you want to get more insights on more companies, visit NeoTradr at: https://neotradr.com/company/LEN',
      ]
    }
  ]

  ngOnInit(): void {
    this.processLinks()
  }

  processLinks(): void {
    this.blog_data.forEach(blog => {
      blog.content = blog.content.map(x => x.replace(/(https?:\/\/[^\s]+)/g, url => {
        return `<a href="${url}" target="_blank">${url}</a>`
      }))
    })
  }
}
