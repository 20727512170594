import { Component } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { convert_int_to_ext_symbol } from 'src/app/core/helpers';
import { LoginService } from 'src/app/core/login.service';
import { HighestScoreData, PillarsService } from 'src/app/core/pillars.service';

@Component({
  selector: 'app-highest-score-by-sector',
  templateUrl: './highest-score-by-sector.component.html',
  styleUrls: ['./highest-score-by-sector.component.scss']
})
export class HighestScoreBySectorComponent {
  highestScoreBySector: {[key: string]: Array<HighestScoreData>} = {}
  currentSelection: {key: string, value: Array<HighestScoreData>}|null = null
  isLoading: boolean = false
  isLoggedIn: boolean = false
  highestRanking: Array<HighestScoreData & {blur: boolean}> = []
  displayedColumns = ['symbol', 'score', 'action']
  dataSource: MatTableDataSource<HighestScoreData & {blur: boolean}> = new MatTableDataSource()
  convert_int_to_ext_symbol = convert_int_to_ext_symbol

  constructor(
    private pillarsService: PillarsService,
    private router: Router,
    private loginService: LoginService){}
  ngOnInit(): void {
    this.isLoading = true
    this.pillarsService.getHighestPerSectorRanking().subscribe(resp => {
      this.highestScoreBySector = resp
      // console.log('in subscribe by sector: ', resp)
      
      if(this.highestScoreBySector){
        const keys = Object.keys(this.highestScoreBySector)
        if(keys.length > 0){
          this.currentSelection = {
            key: keys[0],
            value: this.highestScoreBySector[keys[0]]
          }
          this.updateRankingBasedOnCurrentSelection()
        }
      } else {
        console.log('Error when loading best ranking data :(')
      }
      this.isLoading = false
    })
  }

  getGreenColoGradient(percent: number): string {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#${colorChoosen}0FF${colorChoosen}0`
  }

  getRedColoGradient(percent: number): string {
    percent = Math.max(percent, 0)
    percent = Math.min(percent, 100)
    percent = 100 - percent
    const colorCodes = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F'] // Going only to B to have more intense colors
    const colorChoosen = colorCodes[Math.round((( colorCodes.length - 1)/100)*percent)]
    return `#FF${colorChoosen}0${colorChoosen}0`
  }

  getColorFromScore(percent: number): string {
    let value = ""
    if(percent > 50){
      value = this.getGreenColoGradient((percent-50)*2)
    } else {
      value = this.getRedColoGradient((50 - percent)*2)
    }
    return value;
  }

  selectionChange($event: MatSelectionListChange): void {
    console.log($event.options[0].value)
    this.currentSelection = $event.options[0].value
    this.updateRankingBasedOnCurrentSelection()
  }

  updateRankingBasedOnCurrentSelection(): void {
    if(this.currentSelection){
      this.highestRanking = this.currentSelection.value.filter((val, i, arr) => {
        return arr.findIndex(x => x.symbol == val.symbol) == i ? true : false
      }).map((val, index) => {
        // if(!this.isLoggedIn && (index < 5 || index > 18)){
        //   val.score = 100
        //   val.symbol = "FAKE"
        //   return {...val, 'blur': true}
        // } else {
          return {...val, 'blur': false}
        // }
      })
      this.dataSource = new MatTableDataSource<HighestScoreData & {blur: boolean}>(this.highestRanking)
    }
  }

  goToCompanyPage(symbol: string): void {
    if(symbol != "FAKE"){
      this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
    }
  }
}
