export interface PortfolioData {
    id: string,
    portfolio_name: string,
    description: string,
    cache_name: string,
    rest_resource_name: string,
    static_resource_name: string
}

export const portfolioData: Array<PortfolioData> = [
    {
        id: "test",
        portfolio_name: "Portfolio test",
        description: "Test description for Portfolio test",
        cache_name: "dowjones30-allpillars",
        rest_resource_name: "get_dowjones30_pillars",
        static_resource_name: "get_dowjones30_pillars"
    },
    {
        id: "dowjones30-index",
        portfolio_name: "Dow Jones Index",
        description: "Value Investing Pillars analysis of Dow Jones companies with computed VIP score",
        cache_name: "dowjones30-allpillars",
        rest_resource_name: "get_dowjones30_pillars",
        static_resource_name: "get_dowjones30_pillars"
    },

    {
        id: 'cac40-index',
        portfolio_name: 'CAC 40 Index',
        description: 'Value Investing Pillars analysis of CAC 40 companies with computed VIP score',
        cache_name: 'cac40-allpillars',
        rest_resource_name: 'get_cac40_pillars',
        static_resource_name: 'get_cac40_pillars'
    },
    {
        id: 'berkshire-hathaway-portfolio',
        portfolio_name: 'Berkshire Hathaway Portfolio',
        description: 'Value Investing Pillars analysis of Berkshire Hathaway companies',
        cache_name: 'berkshire-hathaway-portfolio-allpillars',
        rest_resource_name: 'get_berkshire_hathaway_pillars',
        static_resource_name: 'get_berkshire_hathaway_pillars'
    },
    {
        id: 'sti-index',
        portfolio_name: 'STI Index',
        description: 'Value Investing Pillars analysis of Straits Times Index (STI) companies with computed VIP score',
        cache_name: 'stiindex-allpillars',
        rest_resource_name: 'get_sti_pillars',
        static_resource_name: 'get_sti_pillars'
    },
    {
        id: 'dividend-kings-portfolio',
        portfolio_name: 'Dividend Kings Portfolio',
        description: 'Value Investing Pillars analysis of Dividend Kings companies with computed VIP score',
        cache_name: 'dividend-kings-allpillars',
        rest_resource_name: 'get_dividend_kings_pillars',
        static_resource_name: 'get_dividend_kings_pillars'
    },
    {
        id: 'hang-seng-portfolio',
        portfolio_name: 'HSI 50 Portfolio',
        description: 'Value Investing Pillars analysis of Hang Seng 50 (Hong Kong) companies with computed VIP score',
        cache_name: 'hang-seng-allpillars',
        rest_resource_name: 'get_hang_seng_50_pillars',
        static_resource_name: 'get_hang_seng_50_pillars'
    }
]

export const getPortfolioData = (portfolio_id: string): PortfolioData|null => {
    let portfolios = portfolioData.filter(x => x.id == portfolio_id)
    if(portfolios.length > 0){
        return portfolios[0]
    }
    return null
}

export const getPorfolioIdList = (): Array<string> => {
    return portfolioData.map(x => x.id).filter(x => x != "test")
}

export const getPorfolioIdNameList = (): Array<{id: string, portfolio_name: string}> => {
    return portfolioData.map(x => { 
        return {id: x.id, portfolio_name: x.portfolio_name}
    }).filter(x => x.id != "test")
}