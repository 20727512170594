<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-12">
            <mat-form-field>
                <mat-label>Projection on</mat-label>
                <mat-select [(value)]="selectedYear">
                    <mat-option [value]="5">5 years</mat-option>
                    <mat-option [value]="10">10 years</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
            <mat-form-field>
                <mat-label>Desired Annual Return</mat-label>
                <input matInput type="number" [(ngModel)]="desiredAnnualReturn" placeholder="Enter value">
                <span matSuffix>% </span>
            </mat-form-field>
        </div>
    </div>

    <table class="table">
        <thead>
            <tr>
                <th>My assumptions</th>
                <th>Low</th>
                <th>Medium</th>
                <th>High</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assumption of assumptions | keyvalue">
                <td>{{ assumption.value.name }}</td>
                <td><input type="number" [(ngModel)]="assumption.value.low" placeholder="Enter value"></td>
                <td><input type="number" [(ngModel)]="assumption.value.medium" placeholder="Enter value"></td>
                <td><input type="number" [(ngModel)]="assumption.value.high" placeholder="Enter value"></td>
            </tr>
        </tbody>
    </table>
    
    <div style="display: flex; justify-content: center;">
        <button mat-raised-button (click)="calculateStockPrices()">Calculate</button>
    </div>

    <div class="result">
        <h2>Current stock price</h2>
        <p style="font-size: 16px;">{{ stockPrice | number }}</p>
    </div>
    
    <div class="result">
        <h2>DCF Stock Prices</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>Scenario</th>
                    <th>Low</th>
                    <th>Medium</th>
                    <th>High</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Discounted Cashflow</td>
                    <td [style.color]="stockPrices2.low <= stockPrice ? 'red' : 'green'">{{ stockPrices2.low !== 0 ? (stockPrices2.low | number) : '-' }}</td>
                    <td [style.color]="stockPrices2.medium <= stockPrice ? 'red' : 'green'">{{ stockPrices2.medium !== 0 ? (stockPrices2.medium | number) : '-' }}</td>
                    <td [style.color]="stockPrices2.high <= stockPrice ? 'red' : 'green'">{{ stockPrices2.high !== 0 ? (stockPrices2.high | number) : '-' }}</td>
                </tr>
                <tr>
                    <td>Price difference with current stock price</td>
                    <td>{{ Math.abs((stockPrice - stockPrices2.low)/stockPrice * 100) | number: '1.0-0' }}% {{stockPrices2.low < stockPrice ? 'more expensive' : 'cheaper'}}</td>
                    <td>{{ Math.abs((stockPrice - stockPrices2.medium)/stockPrice * 100) | number: '1.0-0' }}% {{stockPrices2.medium < stockPrice ? 'more expensive' : 'cheaper'}}</td>
                    <td>{{ Math.abs((stockPrice - stockPrices2.high)/stockPrice * 100) | number: '1.0-0' }}% {{stockPrices2.high < stockPrice ? 'more expensive' : 'cheaper'}}</td>
                </tr>
                <tr>
                    <td>Multiple of earnings model</td>
                    <td [style.color]="stockPricePE.low <= stockPrice ? 'red' : 'green'">{{ stockPricePE.low !== 0 ? (stockPricePE.low | number) : '-' }}</td>
                    <td [style.color]="stockPricePE.medium <= stockPrice ? 'red' : 'green'">{{ stockPricePE.medium !== 0 ? (stockPricePE.medium | number) : '-' }}</td>
                    <td [style.color]="stockPricePE.high <= stockPrice ? 'red' : 'green'">{{ stockPricePE.high !== 0 ? (stockPricePE.high | number) : '-' }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Explanation of valuation methods
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
            <div>
                <p>
                    <b>The Discounted Cashflow model</b> results represent the estimated stock prices based on different scenarios and assumptions.
                    Here's a breakdown of the results:
                </p>
            
                <ul>
                    <li>
                        <strong>Low Scenario:</strong> The estimated stock price in the low scenario is <span [style.color]="stockPrices2.low < stockPrice ? 'red' : 'green'">{{ stockPrices2.low | number }}</span>.
                        This means that if the actual cashflow and other factors turn out to be worse than expected, the stock price could be lower than the current stock price.
                    </li>
                    <li>
                        <strong>Medium Scenario:</strong> The estimated stock price in the medium scenario is <span [style.color]="stockPrices2.medium < stockPrice ? 'red' : 'green'">{{ stockPrices2.medium | number }}</span>.
                        This represents the expected stock price based on the assumptions and cashflow projections.
                    </li>
                    <li>
                        <strong>High Scenario:</strong> The estimated stock price in the high scenario is <span [style.color]="stockPrices2.high < stockPrice ? 'red' : 'green'">{{ stockPrices2.high | number }}</span>.
                        This indicates the potential upside if the cashflow and other factors perform better than expected.
                    </li>
                </ul>
            
                <p>
                    It's important to note that these estimates are based on assumptions and projections, and actual stock prices may vary.
                    The color coding indicates whether the estimated stock price is lower (red) or higher (green) than the current stock price.
                </p>



                <p>
                    <b>The Multiple of Earnings model</b> results represent the estimated stock prices based on the Price Earnings (PE) multiple valuation metric.
                    Here's a breakdown of the results:
                </p>

                <ul>
                    <li>
                        <strong>Low Scenario:</strong> The estimated stock price in the low scenario is <span [style.color]="stockPricePE.low < stockPrice ? 'red' : 'green'">{{ stockPricePE.low | number }}</span>.
                        This means that if the PE multiple is lower than expected, the stock price could be lower than the current stock price.
                    </li>
                    <li>
                        <strong>Medium Scenario:</strong> The estimated stock price in the medium scenario is <span [style.color]="stockPricePE.medium < stockPrice ? 'red' : 'green'">{{ stockPricePE.medium | number }}</span>.
                        This represents the expected stock price based on the assumptions and the average PE multiple.
                    </li>
                    <li>
                        <strong>High Scenario:</strong> The estimated stock price in the high scenario is <span [style.color]="stockPricePE.high < stockPrice ? 'red' : 'green'">{{ stockPricePE.high | number }}</span>.
                        This indicates the potential upside if the PE multiple is higher than expected.
                    </li>
                </ul>

                <p>
                    It's important to note that the PE multiple valuation metric is based on the ratio of the stock price to the earnings per share (EPS).
                    A higher PE multiple suggests that investors are willing to pay a higher price for each unit of earnings, indicating higher growth expectations.
                    Conversely, a lower PE multiple suggests lower growth expectations or undervaluation.
                    The color coding indicates whether the estimated stock price is lower (red) or higher (green) than the current stock price.
                </p>
            </div>
        </mat-panel-description>
    </mat-expansion-panel>
</div>