import { Component, Inject, Input, OnInit } from '@angular/core';
import { SubscriptionOfferDialogComponent } from './subscription-offer-dialog/subscription-offer-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-button',
  templateUrl: './subscription-button.component.html',
  styleUrls: ['./subscription-button.component.scss']
})
export class SubscriptionButtonComponent implements OnInit {
  @Input('color') color: string = "primary"

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
  }

  buttonClicked(): void {
    // const _dialogRef = this.dialog.open(SubscriptionOfferDialogComponent, {
    //   // data: this.mailRegistrationData,
    //   width: '90%'
    // });
    this.router.navigate(['subscribe'])
  }

}
