<div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <mat-selection-list #sector_selection [multiple]="false" (selectionChange)="selectionChange($event)">
            <mat-list-option role="listitem" *ngFor="let keyval of this.highestScoreBySector | keyvalue" [value]="keyval">
                {{keyval.key}}
            </mat-list-option>
        </mat-selection-list>      
    </div>
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div *ngIf="this.currentSelection == null">Select a category</div>
        <div *ngIf="this.currentSelection">
            <h2>{{this.currentSelection.key}}</h2>
            <!-- <div *ngIf="!this.isLoggedIn" class="">
                <app-login class="" mat-raised-button color="accent">
                    <div class="">Login or Sign up to access all data</div>
                </app-login>
            </div> -->
            <table *ngIf="!isLoading" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="symbol">
                    <th mat-header-cell *matHeaderCellDef> Symbol</th>
                    <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                        <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                            {{element.symbol}}
                        </app-login-required>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef> VIP Score</th>
                    <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                        <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                            {{element.score|number:'1.0-0'}}/100
                        </app-login-required>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                            <a mat-icon-button [disabled]="element.blur" [href]="'/company/'+this.convert_int_to_ext_symbol(element.symbol)" >
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </a>
                        </app-login-required>
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr [ngStyle]="{'background-color':getColorFromScore(row.score)}" mat-row (click)="goToCompanyPage(row.symbol)" *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="isLoading">
                <mat-spinner></mat-spinner>
                <h3>Data are loading...</h3>
            </div>
            
        </div>
    </div>
</div>
