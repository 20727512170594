import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SymbolData } from '../components/symbol-list/symbol-list.component';
import { getLocalStorageItem, setLocalStorageItem } from '../core/local-storage-manager';
import { AllSymbolData, PillarData, PillarsService } from '../core/pillars.service';
import { LoginService } from '../core/login.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { NgOptimizedImage } from '@angular/common';
import { Region, getUserMainRegion } from '../core/core_common';
import { productDescription } from '../subscription-page/subscription-page.component';
// import { TarteaucitronService } from '../core/tarteaucitron.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  ntVersion: string = environment.ntVersion
  disableButton: boolean = true
  symbol: string = "AAPL"
  isLoggedIn: boolean = false
  lastAnalyzedSymbol: string|undefined
  symbolHistory: {[key: string]: SymbolData} = {}
  isXSmallScreen: boolean = false
  currentIndex = 0;
  carouselItems:Array<{name: string, avatarUrl: string, avatarUrlWebp:string, feedbackText: string}> = []
  productDescription = productDescription
  feedbackData = [
    {
      name: "Romain Le Donge",
      avatarUrl: "https://www.romain-ledonge.com/img/photo-profil.png",
      avatarUrlWebp: "https://www.romain-ledonge.com/img/photo-profil.png",
      feedbackText: "As NeoTradr founder, I'm an investment lover and I am using NeoTradr to analyze all my companies before doing an investment move.",
    },
    {
      name: "Jamal Khattab",
      avatarUrl: "assets/img/interview/Jamal_Khattab_small.jpg",
      avatarUrlWebp: "assets/img/interview/Jamal_Khattab_small.webp",
      feedbackText: "As a busy professional, I appreciate NeoTradr's convenience. Their emails keep me in the loop with top-notch investment prospects, allowing me to make informed decisions on the go.",
    },
    {
      name: "Ling Chen",
      avatarUrl: "assets/img/interview/Ling_Chen_small.png",
      avatarUrlWebp: "assets/img/interview/Ling_Chen_small.webp",
      feedbackText: "I'm no expert in finance, but NeoTradr's user-friendly approach has made me feel like one. Their analysis has empowered me to take control of my financial future.",
    },
    {
      name: "Mark Thompson",
      avatarUrl: "assets/img/interview/Mark_Thompson_small.jpg",
      avatarUrlWebp: "assets/img/interview/Mark_Thompson_small.jpg",
      feedbackText: "Thanks to NeoTradr's reports, I've made smarter investment decisions and avoided costly mistakes. Their insights are an invaluable asset to my portfolio.",
    },
    {
      name: "Wang Li",
      avatarUrl: "assets/img/interview/Wang_Li_small.jpg",
      avatarUrlWebp: "assets/img/interview/Wang_Li_small.jpg",
      feedbackText: "NeoTradr simplifies the complex world of value investing. Their regular updates keep me informed about the best investment opportunities, saving me time and effort.",
    },
    {
      name: "Xia Sullivan",
      avatarUrl: "assets/img/interview/Xia_Sullivan_small.jpg",
      avatarUrlWebp: "assets/img/interview/Xia_Sullivan_small.jpg",
      feedbackText: "I was skeptical at first, but NeoTradr's recommendations have consistently outperformed my expectations. Their analysis is now an essential part of my investment strategy.",
    },
  ];

  regionCustomization: {
    index: {
      link: string,
      description: string
    }
  } = {
    index: {
      link: "/portfolio/dowjones30-index",
      description: "Dow Jones 30 analysis"
    }
  }


  constructor(
    private router: Router,
    private loginService: LoginService,
    private breakpointObserver: BreakpointObserver) {
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .subscribe(result => { 
          this.isXSmallScreen = result.matches;
        });
    }

  ngOnInit(): void {
    // this.tac.initTarteaucitron()
    this.loadSymbolHistory()
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn
    })
    this.scrollCarousel(0)

    // This is here for landing page customization
    let region = getUserMainRegion()
    if(region == Region.Asia){
      this.regionCustomization.index.link = "/portfolio/sti-index"
      this.regionCustomization.index.description = "Singapore Index analysis"
    } else if(region == Region.Europe){
      this.regionCustomization.index.link = "/portfolio/cac40-index"
      this.regionCustomization.index.description = "CAC40 Index analysis"
    }
  }

  goToDowJonesAnalysisPage(): void {
    this.router.navigate(['/portfolio/dowjones'])
  }

  goToDiscoverPage(): void {
    this.router.navigate(['discover'])
  }

  goToDashboard(): void {
    this.router.navigate(['dashboard'])
  }

  loadSymbolHistory(): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    this.symbolHistory = savedHistory
  }

  setSymbolToHistory(symbol: string, score: number, pillars_data: Array<PillarData>): void {
    let savedHistory:({[key: string]: SymbolData})|null = getLocalStorageItem('symbolhistory')
    if(!savedHistory){
      savedHistory = {}
    }
    savedHistory[symbol] = {
      symbol: symbol,
      timestamp: new Date(),
      score: score,
      pillars_data: pillars_data
    }
    setLocalStorageItem('symbolhistory', savedHistory, 60*60*24*365*15)
  }

  scrollCarousel(direction: number): void {
    this.carouselItems = []
    this.carouselItems.push(this.feedbackData[this.currentIndex])
    this.carouselItems.push(this.feedbackData[(this.currentIndex+1)%this.feedbackData.length])
    this.carouselItems.push(this.feedbackData[(this.currentIndex+2)%this.feedbackData.length])
    
    this.currentIndex = (this.currentIndex+direction)%this.feedbackData.length;
    if(this.currentIndex < 0){
      this.currentIndex = 0
    }
  }
}
