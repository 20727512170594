
/**
 * Return the data attached to the key from local storage
 * If expiration data is greater than specified return null
 * item_name: the name of the item
 */
export let getLocalStorageItem = (item_name: string): any => {
    if(typeof localStorage == 'undefined') {
        return 
    }
    let raw_data_object = localStorage.getItem(item_name);
    if(!raw_data_object){
        return null;
    }

    let data_object = JSON.parse(raw_data_object);
    
    let deltaSeconds = (Date.now() - data_object['created_at'])/1000;
    let data_expiration = data_object['data_expiration'];
    
    if(deltaSeconds > data_expiration){
        console.log(`Data has expired: ${item_name}`);
        return null;
    } else {
        console.log(`Request avoided for ${item_name} (${deltaSeconds} < ${data_expiration})`);
        return data_object['data'];
    }
}

export let setLocalStorageItem = (item_name: string, data: any, data_expiration: number) => {
    /** data_expiration in seconds */
    try {
        if(typeof localStorage == 'undefined') {
            return 
        }
        let data_object = {
            created_at: Date.now(),
            data: data,
            data_expiration: data_expiration
        };
        localStorage.setItem(item_name, JSON.stringify(data_object));   
    } catch (error) {
        // clean cache
        localStorage.clear()
    }
}

