<div>
    <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="ml-0 pl-0">
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Symbol</th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    {{convert_ext_to_int_symbol(element.symbol)}}
                <!-- </app-login-required> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    {{element.company_name}}
                <!-- </app-login-required> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    <div style="float: right;">
                        <a matSuffix class="action-button" mat-raised-button color="primary"
                        matTooltip="Analyze value"
                        [href]="'/value/'+convert_int_to_ext_symbol(element.symbol)"
                        [disabled]="element.blur">
                            <mat-icon class="action-button-icon-value">search</mat-icon>
                            <mat-icon class="action-button-icon-value">trending_up</mat-icon>
                        </a>

                        <a matSuffix class="action-button" mat-raised-button color="primary"
                        matTooltip="Analyze momentum"
                        [disabled]="element.blur"
                        [href]="'/momentum/'+convert_int_to_ext_symbol(element.symbol)">
                            <mat-icon class="action-button-icon-momentum">search</mat-icon>
                            <mat-icon class="action-button-icon-momentum">flash_on</mat-icon>
                        </a>
                    </div>
                <!-- </app-login-required> -->
            </td>
        </ng-container>
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <!-- <tr mat-row (click)="goToCompanyPage(row.symbol)" *matRowDef="let row; columns: displayedColumns;"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
