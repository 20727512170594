import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-success-page',
  templateUrl: './subscription-success-page.component.html',
  styleUrls: ['./subscription-success-page.component.scss']
})
export class SubscriptionSuccessPageComponent {
  success?: boolean

  constructor(private activatedRoute: ActivatedRoute){}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const session_id = params['session_id']
      if(session_id){
        this.success = true
      } else {
        this.success = false
      }
    })
  }
}
