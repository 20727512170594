import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, InjectionToken, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Chart, ChartDataset, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';


@Component({
  selector: 'app-timeplot',
  templateUrl: './timeplot.component.html',
  styleUrls: ['./timeplot.component.scss']
})
export class TimeplotComponent implements OnInit {
  ctx_chart: any
  chartjs_chart: Chart|undefined
  
  // @Input() chart_title: string|Array<string> = ""
  
  _chart_title: string|Array<string> = ""
  @Input()
  set chart_title(val: string|Array<string>){
    this._chart_title = val
    this.loadData()
  }
  get chart_title(): string|Array<string> {
    return this._chart_title
  }

  _time_values: Array<any> = []
  @Input()
  set time_values(val: Array<any>){
    this._time_values = val
    this.loadData()
  }

  _values: Array<number>|Array<Array<number>> = []
  @Input()
  set values(val: Array<any>){
    this._values = val
    this.loadData()
  }

  _showPlotPoints: boolean = true
  @Input()
  set show_points(val: boolean){
    this._showPlotPoints = val
  }

  isBrowser = false

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.isBrowser  = isPlatformBrowser(this.platformId);
    }

  ngOnInit(): void {
    Chart.register(...registerables)

    if(this.isBrowser){
      this.ctx_chart = this.elementRef.nativeElement.querySelector('#chart');

      this.chartjs_chart = new Chart(this.ctx_chart, {
        type: 'line',
        data: {labels: [], datasets: []},
        options: {
          indexAxis: 'x',
          // aspectRatio: 2/1,
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: this.chart_title,
            }
          },
          scales: {
            x: {
              type: 'timeseries',
              adapters: {
                date: {
                  locale: enUS
                }
              }
            },
            
          },
          elements: {
            point: {
              radius: this._showPlotPoints ? 2 : 0
            }
          }
        },
      })
      this.loadData()
    }
  }

  loadData(): void {
    const borderColors = ["rgb(54, 162, 235)", "rgb(54, 162, 35)", "rgb(154, 62, 35)", "#FFA500"]
    if(this.chartjs_chart){
      this.chartjs_chart.config.data.labels = this._time_values

      let datasets: Array<ChartDataset> = []
      let titles = this.chart_title
      let values:Array<Array<number>> = []
      if(!(titles instanceof Array)){
        titles = [titles]
      }
      if(this._values === undefined){
        return // No values set
      }
      if(!(this._values instanceof Array)){
        console.error('Data to plot should be an array')
        return
      } else {
        if(this._values.length > 0){
          if(this._values[0] instanceof Array){
            values = <Array<Array<any>>>this._values
          } else {
            values = [<Array<number>>this._values]
          }
        } else {
          return
        }
      }
      if(titles.length != values.length){
        console.error(`Data have to be consistent got length ${titles.length} and ${values.length}`)
        return
      }
      
      for(let i = 0; i < titles.length; i++){
        const title = titles[i]
        const plotValues = values[i]
        if(typeof title === "string"){
          datasets.push({
            label: title,
            data: plotValues,
            borderColor: borderColors[i%borderColors.length],
            borderWidth: 2,
            type: 'line',
            tension: 0.4,
            cubicInterpolationMode: 'monotone',
          })
        } else {
          console.error(`Error: title should be string or array of strings not ${typeof title}`)
        }
      } 

      this.chartjs_chart.config.data.datasets = datasets
      this.chartjs_chart.update()
    }
  }

}
