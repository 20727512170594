<div class="row mx-0">
    <div class="col-12">
        <app-news-ticker></app-news-ticker>
    </div>
</div>

<mat-toolbar color="secondary">
    <mat-toolbar-row>
        <button mat-button (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
            Back
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div class="container">
    <div class="nt-primary" style="font-family: Inter; font-size: 38px; font-weight: 800; line-height: 1.2;">
        Business news
    </div>
    <div class="" style="font-family: Inter; color: grey ;font-size: 18px; font-weight: 500; line-height: 1.2;">
        News specifically filtered and grouped together based on subject popularity.
    </div>
    
    <div *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!isLoading" class="row">
        <hr class="mt-3">
        <div class="nt-primary" style="font-family: Inter; font-size: 28px; font-weight: 800; line-height: 1.2;">
            Popular news 
            <mat-icon class="info-icon" matTooltip="Those are news that are the most popular around the world with a lot of published articles" color="primary" aria-hidden="false" aria-label="Explaination of the Popular News" fontIcon="info"></mat-icon>
        </div>
        
        <app-main-news [full_page]="true" [short]="true"></app-main-news>
        
        <hr class="mt-3">
        <div class="nt-primary" style="font-family: Inter; font-size: 28px; font-weight: 800; line-height: 1.2;">
            All business news
            <mat-icon class="info-icon" matTooltip="Those are all the global news from around the world" color="primary" aria-hidden="false" aria-label="Explaination of the All Business News" fontIcon="info"></mat-icon>
        </div>
        <app-articles-component></app-articles-component>
    </div>
</div>
<app-footer></app-footer>