import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GroupedNewsGroup, IntelligenceService } from '../../core/intelligence.service';
import externalData from '../../../assets/company_name_data.json';
import { Article } from 'src/app/core/articles.service';
import { convert_int_to_ext_symbol } from 'src/app/core/helpers';
const companies: Array<{symbol: string, company_name: string}> = externalData

@Component({
  selector: 'app-main-news',
  templateUrl: './main-news.component.html',
  styleUrls: ['./main-news.component.scss']
})
export class MainNewsComponent implements OnInit {
  @Input() short: boolean = false
  @Input() n_data: number = 10
  @Input() full_page: boolean = false
  @Input() useCarousel: boolean = false
  @Input() title_only: boolean = false
  @ViewChild('carouselContainer') carouselContainer: ElementRef | undefined;
  convert_int_to_ext_symbol = convert_int_to_ext_symbol
  grouped_news: Array<GroupedNewsGroup> = [];
  isLoading: boolean = true

  currentIndex: number = 0;
  intervalId: any;


  constructor(private intelligence: IntelligenceService) { }

  ngOnInit(): void {
    this.isLoading = true
    if(this.useCarousel){
      this.startAutoSlide();
    }
    this.intelligence.getGroupedNews().subscribe(grouped_news_result => {
      try {
        if(grouped_news_result && grouped_news_result.groups){
          let grouped_news = grouped_news_result.groups
          grouped_news.forEach(group => {
            group.articles_data.sort((a, b) => {
              return a.article.datetime > b.article.datetime ? -1 : 1
            })
          });
          grouped_news.forEach(group => {
            group.articles_data.forEach(ad => {
              ad.article.companies = findCompaniesInText(ad.article, companies)
            })
          })

          grouped_news.sort((a, b) => {
            return a.articles_data[0].article.datetime > b.articles_data[0].article.datetime ? -1 : 1
          })
          grouped_news = grouped_news.filter((v, i) => i < this.n_data)
          this.grouped_news = grouped_news // Assign only at the end make the UI refresh correctly
          this.isLoading = false
        } else {
          this.grouped_news = []
          this.isLoading = false
        }
        console.log('N Grouped News: ', this.grouped_news.length)
      } catch(err) {
        console.warn('Error when sorting data: ', err);
        this.isLoading = false
      }
    })
  }

  ngOnDestroy(): void {
    this.stopAutoSlide();
  }

  startAutoSlide(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 5000); // Change slide every 3 seconds
  }

  stopAutoSlide(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  previousSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.grouped_news.length) % this.grouped_news.length;
  }

  nextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.grouped_news.length;
  }
}


function preprocessCompanyName(companyName: string): string {
  // List of suffixes to remove
  const suffixesToRemove = [
    "LLC", "Ltd", "Inc", "Corp", "Co", "SA", "AG", "NV", "GmbH", "BV", "KK", "OY", "AS", "AB", "PLC", "LLP", "LP", "PC",
    "Holdings", "Group", "Enterprises", "Company", "Systems", "Technologies", "Solutions", "Services", "Networks", "Partners", "International"
  ];

  // Regular expression to match suffixes
  const regex = new RegExp(`\\b(${suffixesToRemove.join("|")})\\b\\.?(\\s|$)`, "gi");

  // Remove matched suffixes
  return companyName.replace(regex, "").trim();
}

export function findCompaniesInText(
  article: Article,
  companies: Array<{ symbol: string; company_name: string }>
): Array<{ symbol: string; company_name: string }> {
  const matches: Array<{ symbol: string; company_name: string }> = [];
  const text = article.title + " "+article.content

  // List of common English words to exclude from matching
  const commonWords = new Set([
    "the", "and", "or", "on", "to", "at", "it", "be", "is", "as", "an", "in",
    "no", "we", "he", "do", "by", "if", "of", "up", "so", "go", "am", "us",
    "me", "my", "can", "see", "but", "not", "has", "had", "his", "her", "was",
    "our", "are", "you", "your", "they", "their", "them", "this", "that", "with",
    "will", "were", "what", "when", "who", "how", "why", "whom",
    "all", "any", "one", "two", "new", "own", "out", "now", "get", "got", "let",
    "key", "may", "way", "use", "end", "set", "put", "big", "top", "low", "few",
    "far", "old", "own", "off", "cut", "run", "hit", "buy", "sell", "add", "pay",
    "well", "high", "long", "best", "next", "last", "more", "most", "many", "some",
    "such", "each", "both", "than", "then", "over", "very", "also", "just", "even",
    "back", "down", "only", "here", "ever", "still", "much", "once"
  ]);


  companies.forEach(company => {
    const { symbol, company_name } = company;

    if (commonWords.has(symbol.toLowerCase()) || commonWords.has(company_name.toLowerCase())) {
      return;
    }

    let added = false
    if (symbol.length > 2) {
      const regexSymbol = new RegExp(`\\b${symbol}\\b`, "i");
      if (regexSymbol.test(text)) {
        matches.push(company);
        added = true
      }
    }

    if(!added){
      const cleanedName = preprocessCompanyName(company_name);
      const regexName = new RegExp(`\\b${cleanedName}\\b`, "i");
      if (regexName.test(text)) {
        matches.push(company);
      }
    }
  });
  return matches;
}
